import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './firebase/AuthProvider';

const ProtectedRoute = () => {
  const { currentUser, isLoading } = useAuth(); // Add isLoading to your auth context

  if (isLoading) {
    return <div>Loading...</div>; // Or any loading indicator you prefer
  }

  return currentUser ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
