import React, { useEffect, useState } from "react";
import {
  formatDateToWords,
} from "../../../../utilities/dateUtils";
import { useAuth } from "../../../../firebase/AuthProvider";
import {
  getTaskActionsByTaskId,
  markTaskActionComplete,
} from "../../../../services/taskServices/TaskActionService";
import TaskAction from "../../../../interfaces/task/taskActions/TaskAction";

interface RequiredTaskActionsProps {
  taskId: string;
}

const RequiredTaskActions: React.FC<RequiredTaskActionsProps> = ({
  taskId,
}) => {
  const { currentUser } = useAuth();

  const [actions, setActions] = useState<TaskAction[] | null>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    // Fetch the task with taskId and update the state
    const fetchActions = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedValues: TaskAction[] | null = await getTaskActionsByTaskId(
          taskId,
          authToken
        );

        setActions(fetchedValues);
      }
    };

    fetchActions();
  }, [refresh]);

  const markTaskActionCompleteMethod = async (actionId: number) => {
    if (currentUser !== null) {
      const authToken: string = await currentUser.getIdToken();
      await markTaskActionComplete(actionId, authToken).then((id) => {
        setRefresh(!refresh);
      });
    }
  };

  return (
    <div className="section">
      {actions?.filter((action) => !action.completionTime) &&
        actions.filter((action) => !action.completionTime).length > 0 && (
          <div>
            <h4 className="text-muted text-sm">Required Actions</h4>
            <ul className="divide-y divide-gray-100">
              {actions
                ?.filter((action) => !action.completionTime)
                .map((action, index) => (
                  <li key={index} className="flex justify-between py-5 gap-x-6">
                    <div className="">
                      <div className="flex justify-start align-middle">
                        {action.description}
                        <div className="yellow-badge ms-2 my-auto">
                          {action.actionType.actionTypeName}
                        </div>
                        <div className="pink-badge my-auto">
                          {action.actionSubType.actionSubTypeName.toUpperCase()}
                        </div>
                      </div>
                      <div className="text-xs text-muted flex align-middle">
                        Due on {formatDateToWords(action.dueDate)}{" "}
                        {action.author?.firstName && (
                          <div>
                            <span className="text-circle text-center px-2">
                              &#9679;
                            </span>
                            {`${action.author?.firstName}  ${action.author?.lastName}`}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      {action.completionTime ? (
                        <div className="text-xs text-muted">
                          Completed on{" "}
                          {formatDateToWords(action.completionTime)}
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="bg-background dark:bg-background-dark dark:text-text-dark text-text p-0.5 px-1 text-sm rounded hover:bg-card dark:hover:bg-card-dark border-text-dark dark:border-text border-2"
                          onClick={() =>
                            markTaskActionCompleteMethod(action.id)
                          }
                        >
                          Mark Complete
                        </button>
                      )}
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        )}
    </div>
  );
};

export default RequiredTaskActions;
