import React, { useEffect, useState } from "react";
import TextEditor from "../../textEditor/TextEditor";
import InterimTemplateResponse from "../../../../interfaces/task/InterimTemplateResponse";
import { useAuth } from "../../../../firebase/AuthProvider";
import { useNavigate } from "react-router-dom";
import NewInterimTemplate from "../../../../interfaces/task/NewInterimTemplate";
import { updateInterimTemplate } from "../../../../services/taskServices/TaskService";
import Select, { ActionMeta, MultiValue, SingleValue } from "react-select";
import { getTemplateFiles } from "../../../../services/fileServices/FileService";
import TemplateFile from "../../../../interfaces/task/TaskFiles/TemplateFile";

interface EditTemplateInterimTaskProps {
  template: InterimTemplateResponse;
  setSelectedTemplate: (value: InterimTemplateResponse | undefined) => void;
}
interface OptionType {
  value: string;
  label: string;
}

const EditTemplateInterimTask: React.FC<EditTemplateInterimTaskProps> = ({
  template,
  setSelectedTemplate,
}) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [formattedFileTemplates, setFormattedFileTemplates] = useState<
    OptionType[]
  >([]);
  const [fileTemplates, setFileTemplates] = useState<TemplateFile[] | null>();

  const [newTemplate, setNewTemplate] = useState<NewInterimTemplate>({
    id: template.id,
    title: template.title,
    description: template.description,
    information: template.information,
    requiredFiles: template.requiredFiles.map((file) => file.id.toString()),
    assignee: undefined,
    coAssignee: undefined,
    participant: undefined,
    daysFromCreationDueDate: template.daysFromCreationDueDate ? template.daysFromCreationDueDate.toString() : "0",
    taskOrganization: "1",
    taskType: "1",
    taskSubType: "4",
    dueDate: "",
    workflowGroup: "Standard",
  });

  useEffect(() => {
    if (currentUser !== null) {
      const fetchTemplateFiles = async () => {
        const authToken: string = await currentUser.getIdToken();
        const fetchedTemplateFiles = await getTemplateFiles(authToken);
        setFileTemplates(fetchedTemplateFiles);
        console.log(fetchedTemplateFiles);
      };

      fetchTemplateFiles();
    }

  }, [currentUser]);

  useEffect(() => {

    if (fileTemplates) {
      const formattedFileTemplates = fileTemplates.map((file) => ({
        value: file.id.toString(),
        label: file.fileName,
      }));
      if (formattedFileTemplates) {
        setFormattedFileTemplates(formattedFileTemplates);
      }
    }

    console.log(newTemplate)
  }, [fileTemplates]);
  
  const handleDayInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTemplate({...newTemplate, daysFromCreationDueDate: event.target.value})
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setNewTemplate({ ...newTemplate, [name]: value });
  };

  const handleRequiredFileChanges = (
    selectedOptions: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    const requiredFiles = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setNewTemplate({ ...newTemplate, requiredFiles });
    console.log(requiredFiles);
  };

  const handleInfoChange = (infoText: string) => {
    console.log("infoText", infoText);
    setNewTemplate({ ...newTemplate, information: infoText });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle submitting the new task
    console.log("New Task:", newTemplate);
    const createTask = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const newTaskId: number | null = await updateInterimTemplate(
          authToken,
          newTemplate
        );
        return newTaskId;
      }
      return null;
    };
    const idPromise = createTask();

    idPromise.then((id) => {
      if (id !== null) {
        // Redirect to new task here.
        console.log("New task ID:", id);
        setSelectedTemplate(undefined);
      } else {
        console.error("Failed to create task");
      }
    });
  };

  return (
    <div className="mt-5">
      <h1 className="heading-2 text-center">Edit Interim Template</h1>
      <form
        onSubmit={handleSubmit}
        className="max-w-xl mx-auto mt-8 bg-card dark:text-text dark:bg-card-dark shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-4">
          <label
            htmlFor="title"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Title:
          </label>
          <input
            type="text"
            id="title"
            name="title"
            placeholder="Give your task a title..."
            value={newTemplate.title}
            onChange={handleChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="description"
            className="block text-text dark:text-text text-sm font-bold mb-2"
          >
            Description:
          </label>
          <textarea
            id="description"
            name="description"
            placeholder="A brief description of what the task entails..."
            value={newTemplate.description}
            onChange={handleChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-text dark:text-text leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="info"
            className="block text-text dark:text-text text-sm font-bold mb-2"
          >
            Information:
          </label>
          <TextEditor
            text={newTemplate.information}
            setText={handleInfoChange}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="requiredFiles"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Required Files{" "}
            <span className="text-muted font-normal">(Optional)</span>:
          </label>
          <Select
            options={formattedFileTemplates}
            isMulti
            onChange={handleRequiredFileChanges}
            value={formattedFileTemplates.filter((option) =>
                newTemplate.requiredFiles.includes(option.value)
                )}
          />
        </div>
        <div className="my-5 flex-col">
                <div className="text-sm mb-1">Due date from creation</div>
                <input
                  className="w-full border p-2 rounded-md"
                  type="number"
                  min={"1"}
                  max={"365"}
                  value={newTemplate.daysFromCreationDueDate}
                  onChange={handleDayInputChange}
                  placeholder={"Days until completion..."}
                />
              </div>
        <div className="flex justify-end">
          <button
            onClick={(e) => handleSubmit}
            type="submit"
            className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Save Template
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditTemplateInterimTask;
