// TabButton.tsx
import React from 'react';

interface TabButtonProps {
  buttonText: string;
  isActive: boolean;
  size?: 'small' | 'regular'; // Define a 'size' prop with 'small' or 'regular' options
}

const TabButton: React.FC<TabButtonProps> = ({ buttonText, isActive, size = 'regular' }) => {
  const isSmall = size === 'small';

  return (
    <button
      role="tab"
      type="button"
      className={`${
        isActive
          ? `border-2 border-primary flex whitespace-nowrap items-center text-textTitle-dark ${
              isSmall ? 'h-6 px-1.5' : 'h-8 px-5'
            } font-medium rounded-lg outline-none focus:ring-2 focus:ring-primary focus:ring-inset ${
              isSmall
                ? 'text-xs'
                : 'text-textTitle-dark dark:text-textTitle-dark'
            } shadow bg-primary dark:text-textTitle-dark dark:bg-primary`
          : `border-2 border-primary-dark flex whitespace-nowrap items-center ${
              isSmall ? 'text-xs h-6 px-1.5' : 'h-8 px-5'
            } font-medium rounded-lg outline-none focus:ring-2 focus:ring-primary focus:ring-inset hover:border-primary hover:text-gray-800 focus:text-textTitle-dark dark:text-textTitle dark:focus:text-gray-400`
      }`}
    >
      {buttonText}
    </button>
  );
};

export default TabButton;
