// UserOrganizationService.ts
import Organization from "../../interfaces/identity/Organization"; // Import Organization interface

// Function to fetch a user's Organization by their user id
export async function getOrganizationByUserId(authToken: string): Promise<Organization | null> {
  
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/users/organization`
  
  try {
    const response = await fetch(apiUrl, {
      headers: { 
        'Authorization': `Bearer ${authToken}`
      }
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const organizationData = await response.json();

    // Map the organization data to organization interface
    const organization: Organization = {
      name: organizationData.name,
      // Add other necessary properties
    };

    return organization;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error('Error fetching task:', error);
    return null;
  }
}
