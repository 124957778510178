import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Select, { MultiValue, ActionMeta } from "react-select";
import TasksPageTable from "./TasksPageTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faX } from "@fortawesome/free-solid-svg-icons";

// Import your existing interfaces
import TaskList from "../../../interfaces/task/TaskList";
import { User } from "../../../interfaces/User";
import Participant from "../../../interfaces/Participant";
import Status from "../../../interfaces/task/Status";
import Avatar from "../../../interfaces/avatar/Avatar";

interface TasksPageProps {
  tasks: TaskList[];
  assignees: User[];
  participants: Participant[];
  statuses: Status[];
  avatars: Avatar[];
}

interface OptionType {
  value: string;
  label: string;
}

const TasksPageNew: React.FC<TasksPageProps> = ({
  tasks,
  assignees,
  participants,
  statuses,
  avatars,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  // State variables initialized to undefined to handle uninitialized state
  const [assigneeFilter, setAssigneeFilter] = useState<MultiValue<OptionType> | undefined>(undefined);
  const [participantFilter, setParticipantFilter] = useState<MultiValue<OptionType> | undefined>(undefined);
  const [typeFilter, setTypeFilter] = useState<MultiValue<OptionType> | undefined>(undefined);
  const [statusFilter, setStatusFilter] = useState<MultiValue<OptionType> | undefined>(undefined);
  const [dateFilter, setDateFilter] = useState<MultiValue<OptionType> | undefined>(undefined);
  const [toggleFilterForm, setToggleFilterForm] = useState(false);

  // Define options for Select components
  const assigneeOptions: OptionType[] = useMemo(
    () =>
      assignees.map((assignee) => ({
        value: assignee.id.toString(),
        label: assignee.displayName,
      })),
    [assignees]
  );

  const participantOptions: OptionType[] = useMemo(
    () => [
      { value: 'none', label: 'None' }, // Adding support for non-participant tasks
      ...participants.map((participant) => ({
        value: participant.id.toString(),
        label: `${participant.firstName} ${participant.lastName}`,
      })),
    ],
    [participants]
  );

  const statusOptions: OptionType[] = useMemo(
    () =>
      statuses.map((status) => ({
        value: status.name,
        label: status.name,
      })),
    [statuses]
  );

  const dateOptions: OptionType[] = useMemo(
    () => [
      { value: "overdue", label: "Overdue" },
      { value: "today", label: "Today" },
      { value: "week", label: "This Week" },
      { value: "month", label: "This Month" },
    ],
    []
  );

  const typeOptions: OptionType[] = useMemo(
    () => [
      { value: "interim", label: "Interim" },
      { value: "monthly", label: "Monthly" },
      { value: "quarterly", label: "Quarterly" },
      { value: "annual", label: "Annual" },
    ],
    []
  );

  // Initialize filters from URL parameters when options are available
  useEffect(() => {
    // Check if options arrays are populated
    if (
      assigneeOptions.length === 0 ||
      participantOptions.length === 0 ||
      statusOptions.length === 0
    ) {
      // Options not yet loaded; skip initializing filters
      return;
    }

    // Parse URL parameters
    const params = new URLSearchParams(location.search);
    const assignee = params.get("assignee");
    const participant = params.get("participant");
    const type = params.get("type");
    const status = params.get("status");
    const date = params.get("date");

    if (assignee !== null) {
      const assigneeValues = assignee.split(",");
      setAssigneeFilter(
        assigneeOptions.filter((option) => assigneeValues.includes(option.value))
      );
    } else {
      setAssigneeFilter([]);
    }

    if (participant !== null) {
      const participantValues = participant.split(",");
      setParticipantFilter(
        participantOptions.filter((option) => participantValues.includes(option.value))
      );
    } else {
      setParticipantFilter([]);
    }

    if (type !== null) {
      const typeValues = type.split(",");
      setTypeFilter(
        typeOptions.filter((option) => typeValues.includes(option.value))
      );
    } else {
      setTypeFilter([]);
    }

    if (status !== null) {
      const statusValues = status.split(",");
      setStatusFilter(
        statusOptions.filter((option) => statusValues.includes(option.value))
      );
    } else {
      setStatusFilter([]);
    }

    if (date !== null) {
      const dateValues = date.split(",");
      setDateFilter(
        dateOptions.filter((option) => dateValues.includes(option.value))
      );
    } else {
      setDateFilter([]);
    }
  }, [
    location.search,
    assigneeOptions,
    participantOptions,
    typeOptions,
    statusOptions,
    dateOptions,
  ]);

  // Update URL parameters when filters change
  useEffect(() => {
    // Skip updating URL if filters are not initialized
    if (
      assigneeFilter === undefined ||
      participantFilter === undefined ||
      typeFilter === undefined ||
      statusFilter === undefined ||
      dateFilter === undefined
    ) {
      return;
    }

    const params = new URLSearchParams();

    if (assigneeFilter.length > 0) {
      params.set(
        "assignee",
        assigneeFilter.map((assignee) => assignee.value).join(",")
      );
    }

    if (participantFilter.length > 0) {
      params.set(
        "participant",
        participantFilter.map((participant) => participant.value).join(",")
      );
    }

    if (typeFilter.length > 0) {
      params.set("type", typeFilter.map((type) => type.value).join(","));
    }

    if (statusFilter.length > 0) {
      params.set(
        "status",
        statusFilter.map((status) => status.value).join(",")
      );
    }

    if (dateFilter.length > 0) {
      params.set("date", dateFilter.map((date) => date.value).join(","));
    }

    // Use replace: true to avoid adding a new entry to the history stack
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [
    assigneeFilter,
    participantFilter,
    typeFilter,
    statusFilter,
    dateFilter,
    navigate,
    location.pathname,
  ]);

  // Filter tasks based on the filters
  const filteredTasks = useMemo(() => {
    if (!tasks || tasks.length === 0) {
      return [];
    }

    let filtered = tasks;

    if (assigneeFilter && assigneeFilter.length > 0) {
      const selectedAssignees = assigneeFilter.map((a) => a.value);
      filtered = filtered.filter((task: TaskList) =>
        task.assignees.some((assignee) =>
          selectedAssignees.includes(assignee.user.id.toString())
        )
      );
    }

    if (participantFilter && participantFilter.length > 0) {
      const selectedParticipants = participantFilter.map((a) => a.value);
    
      filtered = filtered.filter((task: TaskList) => {
        // Include tasks with no participant if "None" is selected
        const noParticipantMatch = selectedParticipants.includes('none') && !task.participant;
    
        // Include tasks that match the selected participants
        const participantMatch = task.participant && selectedParticipants.includes(task.participant.id.toString());
    
        // Return true if either condition is satisfied
        return noParticipantMatch || participantMatch;
      });
    }

    if (typeFilter && typeFilter.length > 0) {
      const selectedTypes = typeFilter.map((a) => a.value);
      filtered = filtered.filter((task: TaskList) =>
        selectedTypes.includes(task.subType.type.toLowerCase())
      );
    }

    if (statusFilter && statusFilter.length > 0) {
      const selectedStatuses = statusFilter.map((a) => a.value);
      filtered = filtered.filter((task: TaskList) =>
        selectedStatuses.includes(task.status.name)
      );
    }

    if (dateFilter && dateFilter.length > 0) {
      const selectedDates = dateFilter.map((a) => a.value);
      filtered = filtered.filter((task: TaskList) => {
        const today = new Date();
        const endDate = new Date(task.endDate);

        if (selectedDates.includes("overdue")) {
          return endDate < today;
        }
        if (selectedDates.includes("week")) {
          const endOfWeek = new Date(today);
          endOfWeek.setDate(today.getDate() + (7 - today.getDay()));
          return endDate >= today && endDate <= endOfWeek;
        }
        if (selectedDates.includes("today")) {
          return (
            endDate.getDate() === today.getDate() &&
            endDate.getMonth() === today.getMonth() &&
            endDate.getFullYear() === today.getFullYear()
          );
        }
        if (selectedDates.includes("month")) {
          const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
          return endDate >= today && endDate <= endOfMonth;
        }
        return false;
      });
    }

    return filtered;
  }, [
    assigneeFilter,
    participantFilter,
    typeFilter,
    statusFilter,
    dateFilter,
    tasks,
  ]);

  const toggleFilterFormMethod = () => {
    setToggleFilterForm(!toggleFilterForm);
  };

  const clearFilters = () => {
    setAssigneeFilter([]);
    setParticipantFilter([]);
    setTypeFilter([]);
    setStatusFilter([]);
    setDateFilter([]);
    // Update the URL to remove query parameters
    navigate(location.pathname, { replace: true });
  };

  // Define onChange handlers with correct types
  const handleAssigneeFilterChange = (
    newValue: MultiValue<OptionType>,
    _actionMeta: ActionMeta<OptionType>
  ) => {
    setAssigneeFilter(newValue);
  };

  const handleParticipantFilterChange = (
    newValue: MultiValue<OptionType>,
    _actionMeta: ActionMeta<OptionType>
  ) => {
    setParticipantFilter(newValue);
  };

  const handleTypeFilterChange = (
    newValue: MultiValue<OptionType>,
    _actionMeta: ActionMeta<OptionType>
  ) => {
    setTypeFilter(newValue);
  };

  const handleStatusFilterChange = (
    newValue: MultiValue<OptionType>,
    _actionMeta: ActionMeta<OptionType>
  ) => {
    setStatusFilter(newValue);
  };

  const handleDateFilterChange = (
    newValue: MultiValue<OptionType>,
    _actionMeta: ActionMeta<OptionType>
  ) => {
    setDateFilter(newValue);
  };

  return (
    <div className="relative bg-[#F7F9FC] dark:bg-background-dark sm:min-w-[640px] overflow-x-auto max-w-full">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="overflow-x-auto -top-64 relative dark:bg-card-dark dark:text-text-dark bg-[#F7F9FF] p-5 pt-0 rounded-3xl shadow-lg w-11/12 mx-auto min-h-[500px] -mt-10 max-w-[1800px]">
        <div className="flex justify-between items-center">
          <h1 className="my-10 text-xl inter-font text-text">Tasks</h1>
          <button
            onClick={toggleFilterFormMethod}
            className="flex gap-2 align-center items-center p-3 pe-5 shadow-sm my-2 ms-auto rounded-2xl bg-white text-muted"
          >
            <div className="rounded-full bg-[#F5F5F5] w-6 h-6 text-xs flex items-center justify-center">
              <FontAwesomeIcon icon={faFilter} className="text-[10px]" />{" "}
            </div>
            <div className="text-sm">Filters</div>
          </button>
        </div>
        <div className="relative rounded-3xl">
          {toggleFilterForm && (
            <div className="remove-input-txt-border absolute -mt-5 right-0 max-w-[500px] border rounded-md shadow-lg ms-auto w-2/5 p-5 bg-card my-5 dark:bg-card-dark dark:text-text-dark">
              <div className="flex justify-between align-center items-center">
                <div className="font-bold text-lg">Filters</div>
                <div
                  className="m-1 p-1 pe-2 bg-muted-dark rounded-3xl text-sm flex items-center align-middle gap-2 cursor-pointer text-[11px]"
                  onClick={clearFilters}
                >
                  <FontAwesomeIcon icon={faX} className="text-[11px] ps-2" />
                  <div className="text-xs">Clear Filters </div>
                </div>
              </div>
              {/* Input fields to filter tasks */}
              <h3 className="inter-font text-text mt-3">Assignees</h3>
              <Select
                isMulti
                options={assigneeOptions}
                value={assigneeFilter || []}
                onChange={handleAssigneeFilterChange}
                placeholder="Filter by Assignee"
              />
              <h3 className="inter-font text-text mt-3">Participants</h3>
              <Select
                isMulti
                options={participantOptions}
                value={participantFilter || []}
                onChange={handleParticipantFilterChange}
                placeholder="Filter by Participant"
              />
              <h3 className="inter-font text-text mt-3">Task Type</h3>
              <Select
                isMulti
                options={typeOptions}
                value={typeFilter || []}
                onChange={handleTypeFilterChange}
                placeholder="Filter by Type"
              />
              <h3 className="inter-font text-text mt-3">Task Status</h3>
              <Select
                isMulti
                options={statusOptions}
                value={statusFilter || []}
                onChange={handleStatusFilterChange}
                placeholder="Filter by Status"
              />
              <h3 className="inter-font text-text mt-3">Task Relative Date</h3>
              <Select
                isMulti
                options={dateOptions}
                value={dateFilter || []}
                onChange={handleDateFilterChange}
                placeholder="Filter by Date"
              />
            </div>
          )}
          <div>
            {/* Display filtered tasks */}
            <TasksPageTable tasks={filteredTasks} avatars={avatars} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TasksPageNew;
