// UserRolesService.ts
import { TeamUser } from "../../interfaces/team/TeamUser";

// Function to fetch a user's role by their user id
export async function getTeamBySupervisor(authToken: string): Promise<TeamUser[] | null> {

  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/users/supervisorTeam` 

  try {
    const response = await fetch(
      apiUrl, 
      {headers: { 
        'Authorization': `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }}
    );

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const userData: TeamUser[] = await response.json();
    // Map the role data to Role interface

    return userData;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}