// FileList.tsx
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DriveFile from "../../../../interfaces/file/DriveFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEye,
  faFile,
  faFileImage,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../../firebase/AuthProvider";
import { downloadFile } from "../../../../services/fileServices/FileService";
import { formatDateToMMDDYYYY } from "../../../../utilities/dateUtils";

interface FileListProps {
  files: DriveFile[];
  routeOnClick: (files: any, to: string) => void;
}

const FileList: React.FC<FileListProps> = ({ files, routeOnClick }) => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const [showActions, setShowActions] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [hoveredFile, setHoveredFile] = useState<string | null>(null);

  const handleMouseEnter = (fileId: string) => {
    setHoveredFile(fileId);
  };

  const handleMouseLeave = () => {
    setHoveredFile(null);
  };

  useEffect(() => {}, []);

  const handleView = (id: string) => {
    navigate(`/view/file/${id}`);
  };

  const handleDownload = async (id: string, filename: string) => {
    try {
      if (currentUser) {
        const authToken: string = await currentUser.getIdToken();
        const blob = await downloadFile(id, authToken);
        if (blob) {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename; // Use the provided fileName
          document.body.appendChild(a);
          a.click();

          URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div className="container mt-10 flex gap-5 flex-wrap">
      {files.map((file, index) => (
        <div
          className="relative flex align-middle gap-4 w-[120px] bg-card dark:bg-card-dark text-text dark:text-text-dark p-3 rounded-lg shadow-xl cursor-pointer hover:shadow-sm hover:bg-text-dark hover:dark:bg-text"
          key={index}
          onMouseEnter={() => handleMouseEnter(file.id)}
          onMouseLeave={handleMouseLeave}
          onClick={() => {
            if (showActions !== file.id) {
              setShowActions(file.id);
            } else {
              setShowActions(null);
            }
          }}
        >
          <div className="flex flex-col truncate w-full">
            <div className="mb-5 w-full">
              <div className="mx-auto flex justify-center text-4xl">
                {file.type.type === "PDF" && (
                  <FontAwesomeIcon className="text-red-600" icon={faFilePdf} />
                )}
                {file.type.type === "Image" && (
                  <FontAwesomeIcon
                    className="text-blue-600"
                    icon={faFileImage}
                  />
                )}
                {file.type.type === "Misc." && (
                  <FontAwesomeIcon className="text-muted" icon={faFile} />
                )}
              </div>
            </div>
            <div className="truncate text-xs">{file.fileName}</div>
            <div className="text-muted text-xs">
              {formatDateToMMDDYYYY(new Date(file.createDate))}
            </div>
          </div>
          {hoveredFile === file.id && showActions !== file.id && (
            <div
              className="tooltip absolute bg-card dark:bg-card-dark text-text dark:text-text-dark p-2 rounded shadow-lg text-xs max-w-80 min-w-full w-auto z-50 whitespace-nowrap"
              style={{
                top: "120%",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <div className="flex justify-between truncate">
                <p className="font-bold">File name: &nbsp;</p> {file.fileName}
              </div>
              {/* <div className="flex justify-between truncate"><p className="font-bold">File Type: &nbsp;</p> {file.type.type}</div> */}
              {/* <div className="flex justify-between truncate"><p className="font-bold">Author: &nbsp;</p> {file.author.firstName + " " + file.author.lastName}</div> */}
              <div className="flex justify-between truncate">
                <p className="font-bold">Created: &nbsp;</p> {file.createDate}
              </div>
              <div className="flex justify-between truncate">
                <p className="font-bold">Updated: &nbsp;</p> {file.updateDate}
              </div>
            </div>
          )}
          {showActions === file.id && (
            <div
              className="absolute bg-white dark:bg-gray-700 text-black dark:text-white p-2 rounded shadow-lg text-xs z-50"
              style={{
                top: "100%",
                left: "50%",
                transform: "translateX(-50%) translateY( 10%)",
              }}
            >
              <div
                onClick={() => handleView(file.id)}
                className="flex items-center hover:bg-primary hover:text-white p-1 rounded cursor-pointer"
              >
                <FontAwesomeIcon icon={faEye} className="mr-2" /> View
              </div>
              <div
                onClick={() => handleDownload(file.id, file.fileName)}
                className="flex items-center mt-2 hover:bg-primary hover:text-white p-1 rounded cursor-pointer"
              >
                <FontAwesomeIcon icon={faDownload} className="mr-2" /> Download
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FileList;
