// UserRolesService.ts
import Role from "../../interfaces/identity/Role"; // Import Role interface

// Function to fetch a user's role by their user id
export async function getRoleByUserId(authToken: string): Promise<Role[] | null> {

  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/users/roles` 

  try {
    const response = await fetch(
      apiUrl, 
      {headers: { 
        'Authorization': `Bearer ${authToken}`
      }}
    );

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      console.error(`Server responded with status: ${response.status}`);
      return null;
    }

    const roleData = await response.json();

    // Map the role data to Role interface
    const roles: Role[] = roleData.map((rd: any) => ({
      name: rd.name,
      premissions: rd.premissions ? rd.permissions : [],
      // Add other necessary properties
    }));

    return roles;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}

// Function to fetch all possible roles
export async function getRoles(authToken: string): Promise<Role[] | null> {

  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/users/allRoles` 

  try {
    const response = await fetch(
      apiUrl, 
      {headers: { 
        'Authorization': `Bearer ${authToken}`
      }}
    );

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      console.error(`Server responded with status: ${response.status}`);
      return null;
    }

    const roleData = await response.json();

    // Map the role data to Role interface
    const roles: Role[] = roleData.map((rd: any) => ({
      id: rd.name,
      name: rd.name,
      // Add other necessary properties
    }));

    return roles;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}
