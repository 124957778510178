import { useEffect, useState } from "react";
import {
  getAllParticipants,
  postNewParticipantCertificationDate,
  postNewParticipantCpocDate,
  postNewParticipantCpocEndDate,
  postNewParticipantLinkageDate,
  toggleParticipantDeactivated,
} from "../../../services/participantServices/ParticipantService";
import { useAuth } from "../../../firebase/AuthProvider";
import AssignedParticipant from "../../../interfaces/participant/AssignedParticipant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesUp,
  faCheck,
  faChevronDown,
  faPencil,
  faPlus,
  faRepeat,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Banner from "../../ui/Banner/Banner";
import {
  formatDateToDateFormFormat,
  formatDateToWords,
} from "../../../utilities/dateUtils";
import TransferParticipantComponent from "./TransferParticipantComponent";

interface groupedParticipants {
  [assigneeId: string]: AssignedParticipant[];
}

const EditParticipantPage: React.FC = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [reload, setReload] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [displayParticipantInfo, setDisplayParticipantInfo] =
    useState<string>("");
  const [editLinkageDateInfo, setEditLinkageDateInfo] = useState<string>("");
  const [editCertificationDateInfo, setEditCertificationDateInfo] =
    useState<string>("");
  const [editCpocDateInfo, setEditCpocDateInfo] = useState<string>("");
  const [editCpocEndDateInfo, setEditCpocEndDateInfo] = useState<string>("");
  const [linkageDate, setLinkageDate] = useState<string>("");
  const [certificationDate, setCertificationDate] = useState<string>("");
  const [cpocDate, setCpocDate] = useState<string>("");
  const [cpocEndDate, setCpocEndDate] = useState<string>("");
  const [toggledAssignees, setToggledAssignees] = useState<string[]>([]);
  const [toggleTransferParticipant, setToggleTransferParticipant] =
    useState<boolean>(false);
  const [participants, setParticipants] = useState<
    AssignedParticipant[] | null
  >();
  const [groupedParticipants, setGroupedParticipants] =
    useState<groupedParticipants | null>();

  useEffect(() => {
    const fetchParticipants = async () => {
      if (currentUser !== null) {
        const authToken = await currentUser.getIdToken();
        setParticipants(await getAllParticipants(authToken));
      }
    };

    fetchParticipants();
  }, [currentUser, reload]);

  // Group participants
  useEffect(() => {
    if (participants) {
      const groupedByAssignee = participants.reduce((group, participant) => {
        const assigneeId = participant.assignee.id;

        if (!group[assigneeId]) {
          group[assigneeId] = [];
        }

        group[assigneeId].push(participant);
        return group;
      }, {} as { [assigneeId: string]: AssignedParticipant[] });

      setGroupedParticipants(groupedByAssignee);
    }
  }, [participants]);

  const toggleDeactivated = async (id: string) => {
    if (currentUser !== undefined && currentUser) {
      try {
        const authToken: string = await currentUser.getIdToken();
        await toggleParticipantDeactivated(id, authToken);
        setReload(!reload);
      } catch {
        setErrorMessage("Failed to de-activate participant.");
      }
    }
  };

  const onChangeLinkageDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;
    setLinkageDate(newDate);
  };

  const onChangeCpocDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;
    setCpocDate(newDate);
  };

  const onChangeCpocEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;
    setCpocEndDate(newDate);
  };

  const onChangeCertificationDate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newDate = event.target.value;
    setCertificationDate(newDate);
  };

  const navigateCreateParticipant = () => {
    navigate("new");
  };

  const toggleParticipantInfo = (id: string) => {
    if (displayParticipantInfo === id) {
      clearDisplayInfo();
    } else {
      clearDisplayInfo();
      setDisplayParticipantInfo(id);
      let participant = participants?.filter((p) => p.id === id)[0];
      if (participant?.linkageDate) {
        setLinkageDate(formatDateToDateFormFormat(participant.linkageDate));
      }
      if (participant?.certificationDate) {
        setCertificationDate(
          formatDateToDateFormFormat(participant.linkageDate)
        );
      }
      if (participant?.cpocDate) {
        setCpocDate(formatDateToDateFormFormat(participant.cpocDate));
      }
      if (participant?.cpocEndDate) {
        setCpocEndDate(formatDateToDateFormFormat(participant.cpocEndDate));
      }
    }
  };

  const clearDisplayInfo = () => {
    setDisplayParticipantInfo("");
    setEditCertificationDateInfo("");
    setEditCpocDateInfo("");
    setEditCpocEndDateInfo("");
    setEditLinkageDateInfo("");
    setLinkageDate("");
    setCertificationDate("");
    setCpocDate("");
    setCpocEndDate("");
  };

  const toggleEditLinkageDateInfo = (id: string) => {
    if (editLinkageDateInfo === id) {
      setEditLinkageDateInfo("");
    } else {
      setEditLinkageDateInfo(id);
    }
  };

  const toggleEditCertificationDateInfo = (id: string) => {
    if (editCertificationDateInfo === id) {
      setEditCertificationDateInfo("");
    } else {
      setEditCertificationDateInfo(id);
    }
  };

  const toggleEditCpocDateInfo = (id: string) => {
    if (editCpocDateInfo === id) {
      setEditCpocDateInfo("");
    } else {
      setEditCpocDateInfo(id);
    }
  };

  const toggleEditCpocEndDateInfo = (id: string) => {
    if (editCpocEndDateInfo === id) {
      setEditCpocEndDateInfo("");
    } else {
      setEditCpocEndDateInfo(id);
    }
  };

  const validateLinkageDate = () => {
    const isValid = !isNaN(Date.parse(linkageDate));
    console.log("Is LinkageDate Valid? ", isValid);
    return isValid;
  };

  const validateCertificationDate = () => {
    const isValid = !isNaN(Date.parse(certificationDate));
    console.log("Is CerticiationDate Valid? ", isValid);
    return isValid;
  };

  const validateCpocDate = () => {
    const isValid = !isNaN(Date.parse(cpocDate));
    console.log("Is CPOCDate Valid? ", isValid);
    return isValid;
  };

  const validateCpocEndDate = () => {
    const isValid = !isNaN(Date.parse(cpocEndDate));
    console.log("Is CPOC End Date Valid? ", isValid);
    return isValid;
  };

  const postNewLinkageDate = async (id: string) => {
    try {
      if (validateLinkageDate() && currentUser) {
        const authToken: string = await currentUser.getIdToken();
        await postNewParticipantLinkageDate(
          id,
          new Date(linkageDate),
          authToken
        );
        setErrorMessage("");
        toggleEditLinkageDateInfo(id);
        clearDisplayInfo();
        setReload(!reload);
      } else if (!validateLinkageDate()) {
        setErrorMessage("Linkage Date is not a valid date format.");
      }
    } catch {
      setErrorMessage("Unable to update linkage date");
    }
  };

  const postNewCertificationDate = async (id: string) => {
    try {
      if (validateCertificationDate() && currentUser) {
        const authToken: string = await currentUser.getIdToken();
        await postNewParticipantCertificationDate(
          id,
          new Date(certificationDate),
          authToken
        );
        setErrorMessage("");
        toggleEditCertificationDateInfo(id);
        clearDisplayInfo();
        setReload(!reload);
      } else if (!validateCertificationDate()) {
        setErrorMessage("Certification Date is not a valid date format.");
      }
    } catch {
      setErrorMessage("Unable to update certification date");
    }
  };

  const postNewCpocDate = async (id: string) => {
    try {
      if (validateCpocDate() && currentUser) {
        const authToken: string = await currentUser.getIdToken();
        await postNewParticipantCpocDate(id, new Date(cpocDate), authToken);
        setErrorMessage("");
        toggleEditCpocDateInfo(id);
        clearDisplayInfo();
        setReload(!reload);
      } else if (!validateCpocDate()) {
        setErrorMessage("CPOC Date is not a valid date format.");
      }
    } catch {
      setErrorMessage("Unable to update CPOC date");
    }
  };

  const postNewCpocEndDate = async (id: string) => {
    try {
      if (validateCpocEndDate() && currentUser) {
        const authToken: string = await currentUser.getIdToken();
        await postNewParticipantCpocEndDate(
          id,
          new Date(cpocEndDate),
          authToken
        );
        setErrorMessage("");
        toggleEditCpocEndDateInfo(id);
        clearDisplayInfo();
        setReload(!reload);
      } else if (!validateCpocEndDate()) {
        setErrorMessage("CPOC Date is not a valid date format.");
      }
    } catch {
      setErrorMessage("Unable to update CPOC date");
    }
  };

  const dismissBanner = () => {
    setErrorMessage("");
  };

  const toggleAssignee = (assignee: string) => {
    setToggledAssignees((prevState) => {
      if (prevState.includes(assignee)) {
        // If the string already exists, remove it
        return prevState.filter((item) => item !== assignee);
      } else {
        // If the string doesn't exist, add it
        return [...prevState, assignee];
      }
    });
  };

  return (
    <div className="relative bg-background dark:bg-background-dark sm:min-w-[640px] overflow-x-auto max-w-full flex-grow flex-wrap h-screen">
      <div className=" w-full h-[300px] bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="-mt-10 bg-card dark:bg-card-dark dark:text-text-dark p-5 rounded-md shadow-lg min-w-96 max-w-screen-lg mx-auto -top-64 relative min-h-[400px]">
        <div className="flex justify-between">
          <div className="inter-font text-lg">Participants</div>
          <div className="flex gap-2">
            <button
              className="flex gap-2 justify-center items-center align-middle bg-gray-200 p-2 rounded shadow hover:shadow-none"
              onClick={() =>
                setToggleTransferParticipant(!toggleTransferParticipant)
              }
            >
              <FontAwesomeIcon icon={faRepeat} />
              <div>Transfer Participant</div>
            </button>
            <button
              className="flex gap-2 justify-center items-center align-middle bg-[#3DD598] text-white p-2 rounded shadow hover:shadow-none"
              onClick={navigateCreateParticipant}
            >
              <FontAwesomeIcon icon={faPlus} />
              <div>New Participant</div>
            </button>
          </div>
        </div>
        {errorMessage !== "" && (
          <div className="my-5">
            <Banner
              status={"error"}
              message={errorMessage}
              dismissBanner={dismissBanner}
              toggleBanner={dismissBanner}
            />
          </div>
        )}
        {toggleTransferParticipant && (
          <TransferParticipantComponent participants={participants} />
        )}
        <div className="flex flex-col gap-5 gap-y-5 mt-5 align-middle">
          {groupedParticipants &&
            Object.keys(groupedParticipants).map((assigneeId) => (
              <div
                key={assigneeId}
                className="bg-card dark:bg-card-dark border p-2 flex-col gap-3 items-center"
              >
                <div
                  className={`flex align-middle items-center relative -top-5 bg-card dark:bg-card-dark w-fit gap-2 cursor-pointer px-1.5 rounded-3xl hover:bg-gray-200`}
                  onClick={() => toggleAssignee(assigneeId)}
                >
                  <div>
                    {groupedParticipants[assigneeId][0].assignee.firstName +
                      " " +
                      groupedParticipants[assigneeId][0].assignee.lastName}
                  </div>
                  <div className="text-muted text-xs items-center flex justify-center">
                    {"("}
                    {
                      groupedParticipants[assigneeId].filter(
                        (participant) => !participant.deactivated
                      ).length
                    }
                    {" participants)"}
                  </div>
                </div>
                {toggledAssignees.some((assignee) => assignee === assigneeId) &&
                  groupedParticipants[assigneeId]
                    .sort((a, b) => a.lastName.localeCompare(b.lastName)) // Sort strings alphabetically
                    .map((participant, index) => (
                      <div
                        key={index}
                        className="shadow-lg border rounded-md p-2 mt-3 items-center"
                      >
                        <div className="flex justify-between">
                          <div className="flex gap-2 items-center">
                            <div className="bg-background dark:bg-background-dark w-14 text-2xl h-14 flex items-center justify-center dark:text-muted-dark text-muted">
                              <FontAwesomeIcon icon={faUser} />
                            </div>
                            <div className="flex-col gap-2 items-center">
                              <div>
                                {participant.firstName} {participant.lastName}
                              </div>
                              <div className="text-muted dark:text-muted-dark text-sm">
                                Region {participant.region} -{" "}
                                {participant.waiver.waiverName}
                              </div>
                            </div>
                          </div>
                          <div className="flex-col gap-3 pe-2 justify-between h-full">
                            {participant.deactivated ? (
                              <div
                                onClick={() =>
                                  toggleDeactivated(participant.id)
                                }
                                className="cursor-pointer hover:text-muted dark:hover:text-muted-dark"
                              >
                                {/* <FontAwesomeIcon icon={faEyeSlash} /> */}
                                <label className="flex items-center cursor-pointer select-none text-dark dark:text-text">
                                  <div className="relative">
                                    <input
                                      id="toggleFive"
                                      disabled
                                      checked={false}
                                      type="checkbox"
                                      className="peer sr-only"
                                    />
                                    <div className="h-5 rounded-full shadow-inner w-14 bg-background dark:bg-dark"></div>
                                    <div className="absolute left-0 flex items-center justify-center transition bg-primary-dark rounded-full dot shadow-switch-1 -top-1 h-7 w-7 dark:bg-dark-5 peer-checked:dark:bg-dark-3 peer-checked:translate-x-full">
                                      <span className="w-4 h-4 rounded-full active bg-gray-3 dark:bg-dark-2"></span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            ) : (
                              <div
                                onClick={() =>
                                  toggleDeactivated(participant.id)
                                }
                                className="cursor-pointer hover:text-muted dark:hover:text-muted-dark"
                              >
                                {/* <FontAwesomeIcon icon={faEye} /> */}
                                <label className="flex items-center cursor-pointer select-none text-dark dark:text-text">
                                  <div className="relative">
                                    <input
                                      id="toggleFive"
                                      disabled
                                      checked
                                      type="checkbox"
                                      className="peer sr-only"
                                    />
                                    <div className="h-5 rounded-full shadow-inner w-14 bg-primary-dark dark:bg-dark"></div>
                                    <div className="absolute left-0 flex items-center justify-center transition bg-primary rounded-full dot shadow-switch-1 -top-1 h-7 w-7 dark:bg-dark-5 peer-checked:dark:bg-dark-3 peer-checked:translate-x-full">
                                      <span className="w-4 h-4 rounded-full active bg-gray-3 dark:bg-dark-2"></span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            )}
                            <div className="flex-end mt-auto pt-2">
                              <FontAwesomeIcon
                                onClick={() =>
                                  toggleParticipantInfo(participant.id)
                                }
                                className="cursor-pointer hover:text-muted"
                                icon={faChevronDown}
                              />
                            </div>
                          </div>
                        </div>
                        {displayParticipantInfo === participant.id ? (
                          <div className="mt-5 border-t w-full flex justify-between items-center">
                            <div className="flex flex-wrap w-full">
                              <div className="w-1/2">
                                <div className="text-muted">Linkage Date: </div>
                                <div className="bg-background m-2 dark:bg-background-dark p-2 rounded-lg">
                                  {editLinkageDateInfo !== participant.id ? (
                                    <div className="flex gap-1 items-center justify-between">
                                      <div>
                                        {participant.linkageDate
                                          ? formatDateToWords(
                                              participant.linkageDate
                                            )
                                          : "N/A"}
                                      </div>
                                      <div
                                        onClick={() =>
                                          toggleEditLinkageDateInfo(
                                            participant.id
                                          )
                                        }
                                        className="text-xs text-muted dark:text-muted-dark"
                                      >
                                        <FontAwesomeIcon
                                          className="cursor-pointer hover:text-muted-dark dark:hover:text-muted"
                                          icon={faPencil}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <input
                                        name="linkageDate"
                                        value={linkageDate}
                                        onChange={onChangeLinkageDate}
                                        type="date"
                                      />
                                      <button
                                        onClick={() =>
                                          postNewLinkageDate(participant.id)
                                        }
                                        className="bg-primary text-white px-2 py-1 m-1 hover:bg-primary-dark text-sm rounded-lg"
                                      >
                                        <FontAwesomeIcon icon={faCheck} />
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="w-1/2">
                                <div className="text-muted">CPOC Date: </div>
                                <div className="bg-background m-2 dark:bg-background-dark p-2 rounded-lg">
                                  {editCpocDateInfo !== participant.id ? (
                                    <div className="flex gap-1 items-center justify-between">
                                      <div>
                                        {participant.cpocDate
                                          ? formatDateToWords(
                                              participant.cpocDate
                                            )
                                          : "N/A"}
                                      </div>
                                      <div
                                        onClick={() =>
                                          toggleEditCpocDateInfo(participant.id)
                                        }
                                        className="text-xs text-muted dark:text-muted-dark"
                                      >
                                        <FontAwesomeIcon
                                          className="cursor-pointer hover:text-muted-dark dark:hover:text-muted"
                                          icon={faPencil}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <input
                                        name="cpocDate"
                                        value={cpocDate}
                                        onChange={onChangeCpocDate}
                                        type="date"
                                      />
                                      <button
                                        onClick={() =>
                                          postNewCpocDate(participant.id)
                                        }
                                        className="bg-primary text-white px-2 py-1 m-1 hover:bg-primary-dark text-sm rounded-lg"
                                      >
                                        <FontAwesomeIcon icon={faCheck} />
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="w-1/2">
                                <div className="text-muted">
                                  CPOC End Date:{" "}
                                </div>
                                <div className="bg-background m-2 dark:bg-background-dark p-2 rounded-lg">
                                  {editCpocEndDateInfo !== participant.id ? (
                                    <div className="flex gap-1 items-center justify-between">
                                      <div>
                                        {participant.cpocEndDate
                                          ? formatDateToWords(
                                              participant.cpocEndDate
                                            )
                                          : "N/A"}
                                      </div>
                                      <div
                                        onClick={() =>
                                          toggleEditCpocEndDateInfo(
                                            participant.id
                                          )
                                        }
                                        className="text-xs text-muted dark:text-muted-dark"
                                      >
                                        <FontAwesomeIcon
                                          className="cursor-pointer hover:text-muted-dark dark:hover:text-muted"
                                          icon={faPencil}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <input
                                        name="cpocEndDate"
                                        value={cpocEndDate}
                                        onChange={onChangeCpocEndDate}
                                        type="date"
                                      />
                                      <button
                                        onClick={() =>
                                          postNewCpocEndDate(participant.id)
                                        }
                                        className="bg-primary text-white px-2 py-1 m-1 hover:bg-primary-dark text-sm rounded-lg"
                                      >
                                        <FontAwesomeIcon icon={faCheck} />
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="w-1/2">
                                <div className="text-muted">
                                  Certification Date:{" "}
                                </div>
                                <div className="bg-background m-2 dark:bg-background-dark p-2 rounded-lg">
                                  {editCertificationDateInfo !==
                                  participant.id ? (
                                    <div className="flex gap-1 items-center justify-between">
                                      <div>
                                        {participant.certificationDate
                                          ? formatDateToWords(
                                              participant.certificationDate
                                            )
                                          : "N/A"}
                                      </div>
                                      <div
                                        onClick={() =>
                                          toggleEditCertificationDateInfo(
                                            participant.id
                                          )
                                        }
                                        className="text-xs text-muted dark:text-muted-dark"
                                      >
                                        <FontAwesomeIcon
                                          className="cursor-pointer hover:text-muted-dark dark:hover:text-muted"
                                          icon={faPencil}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <input
                                        name="certificationDate"
                                        value={certificationDate}
                                        onChange={onChangeCertificationDate}
                                        type="date"
                                      />
                                      <button
                                        onClick={() =>
                                          postNewCertificationDate(
                                            participant.id
                                          )
                                        }
                                        className="bg-primary text-white px-2 py-1 m-1 hover:bg-primary-dark text-sm rounded-lg"
                                      >
                                        <FontAwesomeIcon icon={faCheck} />
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="w-full flex justify-end mt-5">
                                <button
                                  className="flex gap-2 justify-center items-center align-middle bg-gray-200 p-2 rounded shadow hover:shadow-none"
                                  onClick={() =>
                                    navigate(
                                      `/edit/participants/bump/${participant.id}`
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faAnglesUp} />
                                  <div>Waiver Bump</div>
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ))}
                {!toggledAssignees.some(
                  (assignee) => assignee === assigneeId
                ) && (
                  <div className="text-muted text-xs text-center">
                    Click the employee's name to expand this section...
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default EditParticipantPage;
