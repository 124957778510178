import React, { useEffect, useState } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import TemplateFile from "../../../interfaces/task/TaskFiles/TemplateFile";
import { getTemplateFiles } from "../../../services/fileServices/FileService";
import EditTemplateFile from "./EditTemplateFile";

const EditTemplateFiles = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [templates, setTemplates] = useState<TemplateFile[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateFile>();
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    const fetchTemplates = async () => {
      if (currentUser !== null) {
        const authToken = await currentUser.getIdToken();
        const response = await getTemplateFiles(authToken);
        if (response) {
          setTemplates(response);
        }
      }
    };

    fetchTemplates();
  }, [currentUser, reload]);

  const selectTemplate = (template: TemplateFile) => {
    if (!template) {
      setReload(!reload);
    }
    setSelectedTemplate(template);
  };

  return (
    <div className="bg-[#F7F9FC]">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="min-h-[600px] mx-auto text-text dark:text-text-dark p-5 relative w-11/12 rounded-3xl -top-64 bg-white">
        <div className="w-full flex justify-end">
          <button
            className="ms-auto dark-button"
            onClick={() => navigate("/file/new/template/")}
          >
            New
          </button>
        </div>
        <div className="overflow-none mt-10 flex w-full">
          {selectedTemplate ? (
            <div className="mx-auto">
              <EditTemplateFile
                template={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
              />
            </div>
          ) : (
            <div className="w-full flex gap-5">
              {templates &&
                templates.map((template, index) => (
                  <div
                    key={index}
                    className="card dark:text-text-dark min-w-[200px] p-5 flex gap-5 justify-between hover:shadow-none"
                  >
                    <div>{template.fileName}</div>
                    <div className="hover:text-primary cursor-pointer">
                      <FontAwesomeIcon
                        icon={faPen}
                        onClick={() => selectTemplate(template)}
                      />
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditTemplateFiles;
