import React from "react";

const Reviews: React.FC = () => {
  return (
    <div className="p-5">
      <h1 className="inter-font text-text text-2xl">Reviews</h1>
      <div className="container mx-auto">
        <div className="text-muted text-sm mt-5 w-full text-center">There are currently no reviews available.</div>
      </div>
    </div>
  );
};

export default Reviews;
