import React from "react";

interface FileActionModalProps {
    onConfirm: () => void; 
    onCancel: () => void; 
    isLoading: boolean; 
    children: React.ReactNode;
}

const FileActionModal: React.FC<FileActionModalProps> = ({ onConfirm, onCancel, children, isLoading }) => { 
  // Prevents click events from bubbling up to parent components
  const handleModalClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex"
         onClick={onCancel} // Assuming clicking outside the modal box should cancel/close the modal
         style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="relative p-8 m-auto flex-col flex rounded-lg shadow-lg max-w-sm bg-background dark:bg-background-dark"
           onClick={handleModalClick} // Stops propagation here
      >
        <div className="mb-4">
          <p>{children}</p>
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onCancel}
            disabled={isLoading}
            className="px-4 py-2 rounded bg-gray-300 hover:bg-gray-400 text-black disabled:opacity-50"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            disabled={isLoading}
            className="px-4 py-2 rounded bg-danger hover:bg-danger-dark hover:text-text text-white disabled:text-white disabled:bg-danger-dark"
          >
            {isLoading ? "Deleting..." : "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileActionModal;