import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getDriveFileById,
  getFileById,
} from "../../../services/fileServices/FileService";
import { useAuth } from "../../../firebase/AuthProvider";
import DriveFile from "../../../interfaces/file/DriveFile";
import PDFViewer from "./pdfViewer/PDFViewer"; // Your custom PDFViewer
import FileViewer from "react-file-viewer"; // Import react-file-viewer

const FileViewPage: React.FC = () => {
  const { currentUser } = useAuth();
  const { fileId } = useParams();
  const [fileUrl, setFileUrl] = useState<string | null>(null); // To store the blob URL
  const [fileMetadata, setFileMetadata] = useState<DriveFile>();
  const [blobInferredMimeType, setBlobInferredMimeType] = useState<string>("");
  const [scale, setScale] = useState(1.0); // State for controlling the zoom level
  const [zoom, setZoom] = useState<number>(1.0); // Zoom state for image files

  useEffect(() => {
    const fetchFile = async () => {
      if (currentUser && fileId) {
        try {
          const authToken = await currentUser.getIdToken();
          const file: DriveFile | null = await getDriveFileById(
            fileId,
            authToken
          );

          if (file) {
            setFileMetadata(file);

            const fileUrl = await getFileById(fileId, authToken);
            if (fileUrl) {
              setFileUrl(fileUrl); // Set the file URL if it's valid

              // Fetch the blob and check its MIME type
              const response = await fetch(fileUrl);
              const blob = await response.blob();

              // Infer correct MIME type based on file extension
              let inferredMimeType = blob.type;
              if (blob.type === "application/octet-stream") {
                const extension = file.fileName
                  ?.split(".")
                  .pop()
                  ?.toLowerCase();
                switch (extension) {
                  case "pdf":
                    inferredMimeType = "application/pdf";
                    break;
                  case "jpg":
                  case "jpeg":
                    inferredMimeType = "image/jpeg";
                    break;
                  case "png":
                    inferredMimeType = "image/png";
                    break;
                  case "doc":
                  case "docx":
                    inferredMimeType =
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                    break;
                  // Add more cases as needed for other file types
                  default:
                    inferredMimeType = "application/octet-stream";
                }
              }

              setBlobInferredMimeType(inferredMimeType);
            }
          }
        } catch (error) {
          console.error("Error fetching file:", error);
        }
      }
    };

    fetchFile();
  }, [currentUser, fileId]);

  // Define renderViewer function
  const renderViewer = () => {
    if (!fileUrl) return <div>Loading...</div>;

    const fileType = (() => {
      if (blobInferredMimeType === "application/pdf") return "pdf";
      if (
        blobInferredMimeType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      )
        return "docx";
      if (blobInferredMimeType === "image/jpeg") return "jpg";
      if (blobInferredMimeType === "image/png") return "png";

      const extension = fileMetadata?.fileName?.split(".").pop()?.toLowerCase();
      if (extension === "pdf") return "pdf";
      if (extension === "doc" || extension === "docx") return "docx";
      if (extension === "jpg" || extension === "jpeg") return "jpg"; // Use 'jpg' for both
      if (extension === "png") return "png";
      console.log(fileMetadata?.type.type)
      if (fileMetadata?.type.type.toLowerCase() === 'image') return "png"

      return "unknown";
    })();

    const viewerStyles = {
      transform: `scale(${zoom})`,
      transformOrigin: "top left",
      width: "100%",      // Ensure the viewer takes full width
      height: "100%",     // Ensure the viewer takes full height
      overflow: "hidden", // Hide scrollbars
    };

    if (fileType === "pdf") {
      // Use custom PDFViewer for PDFs with access to scale
      return <PDFViewer fileUrl={fileUrl} scale={scale} />;
    } else if (fileType === "jpg" || fileType === "png") {
      // Use an img tag to render images with zoom controls
      return (
        <div className="file-viewer-container">
          <div className="image-container" style={viewerStyles}>
            <img src={fileUrl} alt={fileMetadata?.fileName} style={{ maxWidth: "100%", height: "auto", transform: `scale(${zoom})`, transformOrigin: "center" }} />
          </div>
        </div>
      );
    } else {
      // Use react-file-viewer for other file types
      return (
        <FileViewer
          fileType={fileType}
          filePath={fileUrl}
          errorComponent={() => <div>Error loading file</div>}
          onError={(error) => console.log("Error while viewing file:", error)}
        />
      );
    }
  };

  return (
    <div className="relative bg-background dark:bg-background-dark sm:min-w-[640px] overflow-x-auto max-w-full flex-grow flex-wrap h-screen">
      <div className="bg-black w-full h-[300px] rounded-b-3xl"></div>
      <div className="overflow-x-auto -top-64 relative dark:bg-card-dark dark:text-text-dark bg-[#F7F9FF] p-5 rounded-3xl shadow-lg w-11/12 mx-auto min-h-[600px] -mt-10">
        <div className="flex items-center align-middle">
          <div
            className="hover:bg-muted-dark cursor-pointer rounded-xl p-2"
            onClick={() => window.history.back()}
          >
            {"< "}Back
          </div>
        </div>
        <div className="text-2xl inter-font text-center my-5">
          {fileMetadata?.fileName}
        </div>
        {blobInferredMimeType === "application/pdf" && (
          <div className="zoomControls flex align-middle items-center gap-2 mb-5 justify-end me-5">
            <label htmlFor="scale">Zoom: </label>
            <input
              type="range"
              id="scale"
              min="0.5"
              max="2.0"
              step="0.1"
              value={scale}
              onChange={(e) => setScale(parseFloat(e.target.value))}
              className="accent-[#3dd598]"
            />
            <span>{scale.toFixed(1)}x</span>
          </div>
        )}
        <div className="flex align-center justify-center">{renderViewer()}</div>
      </div>
    </div>
  );
};

export default FileViewPage;
