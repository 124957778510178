// TaskActionService.ts
import ActionSubType from "../../interfaces/task/taskActions/ActionSubType";
import ActionType from "../../interfaces/task/taskActions/ActionType";
import NewTaskActionType from "../../interfaces/task/taskActions/NewTaskActionType";
import TaskAction from "../../interfaces/task/taskActions/TaskAction";
import TaskActionRequest from "../../interfaces/task/taskActions/TaskActionRequest";
import { TaskActionUpdateRequest } from "../../interfaces/task/taskActions/TaskActionUpdateRequest";
import UpdateTaskActionType from "../../interfaces/task/taskActions/UpdateTaskActionType";
import { TaskCommentRequest } from "../../interfaces/taskAction/TaskCommentRequest";

// Function to fetch taskActions by taskId
export async function getTaskActionsByTaskId(
  taskId: string,
  authToken: string
): Promise<TaskAction[] | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/taskActions/task/${taskId}`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const taskActionList = await response.json();

    // Map the task data list to an array of TaskActivity objects
    const actions: TaskAction[] = taskActionList.map((actionData: any) => ({
      id: actionData.id,
      taskId: actionData.taskId,
      description: actionData.description,
      actionSubType: {
        id: actionData.actionSubType.id,
        actionSubTypeName: actionData.actionSubType.actionSubTypeName,
      },
      name: actionData.name,
      actionType: {
        id: actionData.actionType.id,
        actionTypeName: actionData.actionType.actionTypeName,
      },
      author: {
        id: actionData.author.id,
        firstName: actionData.author.firstName,
        lastName: actionData.author.lastName,
        email: actionData.author.email,
        displayname: actionData.author.displayName,
        scId: actionData.author.scId,
      },
      dueDate: actionData.dueDate,
      completionTime: actionData.completionTime,
      deleted: actionData.deleted,
      updateDate: new Date(Date.parse(actionData.updateDate)), 
      updatedBy: actionData.updatedBy
      // Add other necessary properties
    }));

    return actions;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching actions:", error);
    return null;
  }
}

export async function markTaskActionComplete(
  actionId: number,
  authToken: string
): Promise<number | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/taskActions/${actionId}/markComplete`;

    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    return await response.json();
  } catch (error) {
    console.error("Error marking action complete:", error);
    return null;
  }
}

export const createCommentOnTaskId = async (commentData: TaskCommentRequest, authToken: string) => {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/taskActions`;
  // Assuming you have an endpoint that accepts the data structure
  const response = await fetch(apiUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(commentData)
  });

  if (!response.ok) {
    throw new Error("Failed to create comment");
  }

  return await response.json();
};


// export async function createCommentOnTaskId(
//   taskId: string,
//   description: string,
//   authToken: string
// ): Promise<number | null> {
//   const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/taskActions`;

//   // Converet NewTask type to TaskRequest type
//   const requestBody = {
//     taskId: taskId,
//     description: description,
//   };

//   try {
//     const response = await fetch(apiUrl, {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${authToken}`,
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(requestBody),
//     });

//     if (!response.ok) {
//       console.log(response);
//       return null;
//     }

//     const taskId = await response.text();
//     console.log(taskId);

//     return parseInt(taskId, 10);
//   } catch (error) {
//     console.log("Error posting comment", error);
//     return null;
//   }
// }

export async function getTaskActionTypes(
  authToken: string
): Promise<ActionType[] | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/taskActions/actionTypes`;

  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response);
      return null;
    }

    const actionTypeList = await response.json();

    // Map the task data list to an array of TaskActivity objects
    const actions: ActionType[] = actionTypeList.map((actionType: any) => ({
      id: actionType.id,
      actionTypeName: actionType.actionTypeName,
    }));

    return actions;
  } catch (error) {
    console.log("Error posting comment", error);
    return null;
  }
}

export async function getTaskActionSubTypes(
  authToken: string
): Promise<ActionSubType[] | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/taskActions/actionSubTypes`;

  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response);
      return null;
    }

    const actionSubTypeList = await response.json();

    // Map the task data list to an array of TaskActivity objects
    const actions: ActionSubType[] = actionSubTypeList.map(
      (actionSubType: any) => ({
        id: actionSubType.id,
        actionSubTypeName: actionSubType.actionSubTypeName,
        recommendedTimeInMinutes: actionSubType.recommendedTimeInMinutes,
        description: actionSubType.description,
        requiresFile: actionSubType.requiresFile
      })
    );

    return actions;
  } catch (error) {
    console.log("Error posting comment", error);
    return null;
  }
}

export async function createTaskAction(
  authToken: string,
  taskActionRequest: TaskActionRequest,
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/taskActions/new`;

  // Parse completionDate and completionTime
  const [year, month, day] = taskActionRequest.completionDate.split('-');
  const [hours, minutes] = taskActionRequest.completionTime.split(':').map((num: string) => parseInt(num, 10));

  // Create new Date object
  const completionDateTime = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), hours, minutes);

  console.log(taskActionRequest)
  // Converet NewTask type to TaskRequest type
  const requestBody = {
    taskId: taskActionRequest.taskId,
    description: taskActionRequest.description,
    subTypeId: taskActionRequest.subTypeId, 
    durationInMinutes: taskActionRequest.durationInMinutes, 
    completionTime: completionDateTime
  };

  console.log('REQUEST BODY', requestBody)

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      console.log(response);
      return null;
    }

    const actionId: number = await response.json();

    return actionId;
  } catch (error) {
    console.log("Error posting activity", error);
    return null;
  }
}

export async function publishTaskActionTemplate(
  authToken: string,
  taskActionRequest: NewTaskActionType,
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/taskActions/new/template`;

  console.log(taskActionRequest)
  // Converet NewTask type to TaskRequest type
  const requestBody = {
    activityName: taskActionRequest.activityName,
    description: taskActionRequest.description,
    actionSubTypeId: taskActionRequest.actionSubTypeId, 
    durationInMinutes: taskActionRequest.estimated_ttc, 
    actionTypeId: taskActionRequest.actionTypeId,
    requiresFile: taskActionRequest.requiresFile === "" ? null : taskActionRequest.requiresFile
  };

  console.log('REQUEST BODY', requestBody)

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      const errorMessage = await response.text(); // Read error message from response body
      console.error("Error message:", errorMessage);
      return null;
    }

    const actionId: number = await response.json();

    return actionId;
  } catch (error) {
    console.log("Error posting activity", error);
    return null;
  }
}

export async function updateTaskActionTemplate(
  authToken: string,
  taskActionRequest: UpdateTaskActionType,
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/taskActions/template`;

  console.log(taskActionRequest)
  // Converet NewTask type to TaskRequest type
  const requestBody = {
    id: taskActionRequest.id,
    activityName: taskActionRequest.activityName,
    description: taskActionRequest.description,
    actionSubTypeId: taskActionRequest.actionSubTypeId, 
    durationInMinutes: taskActionRequest.estimated_ttc, 
    actionTypeId: taskActionRequest.actionTypeId,
    requiresFile: taskActionRequest.requiresFile
  };

  console.log('REQUEST BODY', requestBody)

  try {
    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      console.log(response);
      return null;
    }

    const actionId: number = await response.json();

    return actionId;
  } catch (error) {
    console.log("Error posting activity", error);
    return null;
  }
}

export async function getTaskActionTemplate(
  authToken: string,
): Promise<ActionSubType[] | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/taskActions/templates`;

  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response);
      return null;
    }

    const templates: ActionSubType[] = await response.json();

    console.log(templates)
    return templates;
  } catch (error) {
    console.log("Error posting activity", error);
    return null;
  }
}

export async function markTaskActionForDeletion(
  authToken: string,
  actionId: string
): Promise<boolean> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/taskActions/markActionForDeletion/${actionId}`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }
    });

    if (!response.ok) {
      console.log(response);
      return false;
    }

    return true;
  } catch (error) {
    console.log("Error posting activity", error);
    return false;
  }
}

export async function markTaskActionForUnDeletion(
  authToken: string,
  actionId: string
): Promise<boolean> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/taskActions/markActionForUnDeletion/${actionId}`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }
    });

    if (!response.ok) {
      console.log(response);
      return false;
    }

    return true;
  } catch (error) {
    console.log("Error posting activity", error);
    return false;
  }
}

export async function updateTaskAction(
  authToken: string,
  actions: TaskActionUpdateRequest[]
): Promise<boolean> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/taskActions/updateTaskActions`;

  try {
    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }, 
      body: JSON.stringify(actions),
    });

    const contentType = response.headers.get("content-type");

    // Check if the response is JSON or something else
    let responseData;
    if (contentType && contentType.includes("application/json")) {
      responseData = await response.json();
    } else {
      const responseText = await response.text();
      responseData = responseText;
    }

    if (!response.ok) {
      console.error("Failed to update task actions:", responseData);
      return false;
    }

    console.log("Task actions updated successfully:", responseData);
    return true;
  } catch (error) {
    console.error("Error posting activity:", error);
    return false;
  }
}
