// src/components/CreateFolder.tsx
import React, { useState } from "react";
import {
  createFolder,
  getFolderContents,
} from "../../../../../services/fileServices/UserDriverService";
import { UserFolderResponse } from "../../../../../interfaces/userDrive/userFolderResponse";
import { useAuth } from "../../../../../firebase/AuthProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

interface CreateFolderProps {
  currentFolderId?: number;
  onFolderCreated: (updatedContents: UserFolderResponse) => void;
  closeFolder: () => void;
}

export const CreateFolder: React.FC<CreateFolderProps> = ({
  currentFolderId,
  onFolderCreated,
  closeFolder,
}) => {
  const { currentUser } = useAuth();

  const [folderName, setFolderName] = useState("");

  const handleCreateFolder = async () => {
    if (!folderName) return;

    try {
      if (currentUser) {
        const authToken = await currentUser.getIdToken();
        await createFolder(authToken, {
          name: folderName,
          parentFolderId: currentFolderId,
        });
        setFolderName("");
        // Refresh the folder contents
        const updatedContents = await getFolderContents(
          authToken,
          currentFolderId
        );
        if (updatedContents) {
          onFolderCreated(updatedContents);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="create-folder flex flex-col gap-5 w-full">
      <div className="absolute top-5 right-5 rounded-full hover:bg-gray-300 w-7 h-7 flex justify-center align-middle items-center cursor-pointer" onClick={closeFolder}>
        <FontAwesomeIcon icon={faX} />
      </div>
      <div className="text-xl text-center font-bold">New Folder</div>
      <div className="flex flex-col gap-5">
        <input
          type="text"
          className="w-full rounded border-none shadow"
          placeholder="New folder name"
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
        />
        <button
          className="bg-[#3DD598] text-white rounded w-full p-2"
          onClick={handleCreateFolder}
        >
          Create Folder
        </button>
      </div>
    </div>
  );
};
