import React, { useEffect, useState } from "react";
import EventNotification from "../../../interfaces/eventNotification/EventNotification";
import TextEditor from "../textEditor/TextEditor";
import { getTimeAgo } from "../../../utilities/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faCheckCircle,
  faList,
  faUser,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons"; // Use the icon of your choice
import { useNavigate } from "react-router-dom";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";
import Avatar from "../../../interfaces/avatar/Avatar";

interface MessageViewProps {
  notification: EventNotification;
}

const MessageView: React.FC<MessageViewProps> = ({ notification }) => {
  type ReplacementType = {
    [key: string]: string;
  };
  const [avatars, setAvatars] = useState<Avatar[] | null>([]);

  useEffect(() => {
    const storedAvatars = getAvatarsFromStorage();
    setAvatars(storedAvatars);
  }, []);

  const findAvatarById = (userId: string | undefined): string | null => {
    if (userId) {
      const avatarObj = avatars?.find((avatar) => avatar.id === userId);
      return avatarObj ? `data:image/png;base64,${avatarObj.avatar}` : null;
    } else {
      return null;
    }
  };

  const navigate = useNavigate();

  const renderMessageWithLinks = (
    message: string,
    isTitle: boolean = false
  ) => {
    const replacements: ReplacementType = {
      "{task}": "task",
      "{author}": "user",
      "{file}": "file",
      "{activity}": "task_action",
    };

    const parts = message.split(/(\{[a-zA-Z]+\})/g); // Split the message by placeholders
    return parts.map((part, index) => {
      if (replacements[part]) {
        const type = replacements[part];
        const resource = notification.notificationResources?.find(
          (res: any) => res.resourceType === type
        );
        if (type === "task_action") {
          return (
            <a
              key={index}
              className="inline-flex align-middle items-center bg-[rgba(108,117,125,0.2)] px-2 py-0.5 cursor-pointer rounded truncate shadow-none border-2 border-[rgba(108,117,125,0.2)] hover:shadow-lg"
              href={resource?.resourceId}
            >
              <FontAwesomeIcon icon={faBolt} className="text-[#6C757D] mr-1" />
              <span>
                {notification.notificationResources?.find(
                  (res: any) => res.resourceType === type
                )?.resourceName || "Activity"}
              </span>
            </a>
          );
        }
        if (type === "user") {
          return (
            <a
              key={index}
              className="inline-flex align-middle items-center bg-[rgba(108,117,125,0.2)] px-2 py-0.5 cursor-pointer rounded truncate shadow-none border-2 border-[rgba(108,117,125,0.2)] hover:shadow-lg"
              href={resource?.resourceId}
            >
              <FontAwesomeIcon icon={faUser} className="text-[#6C757D] mr-1" />
              <span>
                {notification.notificationResources?.find(
                  (res: any) => res.resourceType === type
                )?.resourceName || "Activity"}
              </span>
            </a>
          );
        }
        if (type === "task" && !isTitle) {
          return (
            <a
              key={index}
              className="inline-flex align-middle items-center bg-[rgba(108,117,125,0.2)] px-2 py-0.5 cursor-pointer rounded truncate text-[14px] font-normal shadow-none border-2 border-[rgba(108,117,125,0.2)] hover:shadow-lg"
              href={resource?.resourceId}
            >
              <FontAwesomeIcon icon={faList} className="text-[#6C757D] mr-1" />
              <span>
                {notification.notificationResources?.find(
                  (res: any) => res.resourceType === type
                )?.resourceName || "Activity"}
              </span>
            </a>
          );
        }
        // else if (type === "task" && isTitle) {
        //   return (
        //     <div
        //       key={index}
        //       className="inline-flex align-middle items-center bg-[rgba(108,117,125,0.2)] px-2 py-0.5 cursor-pointer rounded truncate text-[14px] font-normal"
        //     >
        //       <FontAwesomeIcon icon={faList} className="text-primary mr-1" />
        //       <span>{notification.notificationResources?.find((res: any) => res.resourceType === type)?.resourceName || "Activity"}</span>
        //     </div>
        //   );
        // }
        if (resource) {
          return (
            <div
              key={index}
              className="inline"
              // href={resource.resourceId}
            >
              {resource.resourceName}
            </div>
          );
        }
      }
      return <span key={index}>{part}</span>; // If no replacement, return the part as is
    });
  };

  return (
    <div className={`inter-font flex justify-start align-middle gap-2 p-2 max-h-[85px]`}>
      <div className="flex align-middle justify-center">
        <div className="rounded-full w-10 h-10 bg-background dark:bg-background-dark my-auto">
          <div>
            {notification.createdByUser && (
              <div className="relative">
                {findAvatarById(notification.createdByUser?.id) ? (
                  <img
                    src={findAvatarById(notification.createdByUser?.id)!}
                    alt="profile_picture"
                    className="h-10 w-10 rounded-full me-2"
                  />
                ) : (
                  <div className="w-10 h-10 font-bold flex items-center justify-center rounded-full bg-gray-300 dark:text-text">
                    <div className="2xl:text-sm">
                      {notification.createdByUser?.firstName[0] +
                        "" +
                        notification.createdByUser?.lastName[0]}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>{" "}
        </div>
      </div>
      <div className="text-[15px] flex flex-col w-full py-1">
        <div className={`text-start font-bold flex items-center align-middle gap-2`}>
          <div>{renderMessageWithLinks(notification.title, true)}</div>
          {notification.status === "success" && (
            <div>
              <FontAwesomeIcon icon={faCheckCircle} className="text-green-600"/>
            </div>
          )}
          {notification.status === "error" && (
            <div>
              <FontAwesomeIcon icon={faXmarkCircle} className="text-red-600"/>
            </div>
          )}
        </div>
        <div className="text-[14px] col-span-2 text-ellipsis text-start ">
          {renderMessageWithLinks(notification.message)}
        </div>
        <div className="text-muted text-end text-xs">
          {getTimeAgo(notification.createdAt)}
        </div>
      </div>
    </div>
  );
};

export default MessageView;
