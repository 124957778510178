// MonthlyView.tsx
import React from 'react';
import { Event } from '../../../interfaces/Event';

interface MonthlyViewProps {
  events: Event[];
  setSelectedEventFunction: (event: Event) => void;
}

const MonthlyView: React.FC<MonthlyViewProps> = ({ events, setSelectedEventFunction }) => {
  const getDaysInMonth = (year: number, month: number) => new Date(year, month, 0).getDate();
  const getFirstDayOfMonth = (year: number, month: number) => new Date(year, month - 1, 1).getDay();

  const renderCalendarDays = () => {
    const today = new Date();
    const daysInMonth = getDaysInMonth(today.getFullYear(), today.getMonth() + 1);
    const firstDay = getFirstDayOfMonth(today.getFullYear(), today.getMonth() + 1);

    let days = [];
    // Fill in the blanks for days before the first day of the month
    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="p-2 h-32"></div>);
    }

    // Fill in the actual days of the month
    for (let day = 1; day <= daysInMonth; day++) {
        const currentDayEvents = events.filter(event =>
          new Date(event.date).getDate() === day &&
          new Date(event.date).getMonth() === today.getMonth() &&
          new Date(event.date).getFullYear() === today.getFullYear()
        );
  
        days.push(
          <div key={day} className="bg-card dark:bg-card-dark relative h-32 overflow-y-auto scrollbar-thin scrollbar-corner-transparent scrollbar-track-primary-dark scrollbar-thumb-primary dark:scrollbar-track-primary-dark dark:scrollbar-thumb-primary m-0.5">
            <div className="text-xs absolute top-1 left-1 text-muted">{day}</div>
            <div className="flex flex-col space-y-1 mt-7">
              {currentDayEvents.map(event => (
                <div key={event.id} onClick={() => setSelectedEventFunction(event)} className="bg-primary dark:bg-primary text-text dark:text-text-dark w-full text-xsm cursor-pointer ps-2">
                  {event.title}
                </div>
              ))}
            </div>
          </div>
        );
      }
      return days;
  };

  return (
    <div className='w-100 m-auto text-muted dark:text-muted-dark'>
      <div className="grid grid-cols-7 w-full">
        {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map(day => (
          <div key={day} className="border p-2 text-xs text-muted">{day}</div>
        ))}
      </div>
      <div className="grid grid-cols-7 w-full h-14">
        {renderCalendarDays()}
      </div>
    </div>
  );
};

export default MonthlyView