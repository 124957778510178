import React, { useEffect, useState } from "react";
import TaskList from "../../../interfaces/task/TaskList";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";
import Avatar from "../../../interfaces/avatar/Avatar";
import { getTasks } from "../../../services/taskServices/TaskService";
import { useAuth } from "../../../firebase/AuthProvider";
import TasksPageNew from "./TaskPageNew";
import { getTaskLKValues } from "../../../services/taskServices/TaskLKService";
import { User } from "../../../interfaces/User";
import Participant from "../../../interfaces/Participant";
import { getAllParticipants } from "../../../services/participantServices/ParticipantService";
import Status from "../../../interfaces/task/Status";
import { useLocation } from "react-router-dom";

const TasksPageComponent: React.FC = () => {
  const { currentUser, currentRoles } = useAuth();
  const location = useLocation();
  
  const [tasks, setTasks] = useState<TaskList[] | null>([]);
  const [avatars, setAvatars] = useState<Avatar[] | null>([]);
  const [assignees, setAssignees] = useState<User[]>([]);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [statuses, setStatuses] = useState<Status[] | null | undefined>();


  useEffect(() => {
    const storedAvatars = getAvatarsFromStorage();
    setAvatars(storedAvatars);
  }, []);

  useEffect(() => {
    if (currentUser !== null) {
      const fetchTasksForCurrentUser = async () => {
        const authToken: string = await currentUser.getIdToken();
        let fetchedTasks;
        if (currentRoles?.some((role) => role.name !== "SC")) {
          fetchedTasks = await getTasks(authToken).then((task) =>
            task?.filter((t) => t.status.name !== "COMPLETED")
          );
        } else {
          fetchedTasks = await getTasks(authToken, currentUser.uid).then(
            (task) => task?.filter((t) => t.status.name !== "COMPLETED")
          );
        }
        const sortedTasks = fetchedTasks?.sort(
          (a, b) =>
            new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
        );
        if (sortedTasks) {
          setTasks(sortedTasks);
        }
      };

      const fetchLkValues = async () => {
        if (currentUser !== null) {
          const authToken: string = await currentUser.getIdToken();
          await getTaskLKValues(authToken).then((data) => {
            if (data) {
              setAssignees(data?.users);
            }
            setStatuses(data?.statuses);
          });
          await getAllParticipants(authToken).then((data) => {
            if (data) setParticipants(data);
          });
        }
      };
      fetchLkValues();
      fetchTasksForCurrentUser();
    }
  }, [currentUser, currentRoles]);

  return (
    <div>
        <TasksPageNew
          assignees={assignees || []}
          tasks={tasks || []}
          participants={participants || []}
          statuses={statuses || []}
          avatars={avatars || []}
        />
    </div>
  );
};

export default TasksPageComponent;
