import React from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import EventNotification from "../../../interfaces/eventNotification/EventNotification";
import NotificationBlock from "./NotificationBlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

interface NotificationDropDownProps { 
    notifications: EventNotification[];
}

const NotificationDropDown: React.FC<NotificationDropDownProps> = ({notifications}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const sortedNotifications = [...notifications].sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateB.getTime() - dateA.getTime(); // Ascending order
  });

  const topNotifications = sortedNotifications.slice(0, 5);

  return (
    <div className="absolute right-1 top-2 text-[12px] 2xl:text-sm bg-white text-text inter-font dark:bg-card-dark shadow-lg 2xl:w-[400px] w-[300px] rounded-3xl max-h-[400px]">
      <div className="font-bold text-left bg-[#F1F1F1F1] dark:bg-background-dark rounded-t-3xl p-5 py-4">
        Notifications (<span className={`2xl:text-[15px] text-[12px] ${sortedNotifications.filter(n => n.read === false).length === 0 ? 'text-gray-500' : ''}`}>{sortedNotifications.filter(n => n.read === false).length}</span>)
      </div>
      <div className="bg-white rounded-b-3xl overflow-y-auto max-h-[350px] scrollbar">
        {topNotifications.length > 0 &&
          topNotifications.map((data, index) => (
            <div key={index} className="bg-white">
              <NotificationBlock notification={data} />
            </div>
          ))}
        {sortedNotifications.length > 5 && (
          <div className="text-center text-primary dark:text-primary-dark rounded-b-3xl cursor-pointer hover:underline " onClick={() => navigate('/profile/inbox')}>
            <div className="flex gap-2 justify-center bg-white p-2 dark:bg-background-dark rounded-b-3xl">
                <div><FontAwesomeIcon icon={faEye} /></div>
                <div>View more</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationDropDown;
