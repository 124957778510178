// FileService.ts

import Avatar from "../../interfaces/avatar/Avatar";
import DriveFile from "../../interfaces/file/DriveFile";
import DriveFiles from "../../interfaces/file/DriveFiles";
import DriveFilesByEmployee from "../../interfaces/file/DriveFilesByEmployee";
import NewTemplateFile from "../../interfaces/task/TaskFiles/NewTemplateFile";
import { TaskRequiresAttachment } from "../../interfaces/task/TaskFiles/TaskRequiresAttachment";
import TemplateFile from "../../interfaces/task/TaskFiles/TemplateFile";

// Function to fetch all participants of an employee
export async function uploadFile(
  file: File,
  taskId: string,
  authToken: string
): Promise<boolean | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/upload`;

    // We are not using participant ID here, that's now handled by the API; value overwritten
    const orgId: string = "1";
    const regionId: string = "2";
    const participantId: string = "2";
    const typeId: string = "1";

    const formData = new FormData();
    formData.append("file.File", file);
    formData.append("file.OrganizationId", orgId);
    formData.append("file.RegionId", regionId);
    formData.append("file.participantId", participantId);
    formData.append("file.TypeId", typeId);
    formData.append("file.Taskid", taskId);

    const response = await fetch(apiUrl, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (response.ok) {
      return true;
      // Handle success
    } else {
      // Handle failure
      console.error("Upload failed");
      // Print out the response body if available
      const responseBody = await response.json().catch((parseError) => {
        console.error("Error parsing JSON:", parseError);
      });
      console.log("Error Response Body:", responseBody);
      return false;
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    // Handle error
    return false;
  }
}

export async function uploadFileInPlace(
  file: File,
  taskId: string,
  attachmentId: number,
  authToken: string
): Promise<boolean | null> {
  try {
    const apiUrl: string = `${
      process.env.REACT_APP_API_BASE_URL
    }/file/upload/${attachmentId.toString()}`;

    // We are not using participant ID here, that's now handled by the API; value overwritten
    const orgId: string = "1";
    const regionId: string = "2";
    const participantId: string = "2";
    const typeId: string = "1";

    const formData = new FormData();
    formData.append("file.File", file);
    formData.append("file.OrganizationId", orgId);
    formData.append("file.RegionId", regionId);
    formData.append("file.participantId", participantId);
    formData.append("file.TypeId", typeId);
    formData.append("file.Taskid", taskId);

    const response = await fetch(apiUrl, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (response.ok) {
      return true;
      // Handle success
    } else {
      // Handle failure
      console.error("Upload failed");
      // Print out the response body if available
      const responseBody = await response.json().catch((parseError) => {
        console.error("Error parsing JSON:", parseError);
      });
      console.log("Error Response Body:", responseBody);
      return false;
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    // Handle error
    return false;
  }
}

// Function to fetch all participants of an employee
// We are not using participant ID here, that's now handled by the API; value overwritten
export async function uploadRequiredFile(
  file: File,
  taskId: string,
  attachment: TaskRequiresAttachment,
  durationInMinutes: string,
  authToken: string
): Promise<boolean | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/upload`;

    const orgId: string = "1";
    const regionId: string = "2";
    const participantId: string = "2";
    const typeId: string = "1";

    const formData = new FormData();
    formData.append("file.File", file);
    formData.append("file.FileName", attachment.templateFile.fileName);
    formData.append("file.DurationInMinutes", durationInMinutes)
    formData.append("file.RequiredAttachmentId", attachment.id.toString());
    formData.append("file.OrganizationId", orgId);
    formData.append("file.RegionId", regionId);
    formData.append("file.participantId", participantId);
    formData.append("file.TypeId", typeId);
    formData.append("file.Taskid", taskId);

    const response = await fetch(apiUrl, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (response.ok) {
      return true;
      // Handle success
    } else {
      console.error("Upload failed");
      return false;
      // Handle failure
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    // Handle error
    return false;
  }
}

export async function getDriveFilesByUser(
  authToken: string
): Promise<DriveFiles | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/drive`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    const data: DriveFiles = await response.json();
    return data;
  } catch (err) {
    console.error("Error fetching files:", err);
    return null;
  }
}

export async function getDriveAllFiles(
  authToken: string
): Promise<DriveFilesByEmployee | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/drive/all`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    const data: DriveFilesByEmployee = await response.json();
    return data;
  } catch (err) {
    console.error("Error fetching files:", err);
    return null;
  }
}

export async function getEmployeeDriveFiles(
  authToken: string,
  userId: string
): Promise<DriveFilesByEmployee | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/drive/employee/${userId}`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    const data: DriveFilesByEmployee = await response.json();
    return data;
  } catch (err) {
    console.error("Error fetching files:", err);
    return null;
  }
}

export async function getDriveFileById(
  id: string,
  authToken: string
): Promise<DriveFile | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/metadata/${id}`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    const data: DriveFile = await response.json();
    return data;
  } catch (err) {
    console.error("Error fetching files:", err);
    return null;
  }
}

export async function getFileById(
  id: string,
  authToken: string
): Promise<string | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/download/${id}`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    const blob = await response.blob();
    console.log('Blob MIME type:', blob.type); // Output the MIME type

    return URL.createObjectURL(blob);
  } catch (err) {
    console.error("Error fetching files:", err);
    return null;
  }
}

export async function fetchAndStoreAvatars(authToken: string): Promise<void> {
  const AvatarRefreshInterval = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  const storedData = localStorage.getItem("userAvatar");

  if (storedData) {
    const { timestamp } = JSON.parse(storedData);
    const currentTime = new Date().getTime();
    const timeElapsed = currentTime - timestamp;

    if (timeElapsed < AvatarRefreshInterval) {
      // Avatars are still fresh, no need to fetch again
      return;
    }
  }

  // Proceed to fetch new avatars if no data or data is outdated
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/avatar`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      console.error("Error fetching avatars:", response.statusText);
      return;
    }

    const avatarsList: Avatar[] = await response.json();

    // Store the avatars with the current timestamp in localStorage
    const dataWithTimestamp = {
      timestamp: new Date().getTime(),
      avatars: avatarsList,
    };

    localStorage.setItem("userAvatars", JSON.stringify(dataWithTimestamp));
  } catch (err) {
    console.error("Error fetching avatars:", err);
  }
}

export function getAvatarsFromStorage(): Avatar[] | null {
  const storedData = localStorage.getItem("userAvatars");

  if (!storedData) {
    return null; // No data in storage, need to fetch
  }

  const { avatars }: { avatars: Avatar[] } = JSON.parse(storedData);

  return avatars;
}

export async function uploadAvatar(
  authToken: string,
  uid: string,
  file: File
): Promise<string> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/upload/avatar`;

  const formData = new FormData();
  formData.append("file", file); // Ensure the key matches what your API expects

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (response.ok) {
      const fileLocation = await response.text(); // Parse the response as a plain string

      // Retrieve existing avatars from localStorage
      const storedAvatars = JSON.parse(localStorage.getItem("userAvatars") || "[]").avatars;

      // Update the storedAvatars array with the new avatar
      const updatedAvatars = storedAvatars.filter((avatar: any) => avatar.id !== uid);
      updatedAvatars.push({ id: uid, avatar: fileLocation });

      // Save the updated avatars list back to localStorage
      localStorage.setItem("userAvatars", JSON.stringify(updatedAvatars));

      return fileLocation;
    } else {
      // Handle failure
      console.error("Upload failed");
      const responseBody = await response.text();
      console.log("Error Response Body:", responseBody);
      return "Error";
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    return "Error";
  }
}


export async function deleteFileById(
  id: string,
  authToken: string
): Promise<boolean | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/delete/${id}`;

  try {
    const response = await fetch(apiUrl, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return true;
  } catch (err) {
    console.error("Error deleting file:", err);
    return null;
  }
}

export async function downloadFile(
  id: string,
  authToken: string
): Promise<Blob | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/download/${id}`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      console.error(`Unable to fetch file ${id} for download`);
      return null;
    }

    return response.blob();
  } catch (err) {
    console.error("Error deleting file:", err);
    return null;
  }
}

export async function getTemplateFiles(
  authToken: string
): Promise<TemplateFile[] | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/templateFiles`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    const data = await response.json();
    var res: TemplateFile[] = data.map((d: any) => ({ 
      id: d.id,
      fileName: d.fileName,
      type: d.type,
      estimatedTtc: d.estimatedTTC
    }))
    return res;
  } catch (err) {
    console.error("Error fetching files:", err);
    return null;
  }
}

export async function updateFileTemplate(
  templateFile: TemplateFile,
  authToken: string
): Promise<TemplateFile | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/templateFile`;

  try {
    // Converet NewTask type to TaskRequest type
    const requestBody = {
      id: templateFile.id,
      fileName: templateFile.fileName,
      type: templateFile.type,
      formId: templateFile.formId,
      estimatedTtc: templateFile.estimatedTtc
    };

    try {
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        console.log(response);
        return null;
      }

      const file: TemplateFile = await response.json();

      return file;
    } catch (error) {
      console.log("Error posting activity", error);
      return null;
    }
  } catch (err) {
    console.error("Error fetching files:", err);
    return null;
  }
}

export async function publishTemplateFile(
  templateFile: NewTemplateFile,
  authToken: string
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/templateFile`;

  try {
    // Converet NewTask type to TaskRequest type
    const requestBody = {
      fileName: templateFile.fileName,
      typeId: templateFile.typeId,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        console.log(response);
        return null;
      }

      const file: number = parseInt(response.text.toString());

      return file;
    } catch (error) {
      console.log("Error posting activity", error);
      return null;
    }
  } catch (err) {
    console.error("Error fetching files:", err);
    return null;
  }
}

export async function getCanUserDeleteTaskFile(
  authToken: string,
  taskId: string
): Promise<boolean> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/canDeleteTaskFiles/${taskId}`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    const data = await response.ok;
    return data;
  } catch (err) {
    console.error("Error fetching files:", err);
    return false;
  }
}
