// DashboardStats.tsx
import React, { useEffect, useState } from "react";
import DashboardStatsInterface from "../../../interfaces/stats/DashboardStats";
import { useAuth } from "../../../firebase/AuthProvider";
import { getDashboardStats } from "../../../services/statServices/DashboardStatsService";
import { convertMinutesToHours } from "../../../utilities/dateUtils";

interface DashboardStatsProps {
  userId?: string;
}

const DashboardStats: React.FC<DashboardStatsProps> = ({ userId = null }) => {
  const { currentUser, currentRoles } = useAuth();

  const [isSC, setIsSC] = useState<boolean>(true); // Default to least privilege
  const [stats, setStats] = useState<DashboardStatsInterface>();

  useEffect(() => {
    if (currentUser !== null) {
      const fetchDashboardStats = async () => {
        const authToken: string = await currentUser.getIdToken();
        let fetchedStats: DashboardStatsInterface | null = null;
        if (userId) {
          fetchedStats = await getDashboardStats(authToken, userId);
        } else {
          fetchedStats = await getDashboardStats(authToken);
        }
        if (fetchedStats) {
          setStats(fetchedStats);
        }
      };

      fetchDashboardStats();
    }
  }, []);

  useEffect(() => { 
    if ( userId ) { 
      // Technically this could be anyone, but SC dashboard would be only important info
      setIsSC(true);
    } 
    else if (currentRoles && currentRoles.length === 1 && currentRoles[0].name === 'SC') { 
      setIsSC(true);
    } else { 
      setIsSC(false);
    }
  }, [currentRoles, userId])

  return (
    <div className={`h-full ${(currentUser?.uid !== userId || !userId) ? '-top-44 w-[1200px]' :'-top-24'} w-full relative z-50 pb-5 me-auto -mt-10 max-w-[1200px] mx-auto`}>
      <div className="mx-auto">
      {/* Updated container class for vertical layout */}
      {stats && (

          <div className={`flex  flex-row justify-between h-full gap-10 xl-custom:flex-col`}>
            <div className="border dark:border-none w-full p-2 bg-card dark:bg-card-dark shadow-lg rounded-3xl min-w-[400px] min-h-[300px] pb-10">
              <div>
                <div className="font-bold text-black dark:text-white text-center font-inter text-xl mb-2 p-5">
                  Time Logged
                </div>
              </div>
              <div className="flex justify-evenly">
                <div className="flex-col justify-center border rounded-3xl border-b min-w-[150px]">
                  <div className="text-center text-lg font-bold p-5 px-10 dark:text-text-dark text-text w-full border-b font-inter">
                    Today
                  </div>
                  <div className="text-center important text-5xl mt-3">
                    {convertMinutesToHours(stats.timeLoggedToday)}
                  </div>
                  <div className="text-muted text-center font-inter my-3">Hours</div>
                </div>
                <div className="flex-col justify-center border rounded-3xl border-b min-w-[150px]">
                <div className="text-center text-lg font-bold p-5 px-10 dark:text-text-dark text-text w-full border-b font-inter">
                    Week
                  </div>
                  <hr className="m-auto" />
                  <div className="text-center important text-5xl mt-3">
                    {convertMinutesToHours(stats.timeLoggedWeek)}
                  </div>
                  <div className="text-muted text-center font-inter my-3">Hours</div>
                </div>
              </div>
            </div>
            <div className="border w-full bg-card dark:bg-card-dark dark:border-none shadow-lg p-2 rounded-3xl min-w-[400px] min-h-[300px] pb-10">
            <div>
                <div className="font-bold text-black dark:text-white text-center font-inter text-xl mb-2 p-5">
                  Number of Activities Logged
                </div>
              </div>
              <div className="flex justify-evenly">
              <div className="flex-col justify-center border rounded-3xl border-b min-w-[150px]">
                  <div className="text-center text-lg font-bold p-5 px-10 dark:text-text-dark text-text w-full border-b font-inter">
                    Today
                  </div>
                  <hr className="m-auto" />
                  <div className="text-center important text-5xl mt-3">
                    {stats.activitiesCompletedToday}
                  </div>
                  <div className="text-muted text-center font-inter my-3">Activities</div>
                </div>
                <div className="flex-col justify-center border rounded-3xl border-b min-w-[150px]">
                <div className="text-center text-lg font-bold p-5 px-10 dark:text-text-dark text-text w-full border-b font-inter">
                    Week
                  </div>
                  <hr className="m-auto" />
                  <div className="text-center important text-5xl mt-3">
                    {stats.activitiesCompletedWeek}
                  </div>
                  <div className="text-muted text-center font-inter my-3">Activities</div>
                </div>
              </div>
            </div>
          </div>
      )}
      </div>
    </div>
  );
};

export default DashboardStats;
