// dateUtils.ts
export function formatDateToMMDDYYYY(date: Date): string {
  date = new Date(date);
  const formattedDate = date.toLocaleDateString("en-us", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return formattedDate.replace(/\//g, "/");
}

export function formatDateToWords(date: Date): string {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  date = new Date(date);
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

export function getTimeAgo(completionTime: Date): string {
  if (!completionTime) return "";

  const completionDate = new Date(completionTime);
  const now = new Date();

  const seconds = Math.abs(
    Math.floor((now.getTime() - completionDate.getTime()) / 1000)
  );

  if (seconds < 60) {
    return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
  } else if (seconds < 2592000) {
    // 30 days (approximate value for a month)
    const days = Math.floor(seconds / 86400);
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  } else if (seconds < 31536000) {
    // 365 days (approximate value for a year)
    const months = Math.floor(seconds / 2592000);
    return `${months} month${months !== 1 ? "s" : ""} ago`;
  } else {
    const years = Math.floor(seconds / 31536000);
    return `${years} year${years !== 1 ? "s" : ""} ago`;
  }
}

export function calculateDateProximity(date: Date): string {
  const today = new Date();
  const endOfToday = new Date(today);
  endOfToday.setHours(23, 59, 59, 999);

  const endOfThisWeek = new Date(today);
  endOfThisWeek.setDate(endOfThisWeek.getDate() + (7 - endOfThisWeek.getDay()));

  const endOfThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  endOfThisMonth.setHours(23, 59, 59, 999);

  if (date >= today && date <= endOfToday) {
    return "Due Today";
  } else if (date > endOfToday && date <= endOfThisWeek) {
    return "Due Soon";
  } else if (date > endOfThisWeek && date <= endOfThisMonth) {
    return "Due This Month";
  } else if (date < today) {
    return "Overdue";
  } else {
    return "On Time";
  }
}

export const convertMinutesToHours = (minutes: number): string => {
  if (minutes === 0) {
    return "0";
  }

  const hours = minutes / 60;
  // Round to 2 decimal places
  const roundedHours = Math.round(hours * 100) / 100;
  // Convert to string with 2 decimal places, unless it's 0
  return roundedHours.toFixed(2).replace(/\.00$/, "");
};


export function getDateXDaysInFuture(days: number | undefined): string {
  const currentDate = new Date(); // Get current date
  if (days) {
    const futureDate = new Date(
      currentDate.getTime() + days * 24 * 60 * 60 * 1000
    ); // Calculate future date in milliseconds
    // Extract year, month, and day from futureDate
    const year = futureDate.getFullYear();
    const month = `${futureDate.getMonth() + 1}`.padStart(2, "0"); // Month is zero-based, so we add 1
    const day = `${futureDate.getDate()}`.padStart(2, "0");

    // Return formatted date string in 'yyyy-MM-dd' format
    return `${year}-${month}-${day}`;
  }
  return "";
}

export function formatDateToDateFormFormat(date:Date)  {
  const d = new Date(date);
  const month = `${d.getMonth() + 1}`.padStart(2, '0');
  const day = `${d.getDate()}`.padStart(2, '0');
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
};

export function formatDateForInput(date: Date) {
  // Adjust for timezone offset
  const tzOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
  const localDate = new Date(date.getTime() - tzOffset);

  // Manually format the date components
  const year = localDate.getFullYear();
  const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
  const day = String(localDate.getDate()).padStart(2, '0');
  const hours = String(localDate.getHours()).padStart(2, '0');
  const minutes = String(localDate.getMinutes()).padStart(2, '0');

  // Return the formatted string
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

