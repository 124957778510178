import React, { useEffect, useState } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import {
  getDashboardStats,
  getTeamStats,
} from "../../../services/statServices/DashboardStatsService";
import TeamStatsInterface from "../../../interfaces/stats/TeamStatsInterface";
import { convertMinutesToHours } from "../../../utilities/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faC,
  faChartArea,
  faChartBar,
  faChartLine,
  faChartPie,
  faChartSimple,
} from "@fortawesome/free-solid-svg-icons";
import DashboardStatsInterface from "../../../interfaces/stats/DashboardStats";
import { useNavigate } from "react-router-dom";

const TeamStats: React.FC = () => {
  const { currentUser, currentRoles } = useAuth();
  const [stats, setStats] = useState<TeamStatsInterface[]>([]);
  const [isSC, setIsSC] = useState<boolean>(true);
  const [fetchedOpenStats, setFetchedOpenStats] =
    useState<DashboardStatsInterface>();
  const [timeLoggedTodayAverage, setTimeLoggedTodayAverage] =
    useState<number>(0);
  const [activitiesCompletedTodayAverage, setActivitiesCompletedTodayAverage] =
    useState<number>(0);
  const [tasksDueTodayAverage, setTasksDueTodayAverage] = useState<number>(0);
  const [timeLoggedWeekAverage, setTimeLoggedWeekAverage] = useState<number>(0);
  const [activitiesCompletedWeekAverage, setActivitiesCompletedWeekAverage] =
    useState<number>(0);
  const [tasksDueWeekAverage, setTasksDueWeekAverage] = useState<number>(0);
  const [tasksOverdueAverage, setTasksOverdueAverage] = useState<number>(0);

  useEffect(() => {
    if (currentUser !== null && currentUser !== undefined) {
      const fetchTemplateFiles = async () => {
        const authToken: string = await currentUser.getIdToken();
        const fetchedStats = await getTeamStats(authToken);
        const fetchedOpenStats = await getDashboardStats(authToken);
        if (fetchedStats) {
          setStats(fetchedStats);
          calculateAverages(fetchedStats);
        }
        if (fetchedOpenStats) {
          setFetchedOpenStats(fetchedOpenStats);
        }
      };

      fetchTemplateFiles();
    }
  }, [
    timeLoggedTodayAverage,
    activitiesCompletedTodayAverage,
    tasksDueTodayAverage,
    timeLoggedWeekAverage,
    activitiesCompletedWeekAverage,
    tasksDueWeekAverage,
    tasksOverdueAverage,
  ]);

  useEffect(() => { 
    if (currentRoles && currentRoles.length === 1 && currentRoles[0].name === 'SC') { 
      setIsSC(true);
    } else { 
      setIsSC(false);
    }
  }, [currentRoles])

  const navigate = useNavigate();

  const calculateAverages = (stats: TeamStatsInterface[]) => {
    var timeLoggedToday = 0;
    var activitiesCompletedToday = 0;
    var tasksDueToday = 0;
    var timeLoggedWeek = 0;
    var activitiesCompletedWeek = 0;
    var tasksDueThisWeek = 0;
    var tasksOverdue = 0;

    stats.map((value, index) => {
      timeLoggedToday = timeLoggedToday + value.timeLoggedToday;
      activitiesCompletedToday =
        activitiesCompletedToday + value.activitiesCompletedToday;
      tasksDueToday = tasksDueToday + value.tasksDueToday;
      timeLoggedWeek = timeLoggedWeek + value.timeLoggedWeek;
      activitiesCompletedWeek =
        activitiesCompletedWeek + value.activitiesCompletedWeek;
      tasksDueThisWeek = tasksDueThisWeek + value.tasksDueThisWeek;
      tasksOverdue = tasksOverdue + value.tasksOverdue;
    });

    const roundToTwoDecimalPlaces = (num: number): number => {
      return Math.round(num * 100) / 100;
    };

    setTimeLoggedTodayAverage(
      roundToTwoDecimalPlaces(timeLoggedToday / stats.length)
    );
    setActivitiesCompletedTodayAverage(
      roundToTwoDecimalPlaces(activitiesCompletedToday / stats.length)
    );
    setTasksDueTodayAverage(
      roundToTwoDecimalPlaces(tasksDueToday / stats.length)
    );
    setTimeLoggedWeekAverage(
      roundToTwoDecimalPlaces(timeLoggedWeek / stats.length)
    );
    setActivitiesCompletedWeekAverage(
      roundToTwoDecimalPlaces(activitiesCompletedWeek / stats.length)
    );
    setTasksDueWeekAverage(
      roundToTwoDecimalPlaces(tasksDueThisWeek / stats.length)
    );
    setTasksOverdueAverage(
      roundToTwoDecimalPlaces(tasksOverdue / stats.length)
    );
  };

  function getColorForStat(value: number, average: number) {
    if (value < average) {
      return "text-green-500 font-bold"; // Value is below average
    } else if (value > average) {
      return "text-danger font-bold"; // Value is above average
    } else {
      return ""; // Value is equal to average
    }
  }

  return (
    <div className={`relative mx-auto bg-card dark:bg-card-dark dark:text-text-dark rounded-3xl shadow-lg -mt-10 -top-44 z-50 pb-5  ${isSC ? 'min-h-[150px]' : 'min-h-[670px]'} min-w-[700px] max-w-[1200px]`}>
      {isSC ? (
        <div></div>
      ) : (
        <div className="p-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900 font-inter mb-5">
            Team Status
          </h3>
          <div className="bg-white shadow-md rounded-3xl mx-5 overflow-auto border">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3"></th>
                  <th className="px-6 py-3"></th>
                  <th className="col-span-3 px-6 py-3 text-center text-xs font-bold text-black uppercase tracking-wider">
                    Today
                  </th>
                  <th className="px-6 py-3"></th>
                  <th className="px-6 py-3"></th>

                  <th className="col-span-3 px-6 py-3 text-center text-xs font-bold text-black uppercase tracking-wider">
                    Week
                  </th>
                  <th className="px-6 py-3"></th>
                  <th className="px-6 py-3"></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                    User
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                    Time Logged
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                    Activities Completed
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                    Task Due
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                    Time Logged
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                    Activities Completed
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                    Task Due
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                    Overdue
                  </td>
                </tr>
                {stats?.map((userStats, index) => (
                  <tr
                    key={index}
                    onClick={() =>
                      navigate(`/dashboard/${userStats.employeeId}`)
                    }
                    className={`${
                      index % 2 === 0 ? "bg-gray-100" : ""
                    } text-center cursor-pointer hover:shadow-lg`}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-start">
                      {userStats.firstName + " " + userStats.lastName}
                    </td>
                    <td
                      className={`px-6 py-4 whitespace-nowrap text-sm text-gray-900 ${getColorForStat(
                        userStats.timeLoggedToday,
                        timeLoggedTodayAverage
                      )}`}
                    >
                      {convertMinutesToHours(userStats.timeLoggedToday)}
                    </td>
                    <td
                      className={`px-6 py-4 whitespace-nowrap text-sm text-gray-900 ${getColorForStat(
                        userStats.activitiesCompletedToday,
                        activitiesCompletedTodayAverage
                      )}`}
                    >
                      {userStats.activitiesCompletedToday}
                    </td>
                    <td
                      className={`px-6 py-4 whitespace-nowrap text-sm text-gray-900 ${getColorForStat(
                        userStats.tasksDueToday,
                        tasksDueTodayAverage
                      )}`}
                    >
                      {userStats.tasksDueToday}
                    </td>
                    <td
                      className={`px-6 py-4 whitespace-nowrap text-sm text-gray-900${getColorForStat(
                        userStats.timeLoggedWeek,
                        timeLoggedWeekAverage
                      )}`}
                    >
                      {convertMinutesToHours(userStats.timeLoggedWeek)}
                    </td>
                    <td
                      className={`px-6 py-4 whitespace-nowrap text-sm text-gray-900 ${getColorForStat(
                        userStats.activitiesCompletedWeek,
                        activitiesCompletedWeekAverage
                      )}`}
                    >
                      {userStats.activitiesCompletedWeek}
                    </td>
                    <td className="grid-span-1 col-start-9">
                      {userStats.tasksDueThisWeek}
                    </td>
                    {userStats.tasksOverdue > 0 ? (
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {userStats.tasksOverdue}
                      </td>
                    ) : (
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {userStats.tasksOverdue}
                      </td>
                    )}
                  </tr>
                ))}

                <tr className="text-center">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900 text-start">
                    Average
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900">
                    {convertMinutesToHours(timeLoggedTodayAverage)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900">
                    {activitiesCompletedTodayAverage}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900">
                    {tasksDueTodayAverage}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900">
                    {convertMinutesToHours(timeLoggedWeekAverage)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900">
                    {activitiesCompletedWeekAverage}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900">
                    {tasksOverdueAverage}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      <div className="p-4">
        <div className="mb-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900 font-inter mb-5">
            Activity Level
          </h3>
        </div>
        <div className="grid grid-cols-5 gap-4">
          <div
            className="bg-gradient-to-r from-[#776CE6] to-[#A39AFF] px-4 rounded-3xl text-white min-h-[172px] pt-7 shadow-2xl cursor-pointer hover:shadow-lg"
            onClick={() => navigate("/tasks?type=monthly")}
          >
            <div className="text-xl font-semibold mb-3">Monthly Open</div>
            <div className="flex justify-between">
              <div>
                <div className="font-bold text-5xl">
                  {fetchedOpenStats?.numberOfMonthlies}
                </div>
                <div className="text-lg">Tasks</div>
              </div>
              <div className="magicpattern text-white text-6xl flex items-end">
                <FontAwesomeIcon icon={faChartBar} />
              </div>
            </div>
          </div>
          <div
            className="bg-gradient-to-r from-[#12A7FF] to-[#69C8FF] px-4 rounded-3xl text-white min-h-[172px] pt-7 shadow-2xl cursor-pointer hover:shadow-lg"
            onClick={() => navigate("/tasks?type=quarterly")}
          >
            <div className="text-xl font-semibold mb-3">Quarterly Open</div>
            <div className="flex justify-between">
              <div>
                <div className="font-bold text-5xl">
                  {fetchedOpenStats?.numberOfQuarterlies}
                </div>
                <div className="text-lg">Tasks</div>
              </div>
              <div className="magicpattern text-white text-6xl flex items-end">
                <FontAwesomeIcon icon={faChartArea} />
              </div>{" "}
            </div>
          </div>
          <div
            className="bg-gradient-to-r from-[#C650FF] to-[#E3A9FF] px-4 rounded-3xl text-white min-h-[172px] pt-7 shadow-2xl cursor-pointer hover:shadow-lg"
            onClick={() => navigate("/tasks?type=annual")}
          >
            <div className="text-xl font-semibold mb-3">Annual Open</div>
            <div className="flex justify-between">
              <div>
                <div className="font-bold text-5xl">
                  {fetchedOpenStats?.numberOfAnnuals}
                </div>
                <div className="text-lg">Tasks</div>
              </div>
              <div className="magicpattern text-white text-6xl flex items-end">
                <FontAwesomeIcon icon={faChartPie} />
              </div>{" "}
            </div>
          </div>
          <div
            className="bg-gradient-to-r from-[#00AD79] to-[#21F8B7] px-4 rounded-3xl text-white min-h-[172px] pt-7 shadow-2xl cursor-pointer hover:shadow-lg"
            onClick={() => navigate("/tasks?type=interim")}
          >
            <div className="text-xl font-semibold mb-3">Interim Open</div>
            <div className="flex justify-between">
              <div>
                <div className="font-bold text-5xl">
                  {fetchedOpenStats?.numberOfInterims}
                </div>
                <div className="text-lg">Tasks</div>
              </div>
              <div className="magicpattern text-white text-6xl flex items-end">
                <FontAwesomeIcon icon={faChartLine} />
              </div>
            </div>
          </div>
          <div
            className="bg-gradient-to-r from-[#2964FF] to-[#98B4FF] px-4 rounded-3xl text-white min-h-[172px] pt-7 shadow-2xl cursor-pointer hover:shadow-lg"
            onClick={() => navigate("/tasks?date=today")}
          >
            <div className="text-xl font-semibold mb-3">Closed Today</div>
            <div className="flex justify-between">
              <div>
                <div className="font-bold text-5xl">
                  {fetchedOpenStats?.tasksClosedToday}
                </div>
                <div className="text-lg">Tasks</div>
              </div>
              <div className="magicpattern text-white text-6xl flex items-end">
                <FontAwesomeIcon icon={faChartSimple} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamStats;
