import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../navigation/Breadcrumb/Breadcrumb";
import FileList from "./FileList";
import { getDriveFilesByUser, getEmployeeDriveFiles } from "../../../../services/fileServices/FileService";
import FolderList from "./FolderList";
import { useAuth } from "../../../../firebase/AuthProvider";
import EmployeeDriveBreadcrumbs from "../../../navigation/Breadcrumb/EmployeeDriveBreadcrumbs";

const EmployeeDrive: React.FC = () => {
  const {currentUser} = useAuth();
  const { userId, participant, year } = useDriveParams();

  const [currentFiles, setCurrentFiles] = useState<any | null>(null); // Use any for currentFiles
  const [fileCounts, setFileCounts] = useState<{ [key: string]: number }>({});
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      const fetchDriveFiles = async () => {
        if (currentUser) { 
          const authToken: string = await currentUser.getIdToken();
          const driveFiles = await getEmployeeDriveFiles(authToken, userId);
          setCurrentFiles(driveFiles);
          if (!participant) {
            const counts = transformData(driveFiles);
            setFileCounts(counts);
          } else if (participant && !year && driveFiles) {
            const counts = transformData(driveFiles[participant]);
            setFileCounts(counts);
          }
        }
      };
      fetchDriveFiles();
    }
  }, [userId, participant, year]);

  useEffect(() => {
    if (currentFiles) {
      if (participant && !year) {
        const counts = transformData(currentFiles[participant]);
        setFileCounts(counts);
      } else if (participant && year) {
        const counts = transformData(currentFiles[participant][year]);
        setFileCounts(counts);
      }
    }
  }, [currentFiles, participant, year]);

  function useDriveParams() {
    const params = useParams();
    return {
      userId: params.userId ? decodeURIComponent(params.userId) : undefined,
      participant: params.participant ? decodeURIComponent(params.participant) : undefined,
      year: params.year ? decodeURIComponent(params.year) : undefined,
    };
  }

  const routeOnClick = (to: string) => {
    if (participant) navigate(participant + "/" + to);
    else navigate(to);
  };

  const transformData = (data: any): { [key: string]: number } => {
    const counts: { [key: string]: number } = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (Array.isArray(data[key])) {
          counts[key] = data[key].length;
        } else {
          counts[key] = Object.keys(data[key]).reduce((acc, k) => acc + data[key][k].length, 0);
        }
      }
    }
    return counts;
  };

  return (
    <div className="relative bg-[#F7F9FC] dark:bg-background-dark sm:min-w-[640px] overflow-x-auto max-w-full h-screen">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="-top-64 relative dark:bg-card-dark dark:text-text-dark bg-[#F7F9FF] p-5 rounded-3xl shadow-lg w-11/12 mx-auto min-h-[600px] -mt-10">
        <div className="flex align-middle items-center">
          <h1 className="ps-4 justify-start text-start text-2xl text-text inter-font p-5">
            {/* @TODO: User's name here */}
            User Drive
          </h1>
          <div>
            <EmployeeDriveBreadcrumbs/>
          </div>
        </div>
        <div className="flex flex-wrap">
          <Routes>
            <Route
              path=""
              element={
                <FolderList
                  folders={
                    currentFiles !== null && currentFiles !== undefined
                      ? Object.keys(currentFiles)
                      : []
                  }
                  routeOnClick={routeOnClick}
                  fileCounts={fileCounts}
                />
              }
            ></Route>
            <Route
              path="/:participant/:year/*"
              element={
                <FileList
                  files={
                    currentFiles !== null &&
                    currentFiles !== undefined &&
                    participant &&
                    year &&
                    currentFiles[participant]?.[year]
                      ? currentFiles[participant][year]
                      : []
                  }
                  routeOnClick={routeOnClick}
                />
              }
            />
            <Route
              path="/:participant/*"
              element={
                <FolderList
                  folders={
                    currentFiles !== null && currentFiles && participant
                      ? Object.keys(currentFiles[participant])
                      : []
                  }
                  routeOnClick={routeOnClick}
                  fileCounts={fileCounts}
                />
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDrive;
