import React, { useEffect, useState } from "react";
import Select, { ActionMeta, MultiValue, SingleValue } from "react-select";
import TaskCreationLKValues from "../../../../interfaces/lkValues/TaskCreationLKValues";
import { useAuth } from "../../../../firebase/AuthProvider";
import { getTaskLKValues } from "../../../../services/taskServices/TaskLKService";
import {
  publishInterimTemplate,
  publishTask,
} from "../../../../services/taskServices/TaskService";
import { useNavigate } from "react-router-dom";
import {
  getAllParticipants,
  getAssignedParticipants,
} from "../../../../services/participantServices/ParticipantService";
import Participant from "../../../../interfaces/Participant";
import TaskWorkflowGroup from "../../../../interfaces/task/workflow/TaskWorkflowGroup";
import { getWorkflowGroups } from "../../../../services/taskServices/TaskWorkflowServices";
import NewInterimTemplate from "../../../../interfaces/task/NewInterimTemplate";
import TextEditor from "../../textEditor/TextEditor";
import TemplateFile from "../../../../interfaces/task/TaskFiles/TemplateFile";
import { getTemplateFiles } from "../../../../services/fileServices/FileService";

interface OptionType {
  value: string;
  label: string;
}

const AddTemplateInterimTask: React.FC = () => {
  const { currentUser, currentOrganization } = useAuth();
  const navigate = useNavigate();

  const [fileTemplates, setFileTemplates] = useState<TemplateFile[] | null>();
  const [formattedFileTemplates, setFormattedFileTemplates] = useState<
    OptionType[]
  >([]);

  const [newTemplate, setNewTemplate] = useState<NewInterimTemplate>({
    title: "",
    description: "",
    information: "",
    requiredFiles: [],
    assignee: undefined,
    coAssignee: undefined,
    participant: undefined,
    daysFromCreationDueDate: "0",
    taskOrganization: "1",
    taskType: "1",
    taskSubType: "4",
    dueDate: "",
    workflowGroup: "Standard",
  });

  const [lkOptions, setLkOptions] = useState<
    TaskCreationLKValues | null | undefined
  >();

  useEffect(() => {
    // Fetch the task with taskId and update the state
    const fetchLkValues = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedValues: TaskCreationLKValues | null =
          await getTaskLKValues(authToken);
        setLkOptions(fetchedValues);
      }

      if (currentUser !== null) {
        const fetchTemplateFiles = async () => {
          const authToken: string = await currentUser.getIdToken();
          const fetchedTemplateFiles = await getTemplateFiles(authToken);
          setFileTemplates(fetchedTemplateFiles);
        };

        fetchTemplateFiles();
      }
    };

    if (lkOptions && fileTemplates) {
      const formattedFileTemplates = fileTemplates.map((file) => ({
        value: file.id.toString(),
        label: file.fileName,
      }));
      if (formattedFileTemplates) {
        setFormattedFileTemplates(formattedFileTemplates);
      }
    }
    if (!lkOptions) {
      fetchLkValues();
    }
  }, [lkOptions, fileTemplates]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setNewTemplate({ ...newTemplate, [name]: value });
  };

  const handleDayInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTemplate({
      ...newTemplate,
      daysFromCreationDueDate: event.target.value,
    });
  };

  const handleRequiredFileChanges = (
    selectedOptions: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    const requiredFiles = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setNewTemplate({ ...newTemplate, requiredFiles });
  };

  const handleInfoChange = (infoText: string) => {
    setNewTemplate({ ...newTemplate, information: infoText });
  };

  //   const handleCustomChange = (
  //     fieldName: keyof newTemplate,
  //     fieldValue: string | undefined
  //   ) => {
  //     setNewTemplate({ ...newTemplate, [fieldName]: fieldValue });
  //   };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log(newTemplate);
    // return;
    // Handle submitting the new task
    const createTask = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const newTaskId: number | null = await publishInterimTemplate(
          authToken,
          newTemplate
        );
        return newTaskId;
      }
      return null;
    };
    const idPromise = createTask();

    idPromise.then((id) => {
      if (id !== null && id !== undefined) {
        // Redirect to new task here.
        navigate(`/edit/interims`);
      } else {
        console.error("Failed to create task");
      }
    });
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewTemplate({ ...newTemplate, [name]: value });
  };

  return (
    <div className="bg-[#F7F9FC]">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="max-w-screen-sm mx-auto text-text dark:text-text-dark p-5 relative w-11/12 rounded-3xl -top-64 bg-white">
        <h1 className="heading-2 text-text inter-font text-center">New Interim Template</h1>
        <form
          onSubmit={handleSubmit}
          className="max-w-xl mx-auto mt-8 bg-card dark:text-text dark:bg-card-dark shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            >
              Title:
            </label>
            <input
              type="text"
              id="title"
              name="title"
              placeholder="Give your task a title..."
              value={newTemplate.title}
              onChange={handleChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-text dark:text-text text-sm font-bold mb-2"
            >
              Description:
            </label>
            <textarea
              id="description"
              name="description"
              placeholder="A brief description of what the task entails..."
              value={newTemplate.description}
              onChange={handleChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-text dark:text-text leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="info"
              className="block text-text dark:text-text text-sm font-bold mb-2"
            >
              Information:
            </label>
            <TextEditor
              text={newTemplate.information}
              setText={handleInfoChange}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="requiredFiles"
              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            >
              Required Files{" "}
              <span className="text-muted font-normal">(Optional)</span>:
            </label>
            <Select
              options={formattedFileTemplates}
              isMulti
              onChange={handleRequiredFileChanges}
              value={formattedFileTemplates.filter((option) =>
                newTemplate.requiredFiles.includes(option.value)
              )}
            />
          </div>
          <div className="my-5 flex-col">
            <div className="text-sm mb-1">Due date from creation</div>
            <input
              className="w-full border p-2 rounded-md"
              type="number"
              min={"1"}
              max={"365"}
              value={newTemplate.daysFromCreationDueDate}
              onChange={handleDayInputChange}
              placeholder={"Days until completion..."}
            />
          </div>
          <div className="flex justify-end">
            <button
              onClick={(e) => handleSubmit}
              type="submit"
              className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Create Template
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTemplateInterimTask;
