// NotificationService.ts

import EventNotification from "../../interfaces/eventNotification/EventNotification";

// Function to fetch all notifications of an employee
export async function getNotifications(
  authToken: string,
  page: number = 1,
  pageSize: number = 10
): Promise<EventNotification[] | null> {
  try {
    let apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/notifications?page=${page}&pageSize=${pageSize}`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response);
      return null;
    }

    const notifications: EventNotification[] = await response.json();

    return notifications;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    return null;
  }
}


export async function getLatestNotifications(
  authToken: string
): Promise<EventNotification[] | null> {
  try {
    // Retrieve the last poll date from localStorage
    const lastPollDate = localStorage.getItem('lastPollDate') || new Date(0).toISOString();
    
    // Create the API URL with the 'since' query parameter
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/notifications/since?since=${encodeURIComponent(lastPollDate)}`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response);
      return null;
    }

    const result = await response.json();

    // Assuming the API returns the server's date in the headers or body
    const serverDate = response.headers.get('Date') || new Date().toISOString();
    
    // Store the server's date in localStorage for the next poll
    localStorage.setItem('lastPollDate', serverDate);

    const notifications: EventNotification[] = result;
    return notifications;
  } catch (error) {
    console.error("Error fetching latest notifications:", error);
    return null;
  }
}

export async function markNotificationRead(
  authToken: string,
  messageId: string
): Promise<boolean> {
  try {
    let apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/notifications/${messageId}`;

    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return false;
    } else { 
        return true;
    }

  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching participants:", error);
    return false;
  }
}

export async function markAllNotificationsRead(
    authToken: string,
  ): Promise<boolean> {
    try {
      let apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/notifications/`;
  
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        // Handle non-OK responses, e.g., 404 for not found
        return false;
      } else { 
          return true;
      }
  
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error fetching participants:", error);
      return false;
    }
  }