import { faFolderPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';

interface ContextMenuProps {
  x: number;
  y: number;
  toggleCreateFolder: () => void;
  toggleUploadFile: () => void;
}

const CustomContextMenu: React.FC<ContextMenuProps> = ({ x, y, toggleCreateFolder, toggleUploadFile }) => {
  const style: React.CSSProperties = {
    top: `${y-100}px`,
    left: `${x}px`,
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: 1000,
    padding: '',
  };

  return (
    <div className='absolute p-0 border rounded-lg shadow-xl' style={style}>
      <div className='min-w-[200px] p-0 m-0 flex flex-col'>
        <div onClick={toggleCreateFolder} className='p-2 cursor-pointer flex gap-2 items-center align-middle justify-start hover:bg-text-dark'><FontAwesomeIcon className='text-gray-600' icon={faFolderPlus}/><div> Create Folder</div></div>
        <div onClick={toggleUploadFile} className='p-2 cursor-pointer flex gap-2 items-center align-middle justify-start hover:bg-text-dark'><FontAwesomeIcon className='text-gray-600' icon={faUpload}/><div> Upload File</div></div>
        {/* <div className='p-2 cursor-pointer flex gap-2 items-center align-middle justify-start hover:bg-text-dark'><FontAwesomeIcon className='text-gray-600' icon={faFolderPlus}/><div> Create Folder</div></div> */}
      </div>
    </div>
  );
};

export default CustomContextMenu;
