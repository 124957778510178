import {
  faBell,
  faBolt,
  faCog,
  faMessage,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import "./Profile.css";
import ProfileInformation from "./ProfileInformation";
import { User } from "../../../interfaces/User";
import Settings from "../settings/Settings";
import Inbox from "../inbox/Inbox";
import Reviews from "../reviews/Reviews";
import { useAuth } from "../../../firebase/AuthProvider";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";
import Avatar from "../../../interfaces/avatar/Avatar";

const Profile: React.FC = () => {
  const { currentUser, currentUserInfo } = useAuth();
  const [currentAvatar, setCurrentAvatar] = useState<Avatar | null>(null);


  useEffect(() => {
    if (currentUser) {
      const storedAvatars = getAvatarsFromStorage();
      if (storedAvatars) {
        const avatar = storedAvatars.find(avatar => avatar.id === currentUser.uid);
        if (avatar) {
          setCurrentAvatar(avatar);
        }
      }
    }
  }, [currentUser]);

  return (
    <div className="relative bg-[#F7F9FC] dark:bg-background-dark sm:min-w-[640px] overflow-x-auto max-w-full h-screen">
      <div className="bg-black w-full h-[300px] rounded-b-3xl"></div>
      <div className="overflow-x-auto -top-64 relative dark:bg-card-dark dark:text-text-dark bg-[#F7F9FF] p-5 rounded-3xl shadow-lg w-11/12 mx-auto min-h-[600px] -mt-10">
        <h1 className="ps-4 justify-start text-start text-2xl text-text inter-font p-5">
          Your Profile
        </h1>
        <div className="flex gap-10 mx-5">
          <div className="dark:bg-card-dark p-5 rounded-lg shadow-lg min-w-[300px] bg-[#F0F0F0] ">
            {/* <img
          className="rounded-full h-40 w-40 object-cover object-center m-auto"
          src="/sample_user.jpg"
          alt="profile_picture"
        /> */}
            <div className="font-bold text-7xl h-40 w-40 object-cover flex align-center object-center mx-auto justify-center bg-card rounded-full">
            {currentAvatar ? 
                  <img src={`data:image/png;base64,${currentAvatar.avatar}`} className="w-full rounded-full"></img>
              :
              <div className="flex justify-center text-center w-100 my-auto">
                {currentUserInfo?.firstName[0] +
                  "" +
                  currentUserInfo?.lastName[0]}
              </div>
              }
            </div>
            <div className="mt-10">
              <div className="">
                <h1 className="text-3xl important">
                  {currentUser?.displayName}
                </h1>
                <div className="text-muted inter-font text-center text-2xl">
                  {currentUserInfo?.firstName + " " + currentUserInfo?.lastName}
                </div>
              </div>
              <nav className="mt-10 w-full mx-auto">
                <ol className="text-md w-[300px] mb-10 inter-font rounded-3xl bg-white mx-auto">
                  <li className="">
                    <Link
                      to={"edit"}
                      className="text-muted hover:text-muted-dark dark:hover:text-muted rounded-lg link-hover w-full dark:hover:bg-card-dark flex items-center align-middle gap-3 p-5"
                    >
                      <FontAwesomeIcon
                        icon={faUserCircle}
                        className="icon-rotate pe-2 text-xl"
                      />{" "}
                      Profile Information
                    </Link>
                  </li>
                  <hr />
                  <li className="">
                    <Link
                      to={"inbox"}
                      className="text-muted hover:text-muted-dark dark:hover:text-secondary rounded-md link-hover w-full dark:hover:bg-card-dark flex items-center align-middle gap-3 p-5"
                    >
                      <FontAwesomeIcon
                        icon={faBell}
                        className="icon-rotate pe-2 text-xl"
                      />{" "}
                      Notifications
                    </Link>
                  </li>
                  <hr />
                  <li className="">
                    <Link
                      to={"reviews"}
                      className="text-muted hover:text-muted-dark dark:hover:text-secondary rounded-md link-hover w-full dark:hover:bg-card-dark flex items-center align-middle gap-3 p-5"
                    >
                      <FontAwesomeIcon
                        icon={faMessage}
                        className="icon-rotate pe-2 text-xl"
                      />{" "}
                      Reviews
                    </Link>
                  </li>
                  <hr />
                  <li className="">
                    <Link
                      to={"settings"}
                      className="text-muted hover:text-muted-dark dark:hover:text-secondary rounded-md link-hover w-full dark:hover:bg-card-dark flex items-center align-middle gap-3 p-5"
                    >
                      <FontAwesomeIcon
                        icon={faCog}
                        className="icon-rotate pe-2 text-xl"
                      />{" "}
                      Settings
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="bg-card dark:bg-card-dark pg-5 rounded-lg shadow-lg flex-1 overflow-x-auto">
            <Routes>
              <Route path={"/edit"} element={<ProfileInformation />} />
              <Route path={"/inbox"} element={<Inbox />} />
              <Route path={"/reviews"} element={<Reviews />} />
              <Route path={"/settings"} element={<Settings />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
