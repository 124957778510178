import {
    faFileAudio,
    faFileExcel,
    faFileImage,
    faFilePdf,
    faFileWord,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import React from "react";
  import { Link } from "react-router-dom";
  import Filetype from "../../../../interfaces/Filetype";
  
  interface FormCardProps {
    id: string;
    filetype: string;
    filename: string;
  }
  
  const FormCard: React.FC<FormCardProps> = ({
    filetype,
    filename,
    id,
  }) => {
    return (
      <div
        className="card bg-card dark:bg-card-dark text-text dark:text-text-dark cursor-pointer flex 
              hover:shadow-lg rounded-lg mx-1"
      >
        <div className="flex">
          <div className="flex p-2">
            {filetype === "PDF" && (
              <div className="mx-2 text-red-500 text-md">
                <FontAwesomeIcon icon={faFilePdf} />
              </div>
            )}
            {filetype === "image" && (
              <div className="mx-2 text-purple-700 text-md">
                <FontAwesomeIcon icon={faFileImage} />
              </div>
            )}
            {filetype === "audio" && (
              <div className="mx-2 text-cyan-800 text-md">
                <FontAwesomeIcon icon={faFileAudio} />
              </div>
            )}
            {filetype === "excel" && (
              <div className="mx-2 text-green-500 text-md">
                <FontAwesomeIcon icon={faFileExcel} />
              </div>
            )}
            {filetype === "word" && (
              <div className="mx-2 text-blue-500 text-md">
                <FontAwesomeIcon icon={faFileWord} />
              </div>
            )}
            <div className="me-2 h-full truncate max-w-24">{filename}</div>
          </div>
        </div>
      </div>
    );
  };
  
  export default FormCard;
  