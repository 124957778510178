// TaskAttachmentService.ts
import { RemoveTaskRequiresAttachmentRequest } from "../../interfaces/task/TaskFiles/RemoveTaskRequiredAttachmentRequest";
import TaskAttachment from "../../interfaces/task/TaskFiles/TaskAttachment";
import { TaskRequiredAttachmentRequest } from "../../interfaces/task/TaskFiles/TaskRequiredAttachmentRequest";
import { TaskRequiresAttachment } from "../../interfaces/task/TaskFiles/TaskRequiresAttachment";

// Function to fetch a RequiredFile by taskId
export async function getRequiredFileForTask(
  taskId: string,
  authToken: string
): Promise<TaskAttachment[] | null> {
  try {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/tasks/attachments/${taskId}`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const taskDataList = await response.json();

    // Map the task data list to an array of TaskAttachment objects
    const attachments: TaskAttachment[] = taskDataList.map((taskData: any) => ({
      id: taskData.id,
      attachmentName: taskData.attachmentName,
      location: taskData.location ? taskData.location : undefined,
      filetype: taskData.filetype,
      formId: taskData.formId ? taskData.formId : undefined,
      status: {
        name: taskData.status,
      },
      // Add other necessary properties
    }));

    return attachments;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}

export async function getAttachmentRequirementsByTaskId(
  taskId: string,
  authToken: string
): Promise<TaskRequiresAttachment[] | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/requiredAttachments/${taskId}`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const requiredFiles = await response.json();

    // Map the task data list to an array of TaskAttachment objects
    const files: TaskRequiresAttachment[] = requiredFiles.map((file: any) => ({
      id: file.id, 
      templateFile: {
        id: file.templateFile.id, 
        fileName: file.templateFile.fileName, 
        estimatedTtc: file.templateFile.estimatedTTC, // Correct the casing here
        type: { 
          id: file.templateFile.type.id, 
          type: file.templateFile.type.type
        } 
      },
      attachment: file.attachment, 
      // Add other necessary properties
    }));
    

    return files;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching required attachments:", error);
    return null;
  }
}

export async function addRequiredFileForTask(
  taskId: string,
  templateFileId: string,
  authToken: string
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/requiredAttachments`;

  // Converet NewTask type to TaskRequest type
  const requestBody: TaskRequiredAttachmentRequest = {
    taskId: taskId,
    templateFileId: templateFileId,
  };

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.log(response);
      return null;
    }

    const taskId = await response.text();

    return parseInt(taskId, 10);
  } catch (error) {
    console.log("Error posting comment", error);
    return null;
  }
}

export async function removeRequiredFileForTask(
  requiredFileId: string,
  authToken: string
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/requiredAttachments/${requiredFileId}`;

  try {
    const response = await fetch(apiUrl, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response);
      return null;
    }

    const removedRequirementId = await response.text();

    return parseInt(removedRequirementId, 10);
  } catch (error) {
    console.log("Error posting comment", error);
    return null;
  }
}

