import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navigation/Navbar/Navbar";
import Home from "./components/components/home/Home";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./components/components/authenticationComponents/Login";
import "./App.css";
import { AuthProvider, useAuth } from "./firebase/AuthProvider";
import LandingPage from "./components/components/landing/LandingPage";

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    // Check for saved dark mode preference and initialize state accordingly
    const savedMode = localStorage.getItem("darkMode");
    return savedMode === "true"; // returns true if savedMode is 'true', otherwise false
  });

  useEffect(() => {
    // Check for saved dark mode preference
    const isDarkMode = localStorage.getItem("darkMode") === "true";
    setDarkMode(false);
  }, []);

  useEffect(() => {
    // Apply or remove the 'dark' class on the body
    document.body.classList.toggle("dark", darkMode);

    // Save preference
    localStorage.setItem("darkMode", darkMode.toString());
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className="App bg-background text-text dark:bg-background-dark dark:text-text min-h-screen relative">
      <Router>
        <AuthProvider>
              <Navbar toggleDarkMode={toggleDarkMode} isDarkMode={darkMode} />

          <div className="relative flex w-full">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/landing-page" element={<Login />} />
              <Route path="/" element={<Login />} />
              <Route element={<ProtectedRoute />}>
                {/* <Route
                  path="/view/:id"
                  element={<PDFViewer file={"/SCD_Blank.pdf"} />}
                /> */}
                <Route path="/*" element={<Home />} />
                <Route path="/logout" element={<Login />} />
              </Route>
              {/* <Route path="/drive" element={<Drive />} /> */}
              {/* other routes can go here */}
            </Routes>
          </div>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
