import React, { useEffect, useState } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import { useNavigate } from "react-router-dom";
import Banner from "../../ui/Banner/Banner";
import "./Login.css"; // Import the CSS file

// username: it@blossomsca.com
// password: password
const Login: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { login, logout } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    logout();
  }, []);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await login(email, password);
      navigate("/home");
      // If login is successful, you can redirect to another page or update the UI
    } catch (error) {
      setError("Failed to login");
      console.error(error);
    }
  };

  const dismissBanner = () => {
    setError("");
  };

  const togglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="min-h-[calc(100vh)] w-full absolute max-w-full h-screen">
      <div className="mx-auto flex justify-center flex-wrap my-auto h-full gap-10 gap-y-0">
        <div className="w-1/2 absolute left-0 h-screen text-start my-auto min-w-[300px] login-image">
          <div className="h-screen flex flex-col justify-center align-middle items-center">
            <h1 className="inter-font text-text text-8xl"></h1>
            <img src="/login.png"></img>
          </div>
        </div>
        <div className="bg-white min-w-[400px] p-10 h-screen my-auto absolute w-1/2 right-0 flex flex-col justify-between">
          <div className="my-5">
            {error !== "" && (
              <Banner
                message={error}
                dismissBanner={dismissBanner}
                toggleBanner={dismissBanner}
                status="error"
              />
            )}
          </div>
          <div className="h-4/5 my-auto w-4/5 mx-auto flex flex-col gap-10">
            <h1 className="text-text inter-font-600 text-8xl text-center">
              Log In
            </h1>
            <div className="inter-font text-muted text-xl text-center">
              Welcome back! Please sign in.
            </div>
            <form onSubmit={handleLogin}>
              <div className="flex flex-col gap-10 my-5 dark:text-text-dark">
                <div className="flex flex-col inter-font text-lg gap-2">
                  <label>Email</label>
                  <input
                    className="input rounded-xl border-gray-300 leading-10 text-xl"
                    placeholder="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="flex flex-col inter-font text-lg gap-2">
                  <label>Password</label>
                  <input
                    className="input rounded-xl border-gray-300 leading-10 text-xl"
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div
                  onClick={togglePassword}
                  className="flex gap-2 ps-1 items-center"
                >
                  <input
                    className="text-[#3dd598] focus:ring-[#3dd598]"
                    type="checkbox"
                  />
                  <div>Show Password</div>
                </div>
              </div>
              <div className="w-full">
                <button
                  type="submit"
                  onClick={handleLogin}
                  className="bg-[#3dd598] w-full rounded-xl text-white inter-font font-bold py-4 px-4 focus:outline-none focus:shadow-outline text-xl"
                >
                  Log In
                </button>
                <div className="text-[#3dd598] mt-4 -mb-4">Forgot Password?</div>
              </div>
            </form>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
