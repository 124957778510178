// src/components/FolderItem.tsx
import React from "react";
import { UserFolder } from "../../../../../interfaces/userDrive/userFolder";

interface FolderItemProps {
  folder: UserFolder;
  onNavigate: (folderId: number) => void;
}

export const FolderItem: React.FC<FolderItemProps> = ({
  folder,
  onNavigate,
}) => {

  return (
    <div
      className="folder-item w-32 h-32 text-4xl overflow-hidden bg-white rounded-xl border shadow-lg justify-center align-center items-center flex-wrap cursor-pointer hover:shadow-none"
      onClick={() => onNavigate(folder.folderID)}
    >
      <div className="text-6xl mb-2 mx-auto text-center mt-3">📁</div>
      <div className="font-bold text-sm px-2 max-w-full text-center mt-5 text-ellipsis trunacte text-nowrap">{folder.name}</div>
      {/* <div className="text-gray-500">{folder.fileCount} Files</div> */}
    </div>
  );
};
