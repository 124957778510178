import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useParams } from 'react-router-dom';
import './PDFViewer.css'; // Ensure to import a CSS file for additional styles
import { useAuth } from '../../../../firebase/AuthProvider';
import { getUserFileById } from '../../../../services/fileServices/UserDriverService';

// Set worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const UserFilePDFViewer: React.FC<{ fileUrl: string; scale: number }> = ({ fileUrl, scale }) => {
  const { currentUser } = useAuth();
  const { fileId } = useParams(); // Using useParams to get fileId from the URL
  const [numPages, setNumPages] = useState<number | null>(null);
  const [file, setFile] = useState<string | null>();

  useEffect(() => {
    const fetchPDF = async () => {
      if (currentUser && fileId) {
        try {
          const authToken: string = await currentUser.getIdToken();
          const file = await getUserFileById(Number(fileId), authToken);
          setFile(file); // Creates a local URL for the fetched blob
        } catch (error) {
          console.error('Error fetching PDF:', error);
        }
      }
    };

    fetchPDF();
  }, [fileId]); // Effect runs when fileId changes

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <div className='pdfViewer'>
      {file && (
        <Document className='bg-secondary dark:bg-secondary-dark' file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              className='bg-secondary'
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderTextLayer={false}
              scale={scale} // Set the scale to control zoom level
            />
          ))}
        </Document>
      )}
    </div>
  );
};

export default UserFilePDFViewer;
