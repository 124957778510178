import React, { useEffect, useState } from "react";
import { User } from "../../../interfaces/User";
import { useAuth } from "../../../firebase/AuthProvider";
import ProfilePictureUpload from "./ProfilePictureUpload";
import Avatar from "../../../interfaces/avatar/Avatar";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";

const ProfileInformation: React.FC = () => {
  const { currentUser, currentUserInfo } = useAuth();
  const [currentAvatar, setCurrentAvatar] = useState<Avatar | null>(null);

  useEffect(() => {}, [currentUserInfo]);

  useEffect(() => {
    if (currentUser) {
      const storedAvatars = getAvatarsFromStorage();
      if (storedAvatars) {
        const avatar = storedAvatars.find(
          (avatar) => avatar.id === currentUser.uid
        );
        if (avatar) {
          setCurrentAvatar(avatar);
        }
      }
    }
  }, [currentUser]);

  return (
    <div className="p-5">
      <h1 className="inter-font text-text text-2xl">Profile Information</h1>
      {currentUserInfo ? (
        <div className="flex flex-row flex-wrap mt-5">
          <div className="flex justify-between flex-col text-sm w-1/2">
            <p className="text-muted">First Name:</p>{" "}
            <p className="bg-background m-2 dark:bg-background-dark p-2 rounded-lg">
              {currentUserInfo?.firstName}
            </p>
          </div>
          <div className="flex justify-between flex-col text-sm w-1/2">
            <p className="text-muted">Last Name:</p>{" "}
            <p className="bg-background m-2 dark:bg-background-dark p-2 rounded-lg">
              {currentUserInfo?.lastName}
            </p>
          </div>
          <div className="flex justify-between flex-col text-sm w-1/2">
            <p className="text-muted">Display Name:</p>{" "}
            <p className="bg-background m-2 dark:bg-background-dark p-2 rounded-lg">
              {currentUserInfo?.displayName}
            </p>
          </div>
          <div className="flex justify-between flex-col text-sm w-1/2">
            <p className="text-muted">Employee ID:</p>{" "}
            <p className="bg-background m-2 dark:bg-background-dark p-2 rounded-lg">
              {currentUserInfo?.scid}
            </p>
          </div>
        </div>
      ) : (
        <div className="container mx-auto">
          <div className="text-muted text-sm mt-5 w-full text-center">
            There is currently no profile information available.
          </div>
        </div>
      )}
      {!currentAvatar && <ProfilePictureUpload onUploadSuccess={() => {}} />}
    </div>
  );
};

export default ProfileInformation;
