// TemplateTaskService.ts

import NewTemplateTaskAction from "../../interfaces/templateTask/NewTemplateTaskAction";
import NewTemplateTaskActionRequest from "../../interfaces/templateTask/NewTemplateTaskActionRequest";
import TemplateTaskAction from "../../interfaces/templateTask/TemplateTaskAction";

export async function publishTemplateTaskAction(
  taskRequest: NewTemplateTaskAction,
  templateTaskId: number,
  authToken: string
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/template/task/action`;

  console.log("GOT HERE");
  // Converet NewTask type to TaskRequest type
  const requestBody: NewTemplateTaskActionRequest = {
    templateTaskId: templateTaskId,
    description: taskRequest.description,
    actionTypeId: Number(taskRequest.actionTypeId),
    actionSubTypeId: Number(taskRequest.actionSubTypeId),
    estimatedTtc: Number(taskRequest.estimated_ttc),
  };

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.error("Error Publishing task action")
    }

    const taskId = await response.text();
    console.log(taskId);

    return parseInt(taskId, 10);
  } catch (error) {
    console.error("Error posting comment", error);
    return null;
  }
}

export async function getTemplateTaskActions(
  templateTaskId: number,
  authToken: string
): Promise<TemplateTaskAction[] | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/template/task/${templateTaskId}/actions`;

  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
    }

    const templateActions = await response.json();

    const templateTaskActions: TemplateTaskAction[] = templateActions.map((action: any) => ({
        id: action.id, 
        templateTaskId: action.templateTaskId, 
        description: action.description, 
        actionType: {
            id: action.actionType.id, 
            type: action.actionType.name
        }, 
        actionSubType: { 
            id: action.actionSubType.id, 
            type: action.actionSubType.name
        }, 
        estimated_ttc: action.estimatedTtc
    }));

    return templateTaskActions;
  } catch (error) {
    console.log("Error posting comment", error);
    return null;
  }
}

export async function deleteTemplateTaskAction(
actionId: number, 
authToken: string
): Promise<boolean> { 
const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/template/task/action/${actionId}`;

try {
  const response = await fetch(apiUrl, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    console.log(response);
    return false;
  }

  return response.ok
} catch (error) {
  console.log("Error posting comment", error);
  return false;
}
}