import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../firebase/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import { TaskRequiresAttachment } from "../../../../interfaces/task/TaskFiles/TaskRequiresAttachment";
import {
  addRequiredFileForTask,
  getAttachmentRequirementsByTaskId,
  removeRequiredFileForTask,
} from "../../../../services/taskServices/TaskAttachmentRequirementsService";
import TemplateFile from "../../../../interfaces/task/TaskFiles/TemplateFile";
import { getTemplateFiles } from "../../../../services/taskServices/TaskLKService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

interface OptionType {
  value: string;
  label: string;
}

const NewFileRequirementForm: React.FC = () => {
  const { currentUser } = useAuth();
  const { taskId } = useParams();
  const navigate = useNavigate();

  const [taskIdNum, setTaskIdNum] = useState<string>();
  const [requirements, setRequirements] = useState<
    TaskRequiresAttachment[] | null
  >([]);
  const [fileTemplates, setFileTemplates] = useState<TemplateFile[] | null>([]);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [selectedFile, setSelectedFile] = useState<OptionType | null>(null);
  const [reload, setReload] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>();

  useEffect(() => {
    const taskIdNum = taskId?.split("-")[1];
    setTaskIdNum(taskIdNum);

    if (currentUser !== null && taskIdNum !== undefined) {
      const fetchTaskAttachmentRequirements = async () => {
        const authToken: string = await currentUser.getIdToken();
        const fetchedAttachmentRequirements =
          await getAttachmentRequirementsByTaskId(taskIdNum, authToken);
        setRequirements(fetchedAttachmentRequirements);
        console.log(requirements);
      };
      const fetchTemplateFiles = async () => {
        const authToken: string = await currentUser.getIdToken();
        const fetchedTemplateFiles = await getTemplateFiles(authToken);
        setFileTemplates(fetchedTemplateFiles);
        console.log(fetchedTemplateFiles);
      };

      fetchTaskAttachmentRequirements();
      fetchTemplateFiles();
    }
  }, [currentUser, taskId, reload]);

  useEffect(() => {
    if (fileTemplates) {
      const formattedTemplateOptions = fileTemplates.map((file) => ({
        value: file.id.toString(),
        label: file.fileName,
      }));

      console.log("GOT HERE");
      setOptions(formattedTemplateOptions);
    }
  }, [fileTemplates, requirements]);

  const handleAddFile = (selectedOption: SingleValue<OptionType>) => {
    const file = selectedOption;
    setSelectedFile(file);
  };

  const addRequiredFile = () => {
    console.log(
      `Adding Required File ${selectedFile?.label} From Task ${taskId}`
    );
    const addFile = async () => {
      if (currentUser !== null && selectedFile !== null && taskIdNum) {
        const authToken: string = await currentUser.getIdToken();
        const newTaskId: number | null = await addRequiredFileForTask(
          taskIdNum,
          selectedFile.value,
          authToken
        );
        return newTaskId;
      }
      return null;
    };
    const idPromise = addFile();

    idPromise.then((id) => {
      if (id !== null) {
        // Redirect to new task here.
        console.log("New Required Attachment ID:", id);
        setSelectedFile(null);
        setReload(!reload);
      } else {
        console.error("Failed to create task");
      }
    });
  };

  const removeRequirement = (id: number) => {
    console.log(`Attempting to remove file requirement with id ${id}`);
    const removeFile = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const newTaskId: number | null = await removeRequiredFileForTask(
          id.toString(),
          authToken
        );
        return newTaskId;
      }
      return null;
    };
    const idPromise = removeFile();

    idPromise.then((id) => {
      if (id !== null) {
        // Redirect to new task here.
        console.log("Removed Required Attachment ID:", id);
        setSelectedFile(null);
        setReload(!reload);
      } else {
        console.error("Failed to remove requiredAttachment");
      }
    });
  };

  const navigateToTask = () => {
    let currentPath = window.location.pathname;
    let newPath = currentPath.substring(0, currentPath.lastIndexOf("/"));
    navigate(newPath);
  };

  return (
    <div className="my-2 bg-background dark:bg-background-dark dark:text-text-dark p-5 rounded-lg">
      <button onClick={navigateToTask} className="text-sm bg-card dark:bg-card-dark p-2 rounded-md shadow-sm mb-4 text-text dark:text-text-dark">
        <FontAwesomeIcon icon={faChevronLeft} /> Back
      </button>
      <h2>Add/Edit Required Files To {taskId}</h2>
      {successMessage && <div></div>}
      <div className="mx-auto w-3/4 bg-card dark:bg-card-dark p-5 my-10 rounded-lg shadow-lg">
        <div className="mb-10">
          <h3>Required Files: </h3>
          <div className="flex mt-2">
            {requirements &&
              requirements?.map((file, index) => (
                <div className="dark-badge p-0" key={index}>
                  <div className="p-0 flex align-center justify-center ">
                    <div>{file.templateFile.fileName}</div>
                    <div
                      className="text-red-700 cursor-pointer ms-1 font-bold"
                      onClick={() => removeRequirement(file.id)}
                    >
                      x
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="mt-10">
          <h3>Select Files To Add: </h3>
          <div className="my-4 mx-auto flex justify-between">
            <Select
              id="file"
              name="file"
              value={selectedFile}
              placeholder="Select..."
              onChange={handleAddFile}
              options={options}
              className="w-3/4"
              classNamePrefix="select"
            />
            <button
              className="bg-primary px-2 text-text-dark rounded-md"
              onClick={addRequiredFile}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFileRequirementForm;
