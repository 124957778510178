// TaskCreationLKValues.ts
import TaskCreationLKValues from "../../interfaces/lkValues/TaskCreationLKValues";
import TemplateFile from "../../interfaces/task/TaskFiles/TemplateFile";


// Function to fetch a task by its ID
export async function getTaskLKValues(
  authToken: string
): Promise<TaskCreationLKValues | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/lkValues/taskCreation`;

  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    // All employees assigned to logged in user
    // Participant list for current team
    // Task Organization
    // Task Type
    // Task Subtype

    const responseData = await response.json();

    // Map the task data list to an array of TaskComment objects
    const LKVals: TaskCreationLKValues = { 
        users: responseData['assigneeOptions'].map((user: any) => ({
            id: user.id, 
            scid: user.scid,
            firstName: user.firstName, 
            lastName: user.lastName,
            displayName: user.displayName, 
            joinDate: user.joinDate, 
            organization: user.Organization, 
            email: user.email,
            avatar: undefined // to be implemented in the future  
        })), 
        activityTypes: responseData['activityTypeOptions'].map((activityType: any) => ({ 
            id: activityType.id, 
            type: activityType.name
        })), 
        taskTypes: responseData['taskTypeOptions'].map((taskType: any) => ({ 
            id: taskType.id, 
            type: taskType.name
        })), 
        taskSubTypes: responseData['taskSubTypeOptions'].map((taskSubType: any) => ({ 
            id: taskSubType.id, 
            type: taskSubType.name
        })), 
        taskOrganizations: responseData['taskOrganizationOptions'].map((taskOrganization: any) => ({ 
            id: taskOrganization.id, 
            type: taskOrganization.name
        })),
        waivers: responseData['waiverTypeOptions'].map((waiver: any) => ({ 
          id: waiver.id, 
          waiverName: waiver.waiverName
      })),
      statuses: responseData['statuses'].map((status: any) => ({ 
        name: status.name
    }))
    };

    return LKVals;

  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching Task Comments:", error);
    return null;
  }
}

// Function to fetch a TemplateFile by taskId
export async function getTemplateFiles(
  authToken: string
): Promise<TemplateFile[] | null> {
  try {
    const apiUrl = (`${process.env.REACT_APP_API_BASE_URL}/file/templates`);

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const fileList = await response.json();

    // Map the task data list to an array of TaskAttachment objects
    const attachments: TemplateFile[] = fileList.map((file: any) => ({
      id: file.id,
      fileName: file.fileName,
      formId: file.formId,
      typeId: file.typeId
      // Add other necessary properties
    }));

    return attachments;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}
