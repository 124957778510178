// src/components/TextEditor.tsx

import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles for the Quill editor
import "./TextEditor.css";

interface TextEditorProps {
  text: string;
  setText: (value: string) => void;
  displayToolbar?: boolean;
}

const TextEditor: React.FC<TextEditorProps> = ({
  text,
  setText,
  displayToolbar = true,
}) => {
  const modules = displayToolbar
    ? {
        toolbar: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          //   ['blockquote', 'code-block'],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          //   [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          //   [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

          [{ size: ["small", false, "large" /*'huge']*/] }], // custom dropdown
          //   [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          //   ['clean']                                         // remove formatting button
        ],
      }
    : {toolbar: false};

  return (
    <div className="overflow-x-auto bg-background max-w-[1000px] m-auto">
      <ReactQuill
        theme="snow"
        value={text}
        onChange={setText}
        readOnly={!displayToolbar}
        modules={modules}
      />
    </div>
  );
};

export default TextEditor;
