import React, { useEffect, useRef } from "react";
import { Chart, ChartData } from "chart.js/auto";

interface LineChartProps {
  data: ChartData;
}

const LineChart: React.FC<LineChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRef = useRef<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        if (chartInstanceRef.current) {
          chartInstanceRef.current.data = data;
          chartInstanceRef.current.update();
        } else {

          chartInstanceRef.current = new Chart(ctx, {
            type: "line",
            data: data,
          });
        }
      }
    }
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default LineChart;
