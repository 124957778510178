import React, { useState } from "react";
import { Event } from "../../../interfaces/Event";
import Button from "../../ui/Buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

interface SidebarProps {
  show: boolean;
  onClose: () => void;
  eventDetails: Event | undefined;
}

const Sidebar: React.FC<SidebarProps> = ({ show, onClose, eventDetails }) => {
  return (
    <div
      className={`${
        show ? "block" : "hidden"
      } fixed right-0 top-0 w-96 h-full shadow-lg z-50 text-textPrimary dark:text-textPrimary-dark bg-tertiary dark:bg-tertiary-dark`}
    >
      <span onClick={onClose}>
        <Button icon={faChevronLeft} buttonText={"Back"} isActive={true}></Button>
      </span>
      {/* Add your content or form here */}
      {eventDetails ? (
        <div>
          {/* Display event details */}
          {eventDetails.id}
        </div>
      ) : (
        <form>{/* Event creation form */}</form>
      )}
    </div>
  );
};

export default Sidebar;
