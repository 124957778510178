// TaskCommentService.ts
import TaskAction from "../../interfaces/task/taskActions/TaskAction";
import TaskComment from "../../interfaces/task/taskActions/TaskComment";


function createTaskCommentPostData(comment: TaskComment) { 
  const createDateFormatted = comment.timestamp.toISOString().replace('Z', '');
  const updateDateFormatted = comment.timestamp.toISOString().replace('Z', '');

  return { 
    Id: comment.id, 
    Comment: comment.comment, 
    Author: "o5zSgfaPL0YSjXnBxVyG137L3hj2", 
    TaskId: comment.taskId,
    CreateDate: createDateFormatted, 
    UpdateDate: updateDateFormatted,
    CommentType: comment.commentType.id
  }
}

// Function to fetch a task by its ID
export async function getCommentsByTaskId(
  taskId: string,
  authToken: string
): Promise<TaskComment[] | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/${taskId}/comments`;

  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const taskDataList = await response.json();

    // Map the task data list to an array of TaskComment objects
    const attachments: TaskComment[] = taskDataList.map((taskData: any) => ({
      id: taskData.id,
      comment: taskData.comment,
      author: {
        id: taskData.authorId,
        first_name: taskData.author.firstName,
        lastName: taskData.author.lastName,
        email: taskData.author.email,
        displayName: taskData.author.displayName,
        avatar: taskData.authorAvatar,
      },
      timestamp: taskData.createDate,
      taskId: taskData.taskId,
      commentType: taskData.commentType,
      // Add other necessary properties
    }));

    return attachments;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching Task Comments:", error);
    return null;
  }
}

export async function createCommentOnTaskId(
  taskId: string,
  comment: TaskComment,
  authToken: string
): Promise<string | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/comment`;

  const postComment = createTaskCommentPostData(comment);

  try { 
    const response = await fetch(apiUrl, {
      method: 'POST', 
      headers: { 
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }, 
      body: JSON.stringify(postComment)
    })

    if (!response.ok) { 
      console.log(response)
      return null;
    }

    return "";
  } catch (error) { 
    console.log("Error posting comment", error)
    return null
  }
};

export async function getChatsForTask(
  taskId: string,
  authToken: string
):  Promise<TaskAction[] | null> { 
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/taskActions/comments/task/${taskId}`;

  try { 
    const response = await fetch(apiUrl, {
      headers: { 
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })

    if (!response.ok) { 
      console.log(response)
      return null;
    }

    const chatList: TaskAction[] = await response.json();
    return chatList
  } catch (error) { 
    console.log("Error posting comment", error)
    return null
  }

}