import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../navigation/Breadcrumb/Breadcrumb";
import FileList from "./FileList";
import { useAuth } from "../../../../firebase/AuthProvider";
import { getDriveFilesByUser } from "../../../../services/fileServices/FileService";
import FolderList from "./FolderList";
import { getTeamBySupervisor } from "../../../../services/userServices/TeamService";
import { TeamUser } from "../../../../interfaces/team/TeamUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FolderNavigation } from "./userDrive/FolderNavigation";
import CustomContextMenu from "./userDrive/RightClickComponent";

const Drive: React.FC = () => {
  const { currentUser, currentRoles } = useAuth();
  const { participant, year } = useDriveParams();
  const navigate = useNavigate();

  const [currentFiles, setCurrentFiles] = useState<any | null>(null); // Use any for currentFiles
  const [fileCounts, setFileCounts] = useState<{ [key: string]: number }>({});
  const [employees, setEmployees] = useState<TeamUser[]>([]);
  const [userDriveToggle, setUserDriveToggle] = useState<boolean>(false);
  const [createFolderToggle, setCreateFolderToggle] = useState<boolean>(false); 
  const [uploadFileToggle, setUploadFileToggle] = useState<boolean>(false);

  const hasHigherRoleThanSC = currentRoles?.some((role) => role.name !== "SC");
  const [contextMenu, setContextMenu] = useState<{
    x: number;
    y: number;
  } | null>(null);

  // Handle right-click to show the custom menu
  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
    });
  };

  // Hide the menu when clicking elsewhere
  const handleClick = () => {
    setContextMenu(null);
  };

  useEffect(() => {
    // Add a global click handler to close the context menu when clicking anywhere
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (currentUser !== null) {
      const fetchDriveFiles = async () => {
        const authToken: string = await currentUser.getIdToken();
        const driveFiles = await getDriveFilesByUser(authToken);
        setCurrentFiles(driveFiles);
        if (!participant) {
          const counts = transformData(driveFiles);
          setFileCounts(counts);
        } else if (participant && !year && driveFiles) {
          const counts = transformData(driveFiles[participant]);
          setFileCounts(counts);
        }
      };
      fetchDriveFiles();
    }
  }, [currentUser, participant, year]);

  useEffect(() => {
    if (currentUser !== null && hasHigherRoleThanSC) {
      const fetchEmployees = async () => {
        const authToken: string = await currentUser.getIdToken();
        const employees = await getTeamBySupervisor(authToken);
        if (employees) setEmployees(employees);
      };
      fetchEmployees();
    }
  }, [currentUser, hasHigherRoleThanSC]);

  useEffect(() => {
    if (currentFiles) {
      if (participant && !year) {
        const counts = transformData(currentFiles[participant]);
        setFileCounts(counts);
      } else if (participant && year) {
        const counts = transformData(currentFiles[participant][year]);
        setFileCounts(counts);
      }
    }
  }, [currentFiles, participant, year]);

  function useDriveParams() {
    const params = useParams();
    return {
      participant: params.participant
        ? decodeURIComponent(params.participant)
        : undefined,
      year: params.year ? decodeURIComponent(params.year) : undefined,
    };
  }

  const routeOnClick = (to: string) => {
    if (participant) navigate(participant + "/" + to);
    else navigate(to);
  };

  const transformData = (data: any): { [key: string]: number } => {
    const counts: { [key: string]: number } = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (Array.isArray(data[key])) {
          counts[key] = data[key].length;
        } else {
          counts[key] = Object.keys(data[key]).reduce(
            (acc, k) => acc + data[key][k].length,
            0
          );
        }
      }
    }
    return counts;
  };

  const toggleDrive = () => {
    setUserDriveToggle(!userDriveToggle);
  };
  const toggleCreateFolder = () => { 
    setCreateFolderToggle(!createFolderToggle);
    setUploadFileToggle(false);
  }
  const toggleUploadFile = () => { 
    setUploadFileToggle(!uploadFileToggle)
    setCreateFolderToggle(false);
  }

  return (
    <div
      className="relative bg-[#F7F9FC] dark:bg-background-dark sm:min-w-[640px] overflow-x-auto max-w-full h-screen overflow-hidden"
      onContextMenu={handleContextMenu}
    >
      {contextMenu && userDriveToggle && <CustomContextMenu x={contextMenu.x} y={contextMenu.y} toggleCreateFolder={toggleCreateFolder} toggleUploadFile={toggleUploadFile}/>}
      
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="-top-64 relative dark:bg-card-dark dark:text-text-dark bg-[#F7F9FF] p-5 rounded-3xl shadow-lg w-11/12 mx-auto min-h-[600px] -mt-10 overflow-hidden">
        <div className="flex align-middle items-center">
          <h1 className="ps-4 justify-start text-start text-2xl text-text inter-font p-5">
            {/* @TODO: User's name here */}
            User Drive
          </h1>
          <div>
            <Breadcrumbs />
          </div>
        </div>
        <div
          className="cursor-pointer flex shadow-lg mb-5 justify-between rounded-lg border"
          onClick={toggleDrive}
        >
          <div className="relative w-full flex flex-row overlfow-hidden items-center justify-center bg-[#3dd598] rounded-lg">
            {/* Left Div */}
            <div
              className={`flex-1 bg-[#3dd598] h-full relative clip-left font-bold rounded-l-lg ${
                userDriveToggle ? "text-text" : "text-white"
              } text-center`}
            >
              Participant Drive
              {/* Optional: Color Overlay */}
              <div
                className={`absolute inset-0 bg-white transition-opacity duration-500 rounded-l-lg ${
                  userDriveToggle ? "opacity-100" : "opacity-0"
                }`}
              >
                Participant Drive
              </div>
            </div>

            {/* Right Div */}
            <div
              className={`flex-1 bg-[#3dd598] relative clip-right h-full rounded-r-lg ${
                userDriveToggle ? "text-white" : "text-text"
              } text-center font-bold`}
            >
              Personal Drive
              {/* Optional: Color Overlay */}
              <div
                className={`absolute inset-0 bg-white transition-opacity duration-500 rounded-r-lg ${
                  !userDriveToggle ? "opacity-100" : "opacity-0"
                }`}
              >
                Personal Drive
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-self-start w-full">
          <div
            className={`min-w-full p-5 relative flex flex-wrap transform transition duration-500 ease-in-out ${
              userDriveToggle
                ? "translate-x-0 opacity-100 " // Slide in and become visible
                : "translate-x-full opacity-0" // Slide out and become hidden
            } `}
          >
            <FolderNavigation createFolderToggle={createFolderToggle} toggleCreateFolder={toggleCreateFolder} uploadFileToggle={uploadFileToggle} toggleUploadFile={toggleUploadFile}/>
          </div>
          <div
            className={`flex p-5 relative flex-wrap align-top items-start min-w-full justify-start transform transition-transform duration-500 ease-in-out gap-0 ${
              !userDriveToggle
                ? "-translate-x-full opacity-100"
                : "opacity-0 transition delay-200"
            }`}
          >
            {!participant && employees && employees.length > 0 && (
              <div className="bg-gray-200 rounded-3xl w-full p-5">
                <div>
                  <div className="inter-font mt-3 mb-5 text-xl">
                    Employee Drives
                  </div>
                  <div className="flex flex-wrap gap-5">
                    {!participant &&
                      employees.map((employee, index) => (
                        <div
                          key={index}
                          className="bg-card w-40 rounded-xl p-3 shadow-lg cursor-pointer hover:shadow-none flex gap-2 justify-start align-middle items-center"
                          onClick={() =>
                            navigate(`/employeeDrive/${employee.id}`)
                          }
                        >
                          <div>
                            <FontAwesomeIcon icon={faUser} />
                          </div>
                          <div className="truncate">
                            {employee.firstName + " " + employee.lastName}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
            <Routes>
              <Route
                path=""
                element={
                  <FolderList
                    folders={
                      currentFiles !== null && currentFiles !== undefined
                        ? Object.keys(currentFiles)
                        : []
                    }
                    routeOnClick={routeOnClick}
                    fileCounts={fileCounts}
                  />
                }
              ></Route>
              <Route
                path="/:participant/:year/*"
                element={
                  <FileList
                    files={
                      currentFiles !== null &&
                      currentFiles !== undefined &&
                      participant &&
                      year &&
                      currentFiles[participant]?.[year]
                        ? currentFiles[participant][year]
                        : []
                    }
                    routeOnClick={routeOnClick}
                  />
                }
              />
              <Route
                path="/:participant/*"
                element={
                  <FolderList
                    folders={
                      currentFiles !== null && currentFiles && participant
                        ? Object.keys(currentFiles[participant])
                        : []
                    }
                    routeOnClick={routeOnClick}
                    fileCounts={fileCounts}
                  />
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drive;
