// TaskPage.tsx

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Task from "../../../interfaces/task/Task"; // Import your Task interface
import TaskComponent from "./TaskComponent";
import { deleteTaskById, getTaskById } from "../../../services/taskServices/TaskService";
import Status from "../../../interfaces/task/Status";
import { useAuth } from "../../../firebase/AuthProvider";
import TaskChatComponent from "./TaskChatComponent/TaskChatComponent";
import ConfirmDeleteModal from "../../ui/ConfirmationModals/ConfirmDeleteModal";

const TaskPage: React.FC = () => {
  const { currentRoles } = useAuth();
  const navigate = useNavigate();
  const { taskId } = useParams(); // Get the taskId from the route parameter
  const [task, setTask] = useState<Task | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statusOptions, setStatusOptions] = useState<Status[] | null>([
    {
      name: "READY",
    },
    {
      name: "ISSUES FOUND",
    },
    {
      name: "IN REVIEW",
    },
  ]);
  const [tasks, setTasks] = useState<Task[] | null>([]);

  const { currentUser } = useAuth();

  useEffect(() => {
    const taskIdNum = taskId?.split("-")[1];

    // Fetch the task with taskId and update the state
    if (taskIdNum !== undefined) {
      const fetchTask = async () => {
        if (currentUser !== null) {
          const authToken: string = await currentUser.getIdToken();
          const fetchedTask = await getTaskById(taskIdNum, authToken);
          setTask(fetchedTask);
        }
      };
      fetchTask();
    }
  }, [taskId, statusOptions]); // Fetch the task whenever taskId changes

  const confirmDelete = async () => {
    setIsLoading(true);
    try {
      if (currentUser) {
        const authToken: string = await currentUser.getIdToken();
        if (task) { 
          console.log('TASK ID', task.id)
          await deleteTaskById(authToken, task?.id);
          // Assuming delete is successful
          setIsLoading(false);
          setShowModal(false);
          navigate('/home');
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error deleting the file:", error);
    }
  };

  if (task === null) {
    // Handle loading state or not found task
    return <div>Loading...</div>;
  }

  return (
    <div className="relative bg-background dark:bg-background-dark sm:min-w-[640px]  max-w-full inter-font">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="relative justify-center gap-5 flex flex-wrap bg-[#F7F9FF] dark:bg-[#141416] -top-64 -mt-10 w-11/12 mx-auto rounded-3xl shadow-sm border pb-10">
        <div className="flex flex-row justify-between px-10 py-5 items-center align-middle w-full">
          <div className="text-2xl font-medium text-gray-900 inter-font text-start pb-0 ">
            Task
          </div>
          <div className="">
            {currentRoles?.some((role) => role.name === "PROGRAM MANAGER") && (
              <div className="bg-danger text-white rounded px-3 py-1 cursor-pointer" onClick={() => setShowModal(true)}>
                Delete
              </div>
            )}
            {showModal && (
              <ConfirmDeleteModal
                onConfirm={confirmDelete}
                onCancel={() => setShowModal(false)}
                isLoading={isLoading}
              >
                Are you sure you want to delete this task?
              </ConfirmDeleteModal>
            )}
          </div>
        </div>
        <div className="flex flex-wrap w-full">
          <div className="bg-card dark:bg-card-dark rounded-3xl p-2 min-w-[700px] max-w-[800px] w-7/12 justify-end mx-auto">
            <TaskComponent task={task} statusOptions={statusOptions} />
            {/* Add more task details as needed */}
          </div>
          <div className="justify-start rounded-3xl w-4/12 mx-auto max-w-[600px]">
            <TaskChatComponent taskId={task.id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskPage;
