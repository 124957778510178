import React, { useEffect, useState } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import { getTaskActionTemplate } from "../../../services/taskServices/TaskActionService";
import NewTaskActionType from "../../../interfaces/task/taskActions/NewTaskActionType";
import ActionSubType from "../../../interfaces/task/taskActions/ActionSubType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import EditTaskActivityType from "./EditTaskActivityType";

const CreateNewTaskActivityPage: React.FC = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [activityTemplates, setActivityTemplates] = useState<ActionSubType[]>();
  const [selectedTemplate, setSelectedTemplate] = useState<ActionSubType>();
  const [reload, setReload] = useState<boolean>(false);

  const labelsToFilterOut = [
    "COMMENT",
    "TASK FILE DELETED",
    "FILE UPLOAD",
    "TASK APPROVAL",
    "TASK SUBMISSION",
    "TASK COMPLETED",
    "REJECTED",
  ];

  useEffect(() => {
    const fetchActivityTemplates = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedTemplates = await getTaskActionTemplate(authToken);
        if (fetchedTemplates) {
          const filteredOptions = fetchedTemplates.filter(
            (option) => !labelsToFilterOut.includes(option.actionSubTypeName)
          );
          setActivityTemplates(filteredOptions);
        }
      }
    };
    fetchActivityTemplates();
  }, [reload]);

  const selectTemplate = (template: ActionSubType | undefined) => {
    setSelectedTemplate(template);
  };

  const toggleReload = () => {
    setReload(!reload);
  };

  const back = () => {
    setSelectedTemplate(undefined);
  };

  return (
    <div className="bg-[#F7F9FC]">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="min-h-[600px] mx-auto text-text dark:text-text-dark p-5 relative w-11/12 rounded-3xl -top-64 bg-white">
        <div className="w-full">
          <h1 className="heading-2 text-text inter-font text-center">Task Activity Options</h1>
          <div className="flex justify-end">
            <button
              onClick={() => navigate("/task/new/activity/template")}
              className="dark-button ms-auto"
            >
              New
            </button>
          </div>
        </div>
        {selectedTemplate ? (
          <div>
            <EditTaskActivityType
              taskActivity={selectedTemplate}
              reload={toggleReload}
              back={back}
            />
          </div>
        ) : (
          <div className="flex gap-5 flex-wrap">
            {activityTemplates &&
              activityTemplates.map((template, index) => (
                <div
                  className="card p-3 flex justify-between gap-2 hover:shadow-none"
                  key={index}
                >
                  <div>{template.actionSubTypeName}</div>
                  <div
                    onClick={() => selectTemplate(template)}
                    className="text-primary cursor-pointer"
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateNewTaskActivityPage;
