import React, { useEffect, useState } from "react";
import TaskCreation from "./TaskCreation";
import { useAuth } from "../../../../firebase/AuthProvider";
import { getInterimTemplates } from "../../../../services/taskServices/TaskService";
import InterimTemplateResponse from "../../../../interfaces/task/InterimTemplateResponse";
import Select, { SingleValue } from "react-select";
import TaskCreationFromTemplate from "./TaskCreationFromTemplate";

interface OptionType {
  value: string;
  label: string;
}

const TaskCreationPage: React.FC = () => {
  const { currentUser } = useAuth();

  const [templates, setTemplates] = useState<InterimTemplateResponse[]>([]);
  const [templateOptions, setTemplateOptions] = useState<OptionType[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string>();

  useEffect(() => {
    const fetchTemplates = async () => {
      if (currentUser) {
        const authToken = await currentUser.getIdToken();
        const response = await getInterimTemplates(authToken);
        if (response) {
          setTemplates(response);
        }
      }
    };

    fetchTemplates();
  }, []);

  useEffect(() => {
    const formattedTemplates = templates.map((template, index) => ({
      value: template.id.toString(),
      label: template.title,
    }));

    setTemplateOptions(formattedTemplates);
  }, [templates]);

  const handleTemplateChange = (template: SingleValue<OptionType>) => {
    if (template) {
      console.log(template.value);
      setSelectedTemplate(template.value);
    }
  };

  return (
    <div className="relative bg-background dark:bg-background-dark sm:min-w-[640px] overflow-x-auto max-w-full flex-grow flex-wrap h-screen">
      <div className="bg-black w-full h-[300px] rounded-b-3xl"></div>
      <div className="overflow-x-auto -top-64 relative dark:bg-card-dark dark:text-text-dark bg-[#F7F9FC] p-5 rounded-3xl shadow-lg w-11/12 mx-auto min-h-[600px] -mt-10 inter-font">
        <h1 className="inter-font text-text text-2xl mb-10 mt-5">Add Task</h1>
        {selectedTemplate && (
          <div className="flex flex-col">
            <div className="flex w-full">
              <div
                className="hover:bg-muted-dark cursor-pointer rounded-xl p-2"
                onClick={() => setSelectedTemplate(undefined)}
              >
                {"< Back"}
              </div>
            </div>
            <div className=" w-full min-w-[500px] mx-auto flex justify-center">
            <div className="bg-white rounded-xl max-w-[500px] min-w-[500px] ms-10 ">
              <TaskCreationFromTemplate
                template={templates.find(
                  (e) => e.id == parseInt(selectedTemplate)
                )}
              />
            </div>
            </div>
          </div>
        )}
        {!selectedTemplate && (
          <div className="flex justify-center gap-2 mb-10">
            <div className="min-w-[500px] bg-white rounded-xl">
              <TaskCreation />
            </div>
            <div className="min-w-[100px] text-center my-auto text-muted">
              &#8212; or &#8212;
            </div>
            <div className=" min-w-[500px] bg-white rounded-xl">
              <div className="max-w-[500px] mx-auto">
                <h1 className="inter-font text-text text-3xl pt-5 text-center">
                  Select From Template
                </h1>
                <div className="mt-8">
                  <div className="mb-4 p-10">
                    <label
                      htmlFor="requiredFiles"
                      className="block text-text dark:text-text-dark text-sm font-bold mb-2"
                    >
                      Template{" "}
                    </label>
                    <Select
                      options={templateOptions}
                      onChange={handleTemplateChange}
                      value={templateOptions.filter(
                        (option) => option.value === selectedTemplate
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskCreationPage;
