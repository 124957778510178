// Reports.tsx
import React from "react";
import LineChart from "../../ui/Charts/LineChart";
import DonutChart from "../../ui/Charts/DonutChart";
import FileUpload from "../fileUploadComponent/FileUpload";

const Reports: React.FC = () => {
  const renderChart = () => {
    const chartData = {
      labels: ["January", "February", "March", "April", "May"],
      datasets: [
        {
          label: "Participants Helped",
          data: [10, 20, 15, 25, 30],
          borderColor: "turquoise",
          fill: false,
        },
      ],
    };

    return (
      <div>
        <h1>Performance Report</h1>
        <LineChart data={chartData} />
      </div>
    );
  };

  const renderDonutChart = () => {
    const chartData = {
      labels: ["Red", "Blue", "Yellow"],
      datasets: [
        {
          label: "Example Data",
          data: [30, 40, 20],
          backgroundColor: ["turquoise", "#e5f8f5", "#85cfce"],
        },
      ],
    };

    return (
      <div>
        <h1>Donut Chart Example</h1>
        <DonutChart data={chartData} />
      </div>
    );
  };

  return (
    <div className="">
      <div className="mt-10 ">
        <h1 className="text-4xl text-center important text-primary">Reports</h1>
      </div>
      <div className="mt-10 flex gap-10 mr-16">
        <div className="dark:text-primary-dark bg-tertiary dark:bg-tertiary-dark p-4 rounded-lg shadow-lg">
          {renderChart()}
        </div>
        <div className="dark:text-primary-dark bg-tertiary dark:bg-tertiary-dark p-4 rounded-lg shadow-lg">
          {renderDonutChart()}
        </div>
      </div>
    </div>
  );
};

export default Reports;
