// src/components/FileItem.tsx
import React from 'react';
import { UserFile } from '../../../../../interfaces/userDrive/userFile';
import { useAuth } from '../../../../../firebase/AuthProvider';
import { getUserFileById } from '../../../../../services/fileServices/UserDriverService';
import { useNavigate } from 'react-router-dom';
import { formatDateToWords } from '../../../../../utilities/dateUtils';

interface FileItemProps {
  file: UserFile;
}

export const FileItem: React.FC<FileItemProps> = ({ file }) => {

    const {currentUser} = useAuth();
    const navigate = useNavigate();

  const downloadFile = async () => {
    navigate(`/view/user/file/${file.fileID}`)
  };

  return (
    <div className="file-item flex justify-between flex-nowrap gap-5 cursor-pointer hover:bg-gray-200 w-1/2" onClick={downloadFile}>
      <div className='flex gap-5'>
        <div>📄 </div>
        <div>{file.name}</div>
      </div>
      <div>{formatDateToWords(new Date(file.createdAt))}</div>
    </div>
  );
};
