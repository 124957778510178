import React from "react";
import Select from "react-select";
import {
  Option,
  Question,
} from "../../../interfaces/dynamicForms/FormInterfaces";

interface QuestionProps {
  question: Question;
  formState: Record<string, any>;
  handleInputChange: (questionId: string, value: any) => void;
  options?: Option[];
}

const QuestionComponent: React.FC<QuestionProps> = ({
  question,
  formState,
  handleInputChange,
  options,
}) => {
  const renderInput = () => {
    if (question.Conditional && !eval(question.Conditional)) {
      return null;
    }

    switch (question.Type) {
        case "Boolean":
            return (
              <div className="flex flex-col gap-2 justify-start remove-input-txt-border">
                <label>{question.Text}</label>
                <Select
                  options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" },
                  ]}
                  onChange={(selectedOption) =>
                    handleInputChange(question.Id, selectedOption?.value)
                  }
                  value={
                    formState[question.Id]
                      ? {
                          value: formState[question.Id],
                          label: formState[question.Id] === "Yes" ? "Yes" : "No",
                        }
                      : null
                  }
                  placeholder={question.Placeholder || "Select..."} // Add placeholder here
                  isClearable
                />
              </div>
            );
          
      case "Date":
        return (
          <div className="flex flex-col gap-2 justify-start ">
            <label>{question.Text}</label>
            <input
              className="rounded border-gray-300 p-1.5"
              type="date"
              onChange={(e) => handleInputChange(question.Id, e.target.value)}
              value={formState[question.Id] || ""}
              placeholder={question.Placeholder || ""} // Add placeholder here
            />
          </div>
        );

      case "Text":
        return (
          <div className="flex flex-col gap-2 justify-start ">
            <label>{question.Text}</label>
            <input
              className="rounded border-gray-300 p-1.5"
              type="text"
              onChange={(e) => handleInputChange(question.Id, e.target.value)}
              value={formState[question.Id] || ""}
              placeholder={question.Placeholder || ""} // Add placeholder here
            />
          </div>
        );

      case "SingleSelect":
        return (
          <div className="flex flex-col gap-2 justify-start remove-input-txt-border">
            <label>{question.Text}</label>
            <Select
              options={options
                ?.find((opt) => opt.QuestionId === question.Id)
                ?.Options.map((opt) => ({
                  value: opt.value,
                  label: opt.label,
                }))}
              onChange={(selectedOption) =>
                handleInputChange(question.Id, selectedOption?.value)
              }
              value={
                formState[question.Id]
                  ? {
                      value: formState[question.Id],
                      label: options
                        ?.find((opt) => opt.QuestionId === question.Id)
                        ?.Options.find(
                          (opt) => opt.value === formState[question.Id]
                        )?.label,
                    }
                  : null
              }
              placeholder={question.Placeholder || "Select..."} // Add placeholder here
              isClearable
            />
          </div>
        );

      case "MultiSelect":
        return (
          <div className="flex flex-col gap-2 justify-start remove-input-txt-border">
            <label>{question.Text}</label>
            <Select
              isMulti
              options={options
                ?.find((opt) => opt.QuestionId === question.Id)
                ?.Options.map((opt) => ({
                  value: opt.value,
                  label: opt.label,
                }))}
              onChange={(selectedOptions) =>
                handleInputChange(
                  question.Id,
                  selectedOptions?.map((opt) => opt.value)
                )
              }
              value={
                formState[question.Id]
                  ? options
                      ?.find((opt) => opt.QuestionId === question.Id)
                      ?.Options.filter((opt) =>
                        formState[question.Id].includes(opt.value)
                      )
                  : []
              }
              placeholder={question.Placeholder || "Select..."} // Add placeholder here
            />
          </div>
        );

      case "Time":
        return (
          <div className="flex flex-col gap-2 justify-start ">
            <label>{question.Text}</label>
            <input
              className="rounded border-gray-300 p-1.5"
              type="time"
              onChange={(e) => handleInputChange(question.Id, e.target.value)}
              value={formState[question.Id] || ""}
              placeholder={question.Placeholder || ""} // Add placeholder here
            />
          </div>
        );

      case "Number":
        return (
          <div className="flex flex-col gap-2 justify-start ">
            <label>{question.Text}</label>
            <input
              className="rounded border-gray-300 p-1.5"
              type="number"
              onChange={(e) => handleInputChange(question.Id, e.target.value)}
              value={formState[question.Id] || ""}
              placeholder={question.Placeholder || ""} // Add placeholder here
            />
          </div>
        );

      default:
        return null;
    }
  };

  return <div className="">{renderInput()}</div>;
};

export default QuestionComponent;
