import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import TemplateFile from "../../../interfaces/task/TaskFiles/TemplateFile";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../firebase/AuthProvider";
import { updateFileTemplate } from "../../../services/fileServices/FileService";

interface EditTemplateInterimTaskProps { 
    template: TemplateFile;
    setSelectedTemplate: (value: TemplateFile | undefined) => void;
}
interface OptionType {
  value: string;
  label: string;
}

const EditTemplateFile: React.FC<EditTemplateInterimTaskProps> = ({ template, setSelectedTemplate }) => { 
    const { currentUser, currentOrganization } = useAuth();
    const navigate = useNavigate();
    const [participantOptions, setParticipantOptions] = useState<OptionType[]>(
      []
    );
    const [durationInMinutes, setDurationInMinutes] = useState<string>("0");
    const [assigneeOptions, setAssigneeOptions] = useState<OptionType[]>([]);
    const [requiredFilesOptions, setRequiredFilesOptions] = useState<
      OptionType[]
    >([]);
  
    const [newTemplate, setNewTemplate] = useState<TemplateFile>({
      id: template.id,
      fileName: template.fileName, 
      type: template.type,
      formId: template.formId ?? undefined, 
      estimatedTtc: template.estimatedTtc
    });

    useEffect(() => {

    }, [template])

    const handleChange = (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >
    ) => {
      const { name, value } = e.target;
      setNewTemplate({ ...newTemplate, [name]: value });
    };
  
    // const handleRequiredFileChanges = (selectedOptions: OptionType[]) => {
    //   const requiredFiles = selectedOptions
    //     ? selectedOptions.map((option) => option.value)
    //     : [];
    //   setNewTemplate({ ...newTemplate, requiredFiles });
    // };
  
    // const handleInfoChange = (infoText: string) => {
    //   console.log('infoText', infoText)
    //   setNewTemplate({ ...newTemplate, information: infoText });
    // };
  
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      // Handle submitting the new task
      console.log("New Task:", newTemplate);
      const createTask = async () => {
        if (currentUser !== null) {
          const authToken: string = await currentUser.getIdToken();
          const newTaskId: TemplateFile | null = await updateFileTemplate(newTemplate, authToken);
          return newTaskId;
        }
        return null;
      };
      const idPromise = createTask();
  
      idPromise.then((id) => {
        if (id !== null) {
          // Redirect to new task here.
          console.log("New task ID:", id);
          setSelectedTemplate(undefined);
        } else {
          console.error("Failed to create task");
        }
      });
    };

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => { 
      setDurationInMinutes(e.target.value)
    }
    
    return (
      <div className="mt-5">
        <h1 className="heading-2 text-center">Edit Interim Template</h1>
        <form
          onSubmit={handleSubmit}
          className="max-w-xl mx-auto mt-8 bg-card dark:text-text dark:bg-card-dark shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            >
              Filename:
            </label>
            <input
              type="text"
              id="fileName"
              name="fileName"
              placeholder="Give your task a title..."
              value={newTemplate.fileName}
              onChange={handleChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
          <label
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            htmlFor="fileUpload"
          >
            Estimated Time To Completion (in minutes):
          </label>
          <input
            id="estimatedTtc"
            type="number"
            name="estimatedTtc"
            min={0}
            value={newTemplate.estimatedTtc || 0}
            onChange={handleChange}
            className="appearance-none border rounded w-full py-2 px-3 text-text dark:text-text-dark leading-tight focus:outline-none focus:shadow-outline file:mr-4 file:rounded-md file:border-0 file:bg-primary-500 file:py-2.5 file:px-4 file:text-sm file:font-semibold file:text-text"
          />
        </div>
          {/* <div className="mb-4">
            <label
              htmlFor="requiredFiles"
              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            >
              Required Files{" "}
              <span className="text-muted font-normal">(Optional)</span>:
            </label>
            <Select
              options={requiredFilesOptions}
              isMulti
              onChange={(e) => handleRequiredFileChanges}
              value={requiredFilesOptions.filter((option) =>
                newTemplate.requiredFiles.includes(option.value)
              )}
            />
          </div> */}
          <div className="flex justify-end">
            <button
              onClick={(e) => handleSubmit}
              type="submit"
              className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Save Template
            </button>
          </div>
        </form>
      </div>
    );
  };

export default EditTemplateFile;