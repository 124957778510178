// UserRolesService.ts
import UserInfo from "../../interfaces/UserInfo";

// Function to fetch a user's role by their user id
export async function getUserInfoById(authToken: string): Promise<UserInfo | null> {

  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/users/userInfo` 

  try {
    const response = await fetch(
      apiUrl, 
      {headers: { 
        'Authorization': `Bearer ${authToken}`
      }}
    );

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      console.error(`Server responded with status: ${response.status}`);
      return null;
    }

    const userData = await response.json();

    // Map the role data to Role interface
    const userInfo: UserInfo = {
      id: userData.id,
      scid: userData.scId, 
      firstName: userData.firstName, 
      lastName: userData.lastName, 
      displayName: userData.displayName, 
      joinDate: userData.joinDate, 
      organization: userData.organization, 
      email: userData.email
      // Add other necessary properties
    };

    return userInfo;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}

export async function getAllUsersInOrganization(
  authToken: string
): Promise<UserInfo[] | null> { 
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/users` 

  try {
    const response = await fetch(
      apiUrl, 
      {headers: { 
        'Authorization': `Bearer ${authToken}`
      }}
    );

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      console.error(`Server responded with status: ${response.status}`);
      return null;
    }

    const userData = await response.json();

    // Map the role data to Role interface
    const userInfo: UserInfo[] = userData.map((user: any) => ({
      id: user.id,
      scid: user.scid, 
      firstName: user.firstName, 
      lastName: user.lastName, 
      displayName: user.displayName, 
      joinDate: user.joinDate, 
      organization: user.organization, 
      email: user.email
      // Add other necessary properties
    }));

    return userInfo;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}

export async function getUserInfoByUserId(
  userId: string,
  authToken: string
): Promise<UserInfo | null> { 
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/users/${userId}` 

  try {
    const response = await fetch(
      apiUrl, 
      {headers: { 
        'Authorization': `Bearer ${authToken}`
      }}
    );

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      console.error(`Server responded with status: ${response.status}`);
      return null;
    }

    const user = await response.json();

    // Map the role data to Role interface
    const userInfo: UserInfo = {
      id: user.id,
      scid: user.scid, 
      firstName: user.firstName, 
      lastName: user.lastName, 
      displayName: user.displayName, 
      joinDate: user.joinDate, 
      organization: user.organization, 
      email: user.email
      // Add other necessary properties
    };

    return user;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}
