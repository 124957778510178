// src/components/FolderNavigation.tsx
import React, { useState, useEffect } from "react";
import { UserFolder } from "../../../../../interfaces/userDrive/userFolder";
import { UserFile } from "../../../../../interfaces/userDrive/userFile";
import { CreateFolder } from "./CreateFolder";
import { UploadFile } from "./UploadFile";
import { UserFolderResponse } from "../../../../../interfaces/userDrive/userFolderResponse";
import { getFolderContents } from "../../../../../services/fileServices/UserDriverService";
import { useAuth } from "../../../../../firebase/AuthProvider";
import { FileItem } from "./FileItem";
import { FolderItem } from "./FolderItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

interface FolderNavigationProps {
  initialFolderId?: number;
  createFolderToggle: boolean;
  uploadFileToggle: boolean;
  toggleCreateFolder: () => void;
  toggleUploadFile: () => void;
}

export const FolderNavigation: React.FC<FolderNavigationProps> = ({
  initialFolderId,
  createFolderToggle,
  uploadFileToggle,
  toggleCreateFolder,
  toggleUploadFile,
}) => {
  const { currentUser } = useAuth();
  const [currentFolderId, setCurrentFolderId] = useState<number | undefined>(
    initialFolderId
  );
  const [contents, setContents] = useState<UserFolderResponse | null>(null);
  const [path, setPath] = useState<UserFolder[]>([]); // For breadcrumb navigation

  useEffect(() => {
    const fetchContents = async () => {
      try {
        if (currentUser) {
          const authToken = await currentUser.getIdToken();
          const data = await getFolderContents(authToken, currentFolderId);
          setContents(data);
          // Update path for breadcrumbs
          if (currentFolderId) {
            const parentFolder = data; // Assuming data contains the current folder
            if (parentFolder) {
              setPath((prevPath) => [
                ...prevPath.filter((f) => f.folderID !== parentFolder.folderID),
                parentFolder,
              ]);
            }
          }
        } else {
          setPath([]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchContents();
  }, [currentFolderId]);

  const navigateToFolder = (folderId: number) => {
    setCurrentFolderId(folderId);
  };

  const goBack = () => {
    const newPath = [...path];
    newPath.pop();
    const parentFolder = newPath[newPath.length - 1];
    setCurrentFolderId(parentFolder ? parentFolder.folderID : undefined);
    setPath(newPath);
  };

  if (!contents) return <div>Loading...</div>;

  return (
    <div className="relative w-full h-full">
      {/* Conditionally render the custom context menu if it's visible */}
      {/* Breadcrumb Navigation */}
      <nav className="-mt-5 mb-2">
        <ul className="breadcrumb flex gap-2 text-blue-500 cursor-pointer">
          <li onClick={() => navigateToFolder(0)}>Root</li>{" "}
          <span className="text-text">/</span>
          {path.map((folder) => (
            <li
              key={folder.folderID}
              onClick={() => navigateToFolder(folder.folderID)}
            >
              {folder.name} <span className="text-text">/</span>
            </li>
          ))}
        </ul>
      </nav>
      {createFolderToggle && (
        <div className="absolute bg-gray-100 right-0 w-[400px] h-[200px] rounded-lg shadow-lg p-5">
          <CreateFolder
            currentFolderId={currentFolderId}
            onFolderCreated={setContents}
            closeFolder={toggleCreateFolder}
          />
        </div>
      )}
      {uploadFileToggle && (
        <div className="absolute bg-gray-100 right-0 w-[400px] h-[200px] rounded-lg shadow-lg p-5">
          <UploadFile
            currentFolderId={currentFolderId}
            onFileUploaded={setContents}
          />
        </div>
      )}

      {/* Folder Contents */}
      <div>
        {/* <h2>{contents.name}</h2> */}
        <button
          className="border bg-white p-2 rounded-lg flex gap-2 items-center align-middle cursor-pointer shadow-lg hover:shadow-none mb-5"
          onClick={goBack}
          disabled={path.length === 0}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
          <div>Back</div>
        </button>
        <div>
          {contents.subFolders.length > 0 && (
            <div>
              <div className="font-bold mb-2 text-xl">Folders</div>
              <div className="flex flex-wrap gap-2">
                {contents.subFolders.map((folder: UserFolder, index) => (
                  <div key={index} className="flex flex-wrap">
                    <FolderItem
                      key={folder.folderID}
                      folder={folder}
                      onNavigate={navigateToFolder}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

        {contents.files.length > 0 && ( 
            <div>
          <div className="font-bold my-2 mt-5 text-xl">Files</div>
          {contents.files.map((file: any, index) => (
            <div key={index} className="">
              <FileItem key={file.fileId} file={file} />
            </div>
          ))}
        </div>
        )}
          {contents.files.length === 0 && contents.subFolders.length === 0 && (
            <div className="text-sm text-muted">There are no files yet...</div>
          )}
        </div>
      </div>
    </div>
  );
};
