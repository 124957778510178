import React, { useEffect, useRef } from "react";
import { Chart, ChartData } from "chart.js/auto";

interface DonutChartProps {
  data: ChartData;
}

const DonutChart: React.FC<DonutChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRef = useRef<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        if (chartInstanceRef.current) {
          chartInstanceRef.current.data = data;
          chartInstanceRef.current.update();
        } else {
          chartInstanceRef.current = new Chart(ctx, {
            type: "doughnut",
            data: data,
          });
        }
      }
    }
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default DonutChart;
