// FolderList.tsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faFolder,
  faFolderOpen,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

interface folderListProps {
  folders: string[];
  routeOnClick: (to: string) => void;
  fileCounts: { [key: string]: number };
}

const FolderList: React.FC<folderListProps> = ({ folders, routeOnClick, fileCounts }) => {
  return (
    <div className="mt-10 w-[1500px]">
      <div className="">
        <div className="flex flex-wrap justify-start gap-5 w-full">
          {folders.map((folder, index) => (
            <div
              className="bg-card dark:bg-card-dark dark:text-text-dark p-3 shadow-sm flex gap-0.5 cursor-pointer hover:shadow-sm hover:bg-text-dark hover:dark:bg-text rounded-3xl flex-col inter-font"
              onClick={() => routeOnClick(folder)}
              key={index}
            >
              <div className="flex justify-between pt-5 pb-2 px-2 min-w-[120px]">
                <div className="text-4xl text-[#979DAC]">
                  <FontAwesomeIcon icon={faFolderOpen} />
                </div>
                <div className="text-xl text-muted-dark">
                <FontAwesomeIcon icon={faEllipsisVertical} />
                </div>
              </div>
              <div className="text-sm truncate w-[120px]">{folder}</div>
              <div className="text-xs text-muted">{fileCounts[folder] || 0} Files</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FolderList;
