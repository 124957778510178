import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Define the shape of your environment variables
interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string; 
  storageBucket: string; 
  messagingSenderId: string;
  appId: string;
  measurementId: string;
  // ... other config variables
}

const firebaseConfig:FirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY as string,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID as string,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET as string,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID as string,
  appId: process.env.REACT_APP_FIREBASE_APP_ID as string,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID as string,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
