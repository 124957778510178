import React, { useState, useEffect } from "react";
import QuestionComponent from "./QuestionComponent";
import { FormConfig, Step, Validation } from "../../../interfaces/dynamicForms/FormInterfaces";

interface DynamicFormProps {
  config: FormConfig;
}

const DynamicForm: React.FC<DynamicFormProps> = ({ config }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});
  const [derivedAnswers, setDerivedAnswers] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const currentStep: Step = config.Steps[currentStepIndex];
  const totalSteps = config.Steps.length;

  const handleInputChange = (questionId: string, value: any) => {
    setFormState((prevState) => ({
      ...prevState,
      [questionId]: value,
    }));
  };

  useEffect(() => {
    const newDerivedAnswers: Record<string, any> = {};
    config.Answers.forEach((answer) => {
      const derivedValue = eval(answer.Logic);
      newDerivedAnswers[answer.Id] = derivedValue;
    });
    setDerivedAnswers(newDerivedAnswers);
  }, [formState, config.Answers]);

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};

    currentStep.Questions.forEach((question) => {
      const validation = config.Validations.find(
        (v) => v.QuestionId === question.Id
      );

      if (validation) {
        const value = formState[validation.QuestionId];

        switch (validation.ValidationType) {
          case "required":
            if (!value) {
              newErrors[validation.QuestionId] =
                validation.ErrorMessage || "This field is required.";
            }
            break;

          case "minLength":
            if (value && value.length < (validation.Value ?? 0)) {
              newErrors[validation.QuestionId] =
                validation.ErrorMessage ||
                `Minimum length is ${validation.Value}.`;
            }
            break;

          case "minSelection":
            if (Array.isArray(value) && value.length < (validation.Value ?? 0)) {
              newErrors[validation.QuestionId] =
                validation.ErrorMessage ||
                `You must select at least ${validation.Value} options.`;
            }
            break;

          case "range":
            if (
              value &&
              (value < (validation.MinValue ?? -Infinity) ||
                value > (validation.MaxValue ?? Infinity))
            ) {
              newErrors[validation.QuestionId] =
                validation.ErrorMessage ||
                `Value must be between ${validation.MinValue} and ${validation.MaxValue}.`;
            }
            break;

          // Add other validation types as needed
        }
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = () => {
    if (validateForm()) {
      let nextStepIndex = currentStepIndex + 1;

      // Loop to skip steps if their conditionals are not met
      while (
        nextStepIndex < config.Steps.length &&
        config.Steps[nextStepIndex].Conditional &&
        !eval(config.Steps[nextStepIndex].Conditional as string)
      ) {
        nextStepIndex++;
      }

      // If we found a valid step, move to it
      if (nextStepIndex < config.Steps.length) {
        setCurrentStepIndex(nextStepIndex);
        setErrors({});
      }
    }
  };

  const handlePreviousStep = () => {
    let prevStepIndex = currentStepIndex - 1;

    // Loop to skip steps if their conditionals are not met
    while (
      prevStepIndex >= 0 &&
      config.Steps[prevStepIndex].Conditional &&
      !eval(config.Steps[prevStepIndex].Conditional as string)
    ) {
      prevStepIndex--;
    }

    // If we found a valid step, move to it
    if (prevStepIndex >= 0) {
      setCurrentStepIndex(prevStepIndex);
      setErrors({});
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      const pdfData: Record<string, any> = {};

      config.Mappings.forEach((mapping) => {
        pdfData[mapping.PDFField] = derivedAnswers[mapping.AnswerId];
      });

      console.log("Form Submitted:", formState, derivedAnswers);
      console.log("PDF Data:", pdfData);
      // Send pdfData to your PDF generation service
    }
  };

  // Calculate progress percentage
  const progressPercentage = ((currentStepIndex + 1) / totalSteps) * 100;

  return (
    <div className="relative bg-background sm:min-w-[640px] overflow-x-auto max-w-full flex-grow flex-wrap">
      <div className="h-[300px] w-full bg-navBackground -z-10 rounded-b-3xl"></div>
      <div className="relative flex flex-wrap flex-grow bg-[#F7F9FC] dark:bg-[#141416] h-auto">
        <div className="relative -top-64 bg-[#F7F9FC] w-11/12 mx-auto -mt-10 rounded-3xl p-4 shadow-lg min-h-[500px] flex align-middle justify-center items-center">
          <form
            onSubmit={handleSubmit}
            className="max-w-[700px] min-w-[500px] border p-4 px-10 shadow rounded-3xl bg-white"
          >
            {/* Progress Bar */}
            <div className="mb-4">
              <div className="text-center font-semibold mb-2">
                Step {currentStepIndex + 1} of {totalSteps}
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div
                  className="bg-[#3DD598] h-2.5 rounded-full"
                  style={{ width: `${progressPercentage}%` }}
                ></div>
              </div>
              <div className="text-right font-semibold mt-2">
                {Math.round(progressPercentage)}% Complete
              </div>
            </div>

            <h3 className="text-text inter-font text-2xl">
              {config.FormName} - {currentStep.StepName}
            </h3>
            <p>{config.Waiver}</p>

            {/* Display the questions for the current step */}
            {currentStep.Questions.map((question) => (
              <div
                key={question.Id}
                className={`my-2 ${
                  errors[question.Id]
                    ? "bg-red-100 p-2 rounded-xl relative border-red-500 border-2"
                    : ""
                }`}
              >
                {errors[question.Id] && (
                  <p className="text-red-600">{errors[question.Id]}</p>
                )}
                <QuestionComponent
                  question={question}
                  formState={formState}
                  handleInputChange={handleInputChange}
                  options={config.Options}
                />
              </div>
            ))}

            <div className="flex justify-between mt-4">
              {currentStepIndex > 0 && (
                <button
                  type="button"
                  onClick={handlePreviousStep}
                  className="my-3 bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded-md"
                >
                  Previous
                </button>
              )}

              {currentStepIndex < config.Steps.length - 1 && (
                <button
                  type="button"
                  onClick={handleNextStep}
                  className="my-3 bg-[#3DD598] hover:bg-[#46efab] text-white py-2 px-4 rounded-md ml-auto"
                >
                  Next
                </button>
              )}

              {currentStepIndex === config.Steps.length - 1 && (
                <button
                  type="submit"
                  className="my-3 bg-[#3DD598] hover:bg-[#46efab] text-white py-2 px-4 rounded-md ml-auto"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DynamicForm;
