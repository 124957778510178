import React, { useState, useRef } from "react";
import { UserFolderResponse } from "../../../../../interfaces/userDrive/userFolderResponse";
import {
  getFolderContents,
  uploadFile,
} from "../../../../../services/fileServices/UserDriverService";
import { useAuth } from "../../../../../firebase/AuthProvider";

interface UploadFileProps {
  currentFolderId?: number;
  onFileUploaded: (updatedContents: UserFolderResponse) => void;
}

export const UploadFile: React.FC<UploadFileProps> = ({
  currentFolderId,
  onFileUploaded,
}) => {
  const { currentUser } = useAuth();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  // Reference to the hidden file input
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // Handle file selection via both traditional and drag-and-drop methods
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  // Handle the file upload logic
  const handleUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("File", selectedFile);
    if (currentFolderId) {
      formData.append("FolderID", currentFolderId.toString());
    }
    formData.append("FileName", selectedFile.name);

  // Use Array.from to convert the FormData entries to an array
  Array.from(formData.entries()).forEach(([key, value]) => {
    console.log(`${key}: ${value}`);
  });

    try {
      if (currentUser) {
        const authToken = await currentUser.getIdToken();
        await uploadFile(authToken, formData);
        setSelectedFile(null);
        // Refresh the folder contents
        const updatedContents = await getFolderContents(authToken, currentFolderId);
        if (updatedContents) { 
          onFileUploaded(updatedContents);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Handle drag events for drag-and-drop support
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setSelectedFile(e.dataTransfer.files[0]);
    }
  };

  // Handle click to open file picker
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();  // Programmatically click the hidden file input
    }
  };

  return (
    <div className="upload-file">
      {/* Drag and Drop Area that is also clickable */}
      <div
        onClick={handleClick}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={`drag-drop-area ${isDragging ? "dragging" : ""} h-[92px]`}
        style={{
          border: isDragging ? "2px dashed #4A90E2" : "2px dashed #ccc",
          padding: "20px",
          textAlign: "center",
          marginBottom: "20px",
          cursor: "pointer",  // Add pointer cursor to indicate it's clickable
        }}
      >
        {selectedFile ? (
          <p>Selected file: {selectedFile.name}</p>
        ) : (
          <p>Drag and drop a file here, or click to select a file.</p>
        )}
      </div>

      {/* Hidden File Input */}
      <input
        type="file"
        ref={fileInputRef}  // Reference to the hidden input
        style={{ display: "none" }}  // Hide the file input
        onChange={handleFileChange}
      />

      {/* Upload Button */}
      <button onClick={handleUpload}
          className={`bg-[#3DD598] ${!selectedFile ? 'bg-[#3dd5989c]' : ''} text-white rounded w-full p-2`}
       disabled={!selectedFile}>
        Upload File
      </button>
    </div>
  );
};
