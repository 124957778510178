import React, { useEffect, useState } from "react";
import Task from "../../../interfaces/task/Task";
import { formatDateToMMDDYYYY } from "../../../utilities/dateUtils";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../firebase/AuthProvider";
import { getTasks } from "../../../services/taskServices/TaskService";
import AssigneeWithTooltip from "../taskPage/AssigneeWithTooltip";
import TaskList from "../../../interfaces/task/TaskList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

type TaskSectionProps = {
  overdueTasksUnfiltered: TaskList[] | undefined;
  tasksDueTodayUnfiltered: TaskList[] | undefined;
  tasksDueThisWeekUnfiltered: TaskList[] | undefined;
  tasksDueThisMonthUnfiltered: TaskList[] | undefined;
  nonTemplateInterimsUnfiltered: TaskList[] | undefined;
  userId?: string | null;
};

const TaskSection: React.FC<TaskSectionProps> = ({
  tasksDueTodayUnfiltered,
  tasksDueThisWeekUnfiltered,
  tasksDueThisMonthUnfiltered,
  overdueTasksUnfiltered,
  nonTemplateInterimsUnfiltered,
  userId,
}) => {
  const navigate = useNavigate();
  const maxTasksToShow = 10; // Maximum number of overdue tasks to display initially
  const { currentUser, currentRoles } = useAuth();

  if (!userId) {
    userId = currentUser?.uid;
  }

  var tasksDueToday = tasksDueTodayUnfiltered;
  var tasksDueThisWeek = tasksDueThisWeekUnfiltered;
  var tasksDueThisMonth = tasksDueThisMonthUnfiltered;
  var overdueTasks = overdueTasksUnfiltered;
  var nonTemplateInterims = nonTemplateInterimsUnfiltered;

  if (currentRoles?.some((role) => role.name !== "SC") && currentUser?.uid !== userId) {
    tasksDueToday = tasksDueTodayUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    tasksDueThisWeek = tasksDueThisWeekUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    tasksDueThisMonth = tasksDueThisMonthUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    overdueTasks = overdueTasksUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );  
    nonTemplateInterims = nonTemplateInterimsUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );  
  } else if (currentRoles?.some((role) => role.name !== "SC")) {

  } else {
    tasksDueToday = tasksDueTodayUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    tasksDueThisWeek = tasksDueThisWeekUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    tasksDueThisMonth = tasksDueThisMonthUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    overdueTasks = overdueTasksUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    nonTemplateInterims = nonTemplateInterimsUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );  
  }

  return (
    <div className="flex max-w-3/5 2xl: sm:w-full mx-auto dark:text-text-dark relative -top-44 mt-10 flex-grow h-auto min-h-[446px] flex-wrap overflow-wrap justify-center gap-5">
      <div className="min-w-1/4 max-w-[400px] 2xl:max-w-[550px] min-h-[400px] max-h-full w-full border-none bg-card dark:bg-card-dark shadow-lg rounded-3xl h-auto flex-grow-0 basis-[40%]">
        <h2 className="text-center bg-danger text-white rounded-t-3xl py-3 font-inter text-xl">
          <span
            onClick={() => {if (userId === currentUser?.uid) { navigate(`/tasks?date=overdue`) } else { navigate(`/tasks?date=overdue&assignee=${userId}`)}
            }}
            className="cursor-pointer hover:text-gray-300"
          >
            Overdue
          </span>
        </h2>
        <div className="">
          <div
            className={`${
              currentRoles?.length !== 1 && userId === currentUser?.uid
                ? "columns-4"
                : "columns-3"
            } font-bold py-2 bg-gray-100 text-[11px] px-5 font-inter`}
          >
            <div>Type</div>
            <div>Participant</div>
            <div>Due Date</div>
            {currentRoles?.length !== 1 && userId === currentUser?.uid && (
              <div>To Review</div>
            )}{" "}
          </div>
          <hr className="m-auto"></hr>
          {overdueTasks?.slice(0, maxTasksToShow).map((value, index) => (
            <div
              onClick={() => navigate(`/task/BLOSSOM-${value.id}`)}
              className={`${
                currentRoles?.length !== 1 && userId === currentUser?.uid
                  ? "columns-4"
                  : "columns-3"
              }  hover:shadow-lg px-5 py-1 text-sm text-muted font-inter dark:text-muted-dark cursor-pointer ${
                index !== 0 && "border-t"
              }`}
              key={index}
            >
              <div className="truncate">
                {value.subType.type
                  ? value.subType.type
                      .toLowerCase()
                      .split(" ")
                      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      .join(" ")
                  : "N/A"}
              </div>
              <div className="truncate">
                {value.participant?.firstName
                  ? value.participant.firstName[0] +
                    ". " +
                    value.participant.lastName
                  : "N/A"}
              </div>
              <div className="truncate">
                {formatDateToMMDDYYYY(value.endDate)}
              </div>
              {currentRoles?.length !== 1 && userId === currentUser?.uid && (
                <>
                  {value.assignees.some((assignee) => {
                    if (
                      assignee.isReporter === false &&
                      assignee.isCoAssignee === false
                    ) {
                      return assignee.user.id === currentUser?.uid;
                    } else if (
                      assignee.isReporter === false &&
                      assignee.isCoAssignee === true
                    ) {
                      return assignee.user.id === currentUser?.uid;
                    }
                  }) ? (
                    <div></div>
                  ) : (
                    <div className="text-red-500 text-center">*</div>
                  )}
                </>
              )}
            </div>
          ))}
          {overdueTasks && overdueTasks.length > maxTasksToShow && (
            <button
              onClick={() => {if (userId === currentUser?.uid) { navigate(`/tasks?date=overdue`) } else { navigate(`/tasks?date=overdue&assignee=${userId}`)}}}
              className="mx-auto my-4 px-4 py-2 bg-primary text-white font-bold rounded-lg hover:bg-primary-dark focus:outline-none focus:bg-primary-dark flex gap-2 items-center justify-center"
            >
              <FontAwesomeIcon className="font-bold" icon={faArrowDown} />
              Show All Overdue Tasks
            </button>
          )}
        </div>
      </div>
      <div className="min-w-1/4 max-w-[400px] 2xl:max-w-[550px] min-h-[400px] max-h-full w-full border-none bg-card dark:bg-card-dark shadow-lg rounded-3xl h-auto flex-grow-0 basis-[40%]">
        <h2 className="text-center bg-warning text-white rounded-t-3xl py-3 font-inter text-xl">
          <span
            onClick={() => {if (userId === currentUser?.uid) { navigate(`/tasks?date=today`) } else { navigate(`/tasks?date=today&assignee=${userId}`)}}}
            className="cursor-pointer hover:text-gray-300"
          >
            Due Today
          </span>
        </h2>
        <div className="">
          <div
            className={`${
              currentRoles?.length !== 1 && userId === currentUser?.uid
                ? "columns-4"
                : "columns-3"
            }  font-bold py-2 bg-gray-100 text-[11px] px-5 font-inter`}
          >
            <div>Type</div>
            <div>Participant</div>
            <div>Due Date</div>
            {currentRoles?.length !== 1 && userId === currentUser?.uid && (
              <div>To Review</div>
            )}{" "}
          </div>
          <hr className="m-auto"></hr>
          {tasksDueToday?.slice(0, maxTasksToShow).map((value, index) => (
            <div
              onClick={() => navigate(`/task/BLOSSOM-${value.id}`)}
              className={`${
                currentRoles?.length !== 1 && userId === currentUser?.uid
                  ? "columns-4"
                  : "columns-3"
              }  hover:shadow-lg px-5 py-1 text-sm text-muted font-inter dark:text-muted-dark cursor-pointer ${
                index !== 0 && "border-t"
              }`}
              key={index}
            >
              <div className="truncate">
                {value.subType.type
                  ? value.subType.type
                      .toLowerCase()
                      .split(" ")
                      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      .join(" ")
                  : "N/A"}
              </div>
              <div className="truncate">
                {value.participant?.firstName
                  ? value.participant.firstName[0] +
                    ". " +
                    value.participant.lastName
                  : "N/A"}
              </div>
              <div className="truncate">
                {formatDateToMMDDYYYY(value.endDate)}
              </div>
              {currentRoles?.length !== 1 && userId === currentUser?.uid && (
                <>
                  {value.assignees.some((assignee) => {
                    if (
                      assignee.isReporter === false &&
                      assignee.isCoAssignee === false
                    ) {
                      return assignee.user.id === currentUser?.uid;
                    } else if (
                      assignee.isReporter === false &&
                      assignee.isCoAssignee === true
                    ) {
                      return assignee.user.id === currentUser?.uid;
                    }
                  }) ? (
                    <div></div>
                  ) : (
                    <div className="text-red-500 text-center">*</div>
                  )}
                </>
              )}
            </div>
          ))}
          {tasksDueToday && tasksDueToday.length > maxTasksToShow && (
            <button
            onClick={() => {if (userId === currentUser?.uid) { navigate(`/tasks?date=today`) } else { navigate(`/tasks?date=today&assignee=${userId}`)}}}
              className="mx-auto mt-4 px-4 py-2 bg-primary text-white font-bold rounded-lg hover:bg-primary-dark focus:outline-none focus:bg-primary-dark flex gap-2 items-center justify-center"
            >
              <FontAwesomeIcon className="font-bold" icon={faArrowDown} />
              Show All Tasks Due Today
            </button>
          )}
        </div>
      </div>
      <div className="min-w-1/4 max-w-[400px] 2xl:max-w-[550px] min-h-[400px] max-h-full w-full border-none bg-card dark:bg-card-dark shadow-lg rounded-3xl h-auto flex-grow-0 basis-[40%]">
        <h2 className="text-center bg-primary text-white rounded-t-3xl py-3 font-inter text-xl">
          <span
            onClick={() => {if (userId === currentUser?.uid) { navigate(`/tasks?date=week`) } else { navigate(`/tasks?date=week&assignee=${userId}`)}}}
            className="cursor-pointer hover:text-gray-300"
          >
            This Week
          </span>
        </h2>
        <div
          className={`${
            currentRoles?.length !== 1 && userId === currentUser?.uid
              ? "columns-4"
              : "columns-3"
          }  font-bold py-2 bg-gray-100 text-[11px] px-5 font-inter`}
        >
          <div>Type</div>
          <div>Participant</div>
          <div>Due Date</div>
          {currentRoles?.length !== 1 && userId === currentUser?.uid && (
            <div>To Review</div>
          )}{" "}
        </div>
        <hr className="m-auto"></hr>
        {tasksDueThisWeek?.slice(0, maxTasksToShow).map((value, index) => (
          <div
            onClick={() => navigate(`/task/BLOSSOM-${value.id}`)}
            className={`${
              currentRoles?.length !== 1 && userId === currentUser?.uid
                ? "columns-4"
                : "columns-3"
            }  hover:shadow-lg px-5 py-1 text-sm text-muted font-inter dark:text-muted-dark cursor-pointer ${
              index !== 0 && "border-t"
            }`}
            key={index}
          >
            <div className="truncate">
              {value.subType.type
                ? value.subType.type
                    .toLowerCase()
                    .split(" ")
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" ")
                : "N/A"}
            </div>
            <div className="truncate">
              {value.participant?.firstName
                ? value.participant.firstName[0] +
                  ". " +
                  value.participant.lastName
                : "N/A"}
            </div>
            <div className="truncate">
              {formatDateToMMDDYYYY(value.endDate)}
            </div>
            {currentRoles?.length !== 1 && userId === currentUser?.uid && (
              <>
                {value.assignees.some((assignee) => {
                  if (
                    assignee.isReporter === false &&
                    assignee.isCoAssignee === false
                  ) {
                    return assignee.user.id === currentUser?.uid;
                  } else if (
                    assignee.isReporter === false &&
                    assignee.isCoAssignee === true
                  ) {
                    return assignee.user.id === currentUser?.uid;
                  }
                }) ? (
                  <div></div>
                ) : (
                  <div className="text-red-500 text-center">*</div>
                )}
              </>
            )}
          </div>
        ))}
        {tasksDueThisWeek && tasksDueThisWeek.length > maxTasksToShow && (
          <button
          onClick={() => {if (userId === currentUser?.uid) { navigate(`/tasks?date=week`) } else { navigate(`/tasks?date=week&assignee=${userId}`)}}}

            className="mx-auto mt-4 px-4 py-2 bg-primary text-white font-bold rounded-lg hover:bg-primary-dark focus:outline-none focus:bg-primary-dark flex gap-2 items-center justify-center"
          >
            <FontAwesomeIcon className="font-bold" icon={faArrowDown} />
            Show All Tasks Due This Week
          </button>
        )}
      </div>
      <div className="min-w-1/4 max-w-[400px] 2xl:max-w-[550px] min-h-[400px] max-h-full w-full border-none bg-card dark:bg-card-dark shadow-lg rounded-3xl h-auto flex-grow-0 basis-[40%]">
        <h2 className="text-center bg-[#BD5AEF] text-white rounded-t-3xl py-3 font-inter text-xl">
          <span
            onClick={() => {if (userId === currentUser?.uid) { navigate(`/tasks?date=month`) } else { navigate(`/tasks?date=month&assignee=${userId}`)}}}
            className="cursor-pointer hover:text-gray-300"
          >
            This Month
          </span>
        </h2>
        <div
          className={`${
            currentRoles?.length !== 1 && userId === currentUser?.uid
              ? "columns-4"
              : "columns-3"
          }  font-bold py-2 bg-gray-100 text-[11px] px-5 font-inter`}
        >
          <div>Type</div>
          <div>Participant</div>
          <div>Due Date</div>
          {currentRoles?.length !== 1 && userId === currentUser?.uid && (
            <div>To Review</div>
          )}
        </div>
        <hr className="m-auto"></hr>
        {tasksDueThisMonth?.slice(0, maxTasksToShow).map((value, index) => (
          <div
            onClick={() => navigate(`/task/BLOSSOM-${value.id}`)}
            className={`${
              currentRoles?.length !== 1 && userId === currentUser?.uid
                ? "columns-4"
                : "columns-3"
            }  hover:shadow-lg px-5 py-1 text-sm text-muted font-inter dark:text-muted-dark cursor-pointer ${
              index !== 0 && "border-t"
            }`}
            key={index}
          >
            <div className="truncate">
              {value.subType.type
                ? value.subType.type
                    .toLowerCase()
                    .split(" ")
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" ")
                : "N/A"}
            </div>
            <div className="truncate">
              {value.participant?.firstName
                ? value.participant.firstName[0] +
                  ". " +
                  value.participant.lastName
                : "N/A"}
            </div>
            <div className="truncate">
              {formatDateToMMDDYYYY(value.endDate)}
            </div>
            {currentRoles?.length !== 1 && userId === currentUser?.uid && (
              <>
                {value.assignees.some((assignee) => {
                  if (
                    assignee.isReporter === false &&
                    assignee.isCoAssignee === false
                  ) {
                    return assignee.user.id === currentUser?.uid;
                  } else if (
                    assignee.isReporter === false &&
                    assignee.isCoAssignee === true
                  ) {
                    return assignee.user.id === currentUser?.uid;
                  }
                }) ? (
                  <div></div>
                ) : (
                  <div className="text-red-500 text-center">*</div>
                )}
              </>
            )}
          </div>
        ))}
        {tasksDueThisMonth && tasksDueThisMonth.length > maxTasksToShow && (
          <button
          onClick={() => {if (userId === currentUser?.uid) { navigate(`/tasks?date=month`) } else { navigate(`/tasks?date=month&assignee=${userId}`)}}}

            className="mx-auto mt-4 px-4 py-2 bg-primary text-white font-bold rounded-lg hover:bg-primary-dark focus:outline-none focus:bg-primary-dark flex gap-2 items-center justify-center"
          >
            <FontAwesomeIcon className="font-bold" icon={faArrowDown} />
            Show All Tasks Due This Month
          </button>
        )}
      </div>
      <div className="min-w-1/4 max-w-[400px] 2xl:max-w-[550px] min-h-[400px] max-h-full w-full border-none bg-card dark:bg-card-dark shadow-lg rounded-3xl h-auto flex-grow-0 basis-[40%]">
        <h2 className="text-center bg-[#89DCEB] text-white rounded-t-3xl py-3 font-inter text-xl">
          <span
            onClick={() => {if (userId === currentUser?.uid) { navigate(`/tasks?date=month`) } else { navigate(`/tasks?date=month&assignee=${userId}`)}}}
            className="cursor-pointer hover:text-gray-300"
          >
            Interims
          </span>
        </h2>
        <div
          className={`${
            currentRoles?.length !== 1 && userId === currentUser?.uid
              ? "columns-4"
              : "columns-3"
          }  font-bold py-2 bg-gray-100 text-[11px] px-5 font-inter`}
        >
          <div>Type</div>
          <div>Participant</div>
          <div>Due Date</div>
          {currentRoles?.length !== 1 && userId === currentUser?.uid && (
            <div>To Review</div>
          )}
        </div>
        <hr className="m-auto"></hr>
        {nonTemplateInterims?.slice(0, maxTasksToShow).map((value, index) => (
          <div
            onClick={() => navigate(`/task/BLOSSOM-${value.id}`)}
            className={`${
              currentRoles?.length !== 1 && userId === currentUser?.uid
                ? "columns-4"
                : "columns-3"
            }  hover:shadow-lg px-5 py-1 text-sm text-muted font-inter dark:text-muted-dark cursor-pointer ${
              index !== 0 && "border-t"
            }`}
            key={index}
          >
            <div className="truncate">
              {value.subType.type
                ? value.subType.type
                    .toLowerCase()
                    .split(" ")
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" ")
                : "N/A"}
            </div>
            <div className="truncate">
              {value.participant?.firstName
                ? value.participant.firstName[0] +
                  ". " +
                  value.participant.lastName
                : "N/A"}
            </div>
            <div className="truncate">
              {formatDateToMMDDYYYY(value.endDate)}
            </div>
            {currentRoles?.length !== 1 && userId === currentUser?.uid && (
              <>
                {value.assignees.some((assignee) => {
                  if (
                    assignee.isReporter === false &&
                    assignee.isCoAssignee === false
                  ) {
                    return assignee.user.id === currentUser?.uid;
                  } else if (
                    assignee.isReporter === false &&
                    assignee.isCoAssignee === true
                  ) {
                    return assignee.user.id === currentUser?.uid;
                  }
                }) ? (
                  <div></div>
                ) : (
                  <div className="text-red-500 text-center">*</div>
                )}
              </>
            )}
          </div>
        ))}
        {nonTemplateInterims && nonTemplateInterims.length > maxTasksToShow && (
          <button
          onClick={() => {if (userId === currentUser?.uid) { navigate(`/tasks?type=interim`) } else { navigate(`/tasks?date=month&assignee=${userId}`)}}}

            className="mx-auto my-4 px-4 py-2 bg-[#89DCEB] text-white font-bold rounded-lg hover:bg-[#89dcebc3] focus:outline-none focus:bg-primary-dark flex gap-2 items-center justify-center"
          >
            <FontAwesomeIcon className="font-bold" icon={faArrowDown} />
            Show All Interim Tasks
          </button>
        )}
      </div>
    </div>
  );
};

type TaskTableProps = {
  overdueTasks: TaskList[] | undefined;
  tasksDueToday: TaskList[] | undefined;
  tasksDueThisWeek: TaskList[] | undefined;
  tasksDueThisMonth: TaskList[] | undefined;
  nonTemplateInterims: TaskList[] | undefined;
  userId?: string | null;
};

const TaskTable: React.FC<TaskTableProps> = ({
  overdueTasks,
  tasksDueToday,
  tasksDueThisWeek,
  tasksDueThisMonth,
  nonTemplateInterims,
  userId,
}) => {
  return (
    <div className="flex flex-col w-full flex-wrap flex-grow h-auto">
      <TaskSection
        overdueTasksUnfiltered={overdueTasks}
        tasksDueThisMonthUnfiltered={tasksDueThisMonth}
        tasksDueThisWeekUnfiltered={tasksDueThisWeek}
        tasksDueTodayUnfiltered={tasksDueToday}
        nonTemplateInterimsUnfiltered={nonTemplateInterims}
        userId={userId}
      />
    </div>
  );
};

interface TaskProps {
  userId?: string;
}

const Tasks: React.FC<TaskProps> = ({ userId = null }) => {
  const [tasks, setTasks] = useState<TaskList[] | null>([]);

  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser !== null) {
      const fetchTasksForCurrentUser = async () => {
        const authToken: string = await currentUser.getIdToken();
        let fetchedTasks: TaskList[] | undefined = [];
        if (userId) {
          fetchedTasks = await getTasks(authToken, userId).then((task) =>
            task?.filter((t) => t.status.name !== "COMPLETED")
          );
        } else {
          const userId = currentUser.uid;
          fetchedTasks = await getTasks(authToken, userId).then((task) =>
            task?.filter((t) => t.status.name !== "COMPLETED")
          );
        }
        const sortedTasks = fetchedTasks?.sort(
          (a, b) =>
            new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
        );
        if (sortedTasks) {
          setTasks(sortedTasks);
        }
      };

      fetchTasksForCurrentUser();
    }
  }, []);

  const today = new Date();
  const endOfToday = new Date(today);
  endOfToday.setHours(23, 59, 59, 999);

  const endOfThisWeek = new Date(today);
  endOfThisWeek.setDate(endOfThisWeek.getDate() + (7 - endOfThisWeek.getDay()));

  const endOfNextMonth = new Date(today);
  endOfNextMonth.setMonth(endOfNextMonth.getMonth() + 1);
  endOfNextMonth.setHours(23, 59, 59, 999);

  const tasksDueToday: TaskList[] | null | undefined = tasks?.filter(
    (task) => task.endDate >= today && task.endDate <= endOfToday && task.fromTemplate
  );
  const tasksDueThisWeek: TaskList[] | null | undefined = tasks?.filter(
    (task) => task.endDate > endOfToday && task.endDate <= endOfThisWeek && task.fromTemplate
  );
  const tasksDueThisMonth: TaskList[] | null | undefined = tasks?.filter(
    (task) => task.endDate > endOfThisWeek && task.endDate <= endOfNextMonth && task.fromTemplate
  );
  const overdueTasks: TaskList[] | null | undefined = tasks?.filter(
    (task) => task.endDate < today && task.fromTemplate
  );

  const nonTemplateInterims: TaskList[] | null | undefined = tasks?.filter(
    (task) => !task.fromTemplate
  );

  return (
    <div className="flex flex-col flex-wrap flex-grow h-auto">
      <TaskTable
        overdueTasks={overdueTasks}
        tasksDueThisMonth={tasksDueThisMonth}
        tasksDueThisWeek={tasksDueThisWeek}
        tasksDueToday={tasksDueToday}
        nonTemplateInterims={nonTemplateInterims}
        userId={userId}
      />
    </div>
  );
};

export default Tasks;
