import React, { useEffect, useState } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import TaskWorkflowGroup from "../../../interfaces/task/workflow/TaskWorkflowGroup";
import {
  deleteWorkflowGroupByName,
  getWorkflowGroups,
} from "../../../services/taskServices/TaskWorkflowServices";
import NewWorkflows from "./NewWorkflowGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import AssigneeWithTooltip from "../taskPage/AssigneeWithTooltip";

const EditWorkflows: React.FC = () => {
  const { currentUser } = useAuth();
  const [workflows, setWorkflows] = useState<TaskWorkflowGroup>();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [editUsers, setEditUsers] = useState<string>("");

  useEffect(() => {
    const fetchWorkflows = async () => {
      if (currentUser) {
        const authToken: string = await currentUser.getIdToken();
        return await getWorkflowGroups(authToken);
      }
    };

    if (!workflows || workflows === undefined) {
      const promise = fetchWorkflows();
      promise.then((item) => {
        console.log('Item: ', item)
        if (item) {
          console.log('Item: ', item);
          setWorkflows(item);
        }
      });
      promise.finally(() => {
        console.log('finally')
      })
    }

    console.log("workflows", workflows);
  }, [reload]);

  const toggleForm = () => {
    setShowForm(!showForm);
    setWorkflows(undefined);
    setReload(!reload);
  };

  const deleteWorkflowGroup = async (flowGroupName: string) => {
    if (currentUser) {
      const authToken: string = await currentUser.getIdToken();
      await deleteWorkflowGroupByName(flowGroupName, authToken);
      setWorkflows(undefined);
      setReload(!reload);
    }
  };

  const toggleUserEdit = (workflowGroupName: string) => { 
    setEditUsers(workflowGroupName);
  };

  return (
    <div>
      {showForm ? (
        <div>
          <NewWorkflows toggleForm={toggleForm} />
        </div>
      ) : (
        <div className="bg-card dark:bg-card-dark text-text dark:text-text-dark rounded-lg shadow-lg p-5 my-10">
          {workflows &&
            Object.entries(workflows).map(([flowGroup, groupData]) => (
              <div
                key={flowGroup}
                className="border shadow-md p-2 mb-5 rounded-md"
              >
                <div className="title important flex justify-between">
                  <div>Workflow Group "{flowGroup}"</div>
                  <div className="flex text-sm gap-2">
                    {/* <div className="cursor-pointer "><FontAwesomeIcon icon={faPencil} /></div> */}
                    <div
                      onClick={() => deleteWorkflowGroup(flowGroup)}
                      className="cursor-pointer text-danger"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                  </div>
                </div>
                {groupData.approvalFlows
                  .sort((a, b) => a.stepOrder - b.stepOrder)
                  .map((workflow) => (
                    <div key={workflow.id} className="flex gap-2">
                      <div>{workflow.stepOrder}. </div>
                      <div>{workflow.role.name}</div>
                    </div>
                  ))}
              </div>
            ))}
          <button
            onClick={toggleForm}
            className="dark-button mt-5 ms-auto block"
          >
            New Workflow Group
          </button>
        </div>
      )}
    </div>
  );
};

export default EditWorkflows;
