// DashboardStats.ts

import DashboardStats from "../../interfaces/stats/DashboardStats";
import TeamStatsInterface from "../../interfaces/stats/TeamStatsInterface";

// Function to fetch all dashbobard stats of an employee
export async function getDashboardStats(
  authToken: string, 
  userId?: string
): Promise<DashboardStats | null> {
  try {
    let apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/dashboardStats`;

    if ( userId ) {
      apiUrl = `${process.env.REACT_APP_API_BASE_URL}/tasks/dashboardStats/user/${userId}`;
    }

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const stats: DashboardStats = await response.json();
    // Map the participant data
    const dashboardStats: DashboardStats = {
        numberOfMonthlies: stats.numberOfMonthlies, 
        numberOfQuarterlies: stats.numberOfQuarterlies, 
        numberOfAnnuals: stats.numberOfAnnuals, 
        numberOfInterims: stats.numberOfInterims, 
        tasksClosedToday: stats.tasksClosedToday, 
        timeLoggedToday: stats.timeLoggedToday, 
        timeLoggedWeek: stats.timeLoggedWeek, 
        activitiesCompletedToday: stats.activitiesCompletedToday, 
        activitiesCompletedWeek: stats.activitiesCompletedWeek
    };

    return dashboardStats;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching participants:", error);
    return null;
  }
}

export async function getTeamStats(
  authToken: string
): Promise<TeamStatsInterface[] | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/teamStats`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const stats: TeamStatsInterface[] = await response.json();

    return stats;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching participants:", error);
    return null;
  }
}