import { useEffect, useState } from "react";
import { TaskAssignee } from "../../../interfaces/task/TaskAssignee";
import { User } from "../../../interfaces/User";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";
import Avatar from "../../../interfaces/avatar/Avatar";

const AssigneeWithTooltip: React.FC<{ assignee: User }> = ({ assignee }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [currentAvatar, setCurrentAvatar] = useState<Avatar | null>();

  useEffect(() => {
    if (assignee) {
      const storedAvatars = getAvatarsFromStorage();
      if (storedAvatars) {
        const avatar = storedAvatars.find(avatar => avatar.id === assignee.id);
        if (avatar) {
          setCurrentAvatar(avatar);
        }
      }
    }
  }, [assignee]);


  return (
    <div>
      {assignee && (
        <div className="relative">
          {currentAvatar ? (
            <img
              src={`data:image/png;base64,${currentAvatar.avatar}`}
              alt="profile_picture"
              className="h-8 w-8 rounded-full me-2"
              onMouseEnter={() => setTooltipVisible(true)}
              onMouseLeave={() => setTooltipVisible(false)}
            />
          ) : (
            <div
              className="w-8 h-8 font-bold flex items-center justify-center rounded-full bg-gray-300 dark:text-text"
              onMouseEnter={() => setTooltipVisible(true)}
              onMouseLeave={() => setTooltipVisible(false)}
            >
              <div className="text-sm">
                {assignee.firstName[0] + "" + assignee.lastName[0]}
              </div>
            </div>
          )}
          <div
            className={`${
              isTooltipVisible ? "opacity-100" : "hidden"
            } absolute text-sm z-50 bg-background dark:bg-background-dark px-2 py-1 rounded-md shadow-lg whitespace-nowrap top-10 left-1/2 transform translate-x-7 -translate-y-full transition duration-200 ease-in-out tooltip`}
          >
            <h4>{assignee.displayName}</h4>
            <hr className="m-auto w-4/5" />
            <p className="text-muted">{assignee.email}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssigneeWithTooltip;
