// Calendar.tsx
import React, { useState, useEffect } from "react";
import MonthlyView from "./MonthlyView";
// import WeeklyView from './WeeklyView';
// import DailyView from './DailyView';
import { Event } from "../../../interfaces/Event";
import Sidebar from "../sidebar/Sidebar";
import TabButton from "../../ui/Buttons/TabButton";

interface CalendarViewProps {
  events: Event[];
}

const Calendar: React.FC<CalendarViewProps> = ({ events }) => {
  const [view, setView] = useState("monthly"); // or 'weekly', 'daily'
  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>(
    undefined
  );
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    // Fetch events from your service and set them
    // setevents(fetchedevents);
  }, []);

  const renderView = () => {
    switch (view) {
      case "monthly":
        return (
          <MonthlyView
            events={events}
            setSelectedEventFunction={setSelectedEventFunction}
          />
        );
      case "weekly":
        // return <WeeklyView events={events} />;
        return <></>;
      case "daily":
        // return <DailyView events={events} />;
        return <></>;
      default:
        return (
          <MonthlyView
            events={events}
            setSelectedEventFunction={setSelectedEventFunction}
          />
        );
    }
  };

  const setSelectedEventFunction = (event: Event) => {
    setSelectedEvent(event);
    setShowSidebar(true);
  };

  const closeSidebar = () => {
    setShowSidebar(false);
  };

  return (
    <div>
      <div className="my-10 ">
        <h1 className="text-4xl text-center important text-primary">Calendar</h1>
      </div>
      <div className="flex justify-center mt-2 mb-2">
        <nav className="flex overflow-x-auto items-center bg-primary-dark text-text dark:text-text-dark p-1 space-x-1 rtl:space-x-reverse text-sm rounded-xl ">
          <span onClick={() => setView("monthly")}>
            <TabButton isActive={view === "monthly"} buttonText="Monthly" />
          </span>
          <span onClick={() => setView("weekly")}>
            <TabButton isActive={view === "weekly"} buttonText="Weekly" />
          </span>
          <span onClick={() => setView("daily")}>
            <TabButton isActive={view === "daily"} buttonText="Daily" />
          </span>
        </nav>
      </div>
      {renderView()}
      <Sidebar
        show={showSidebar}
        onClose={closeSidebar}
        eventDetails={selectedEvent}
      />
    </div>
  );
};

export default Calendar;
