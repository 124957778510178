import React, { useEffect, useState } from "react";
import EventNotification from "../../../interfaces/eventNotification/EventNotification";
import NotificationBlock from "../notifications/NotificationBlock";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MessageView from "./MessageView";
import { useAuth } from "../../../firebase/AuthProvider";
import {
  getNotifications,
  markAllNotificationsRead,
  markNotificationRead,
} from "../../../services/notificationServices/NotificationService";

const Inbox: React.FC = () => {
  const [selectedMessageId, setSelectedMessageId] = useState<string | null>(null);
  const [notifications, setNotifications] = useState<EventNotification[]>([]);
  const [displayedNotifications, setDisplayedNotifications] = useState<EventNotification[]>([]);
  const [reload, setReload] = useState(false);
  const [page, setPage] = useState(1); // Track the current page number
  const [allNotificationsLoaded, setAllNotificationsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // To prevent multiple load requests

  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      const getNotificationsForUser = async () => {
        setIsLoading(true); // Set loading to true to prevent more requests
        const authToken = await currentUser.getIdToken();
        const response = await getNotifications(authToken, page);
        setIsLoading(false); // Set loading to false once the request is done
        if (response !== undefined && response !== null) {
          if (response.length === 0) {
            setAllNotificationsLoaded(true); // Stop loading if no more notifications
          } else {
            setNotifications((prev) => [...prev, ...response]);
          }
        }
      };
      getNotificationsForUser();
    }
  }, [reload, page, currentUser]);

  useEffect(() => {
    setDisplayedNotifications(notifications);
  }, [notifications]);

  const handleLoadMore = () => {
    if (!isLoading) {
      // Increment the page number to load more notifications
      setPage((prevPage) => prevPage + 1);
    }
  };

  const markAllRead = async () => {
    if (currentUser) {
      const authToken = await currentUser.getIdToken();
      await markAllNotificationsRead(authToken).then(() => {
        setNotifications([]);
        setReload(!reload);
        setPage(1); // Reset pagination
        setAllNotificationsLoaded(false); // Allow reloading after marking all as read
      });
    }
  };

  return (
    <div className="p-5 dark:text-text-dark">
      <div className="flex justify-between align-middle bg-[#EFEFEF] rounded-t-3xl p-2 px-5">
        <h1 className="inter-font text-text text-xl font-extrabold">Notifications</h1>
        <div
          className="text-muted dark:text-muted-dark text-[12px] underline my-auto cursor-pointer"
          onClick={markAllRead}
        >
          Mark all read
        </div>
      </div>
      <div className="flex w-full">
        <div className="flex-col max-h-[700px] w-full">
          <div className="flex flex-col w-full min-w-[350px] overflow-y-auto">
            {displayedNotifications.map((data, index) => (
              <div
                key={index}
                onClick={() => setSelectedMessageId(data.id)}
                className={`${
                  selectedMessageId === data.id
                    ? "bg-background dark:bg-background-dark"
                    : ""
                }`}
              >
                <MessageView notification={data} />
              </div>
            ))}
          </div>
          {!allNotificationsLoaded && (
            <button
              onClick={handleLoadMore}
              className="my-3 bg-[#3DD598] hover:bg-[#46efab] w-11/12 mx-auto block text-white py-2 px-4 rounded-md"
            >
              {isLoading ? "Loading..." : "Load More"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Inbox;

