// Button.tsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import "./Button.css";

interface ButtonProps {
  buttonText: string;
  isActive: boolean;
  icon?: IconDefinition; // FontAwesome icon (optional)
  iconPosition?: "before" | "after"; // Icon position (default: 'before')
}

const Button: React.FC<ButtonProps> = ({
  buttonText,
  isActive = true,
  icon,
  iconPosition = "before",
}) => {
  return (
    <span>
      {isActive ? (
        <button className="bg-blue-500 hover:bg-blue-400 text-white font-bold p-1 px-2 m-2 border-b-4 border-blue-700 hover:border-blue-500 rounded">
          {iconPosition === "before" && icon && (
            <FontAwesomeIcon icon={icon} className="pe-2" />
          )}
          {buttonText}
          {iconPosition === "after" && icon && (
            <FontAwesomeIcon icon={icon} className="ps-2" />
          )}
        </button>
      ) : (
        <button className="bg-blue-500 hover:bg-blue-400 text-white font-bold p-1 px-2 m-2 border-b-4 border-blue-700 hover:border-blue-500 rounded" disabled>
          {iconPosition === "before" && icon && (
            <FontAwesomeIcon icon={icon} className="pe-2" />
          )}
          {buttonText}
          {iconPosition === "after" && icon && (
            <FontAwesomeIcon icon={icon} className="ps-2" />
          )}
        </button>
      )}
    </span>
  );
};

export default Button;
