import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { getUserInfoByUserId } from "../../../services/userServices/UserInfoService";
import { useAuth } from "../../../firebase/AuthProvider";

const EmployeeDriveBreadcrumbs: React.FC = () => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [userId, setUserId] = useState<string | null>(null);
  const [userPathnames, setUserPathnames] = useState<string[]>([]);
  const [userFullName, setUserFullName] = useState<string | null>(null);

  const isLinkable = (segment: string, index: number, pathnames: string[]) => {
    // Add logic here to determine if a segment should be a link
    if (segment === 'employeeDrive' || (index == pathnames.length - 1)) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    const employeeDriveIndex = pathnames.indexOf("employeeDrive");

    if (
      employeeDriveIndex !== -1 &&
      pathnames[employeeDriveIndex + 1] &&
      currentUser
    ) {
      const fetchUserInfo = async () => {
        const userId = pathnames[employeeDriveIndex + 1];
        setUserId(userId);

        const authToken = await currentUser.getIdToken();

        getUserInfoByUserId(userId, authToken)
          .then((userInfo) => {
            if (userInfo) {
              setUserFullName(`${userInfo.firstName}${userInfo.lastName}`);
              setUserPathnames(pathnames); // Keep original pathnames with userId
            } else {
              setUserPathnames(pathnames);
            }
          })
          .catch((error) => {
            console.error("Error fetching user info:", error);
            setUserPathnames(pathnames);
          });
      };
      fetchUserInfo();
    } else {
      setUserPathnames(pathnames);
    }
  }, [location, currentUser]);

  return (
    <nav aria-label="Breadcrumb" className="py-3">
      <ol className="list-reset flex">
        {userPathnames.map((value, index) => {
          const isLast = index === userPathnames.length - 1;
          const to = isLast && userId
            ? `/${userPathnames.slice(0, index).join('/')}/${userId}`
            : `/${userPathnames.slice(0, index + 1).join('/')}`;
          const displayValue = value === userId && userFullName
            ? userFullName
            : value.charAt(0).toUpperCase() + value.slice(1); // Capitalize first letter

          return (
            <li key={to} className="flex items-center">
              <span className="mx-2 text-gray-500">/</span>
              {isLast ? (
                <span className="text-gray-400">
                  {displayValue.replace(/%20/g, " ")}
                </span>
              ) : (
                <Link
                  to={
                    isLinkable(value, index, userPathnames) ? to : ''
                  }
                  className={`text-blue-600 hover:text-blue-800 transition-colors duration-300 ${!isLinkable(value, index, userPathnames) && 'pointer-events-none text-gray-600'}`}
                >
                  {displayValue.replace(/%20/g, " ")}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default EmployeeDriveBreadcrumbs;
