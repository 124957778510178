// Dashboard.tsx
import React, { useEffect } from "react";
import Tasks from "../tasks/Tasks";
import DashboardStats from "./DashboardStats";
import { useAuth } from "../../../firebase/AuthProvider";
import TeamStats from "./TeamStats";

const Dashboard: React.FC = () => {
  const { currentRoles } = useAuth();

  useEffect(() => {
    if (currentRoles) {
      console.log();
    }
  }, [currentRoles]);

  return (
    <div className="relative bg-background dark:bg-background-dark sm:min-w-[640px] overflow-x-auto max-w-full flex-grow flex-wrap">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="relative flex flex-wrap flex-grow bg-[#F7F9FC] dark:bg-[#141416] h-auto">
        <div className=" relative mx-auto min-h-full flex flex-wrap flex-grow h-auto -top-40">
          <Tasks />
        </div>
      </div>
      <div className="flex-1 flex-col -top-20 relative">
        {currentRoles?.some((role) => role.name === "SUPERVISOR") ? (
          <div className="flex flex-col gap-10 mx-0 xl-custom:flex-row xl-custom:justify-center xl-custom:items-center xl-custom:gap-5">
            <div className="order-2 xl-custom:order-1">
              <TeamStats />
            </div>
            <div className="order-1 xl-custom:order-2 xl-custom:bg-black">
              <DashboardStats />
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-10 mx-0 xl-custom:flex-row xl-custom:justify-center xl-custom:items-center xl-custom:gap-5">
            <div className="order-2 xl-custom:order-1">
              <TeamStats />
            </div>
            <div className="order-1 xl-custom:order-2 xl-custom:bg-black">
              <DashboardStats />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
