import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  const isLinkable = (segment: string, index: number, pathnames: string[]) => {
    // Add logic here to determine if a segment should be a link
    // For example, if the next segment is just a number, it might not be linkable
    if (index < pathnames.length - 1 && /^\d+$/.test(pathnames[index + 1])) {
      return false;
    }
    return true;
  };

  return (
    <nav aria-label="Breadcrumb" className="py-3">
      <ol className="list-reset flex">
        {pathnames.map((value, index) => {
          const isLast = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const displayValue = value.charAt(0).toUpperCase() + value.slice(1); // Capitalize first letter

          return (
            <li key={to} className="flex items-center">
              <span className="mx-2 text-gray-500">/</span>
              {isLast ? (
                <span className="text-gray-400">{displayValue.replace(/%20/g, " ")}</span>
              ) : (
                <Link
                  to={to}
                  className="text-blue-600 hover:text-blue-800 transition-colors duration-300"
                >
                  {displayValue.replace(/%20/g, " ")}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
